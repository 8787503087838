<template>
	<div id="app" :class="appClass">
		<div :class="barrierClasses" @click="clickBarier"></div>
		<sidebar v-if="isShowSidebar" :class="[isActiveSidebar ? 'active' : '']"/>
		<div :class="[isShowSidebar ? '' : 'm0', 'wrapper']">
			<Header v-if="isShowSidebar" @activeSidebar="isActiveSidebar = !isActiveSidebar" />
			<router-view/>
		</div>
    <Success v-if="notificationStatus === 'success'" />
    <Failure v-else-if="notificationStatus === 'failure'" />
    <Server v-else-if="notificationStatus === 'server'"/>
    <Notification v-else-if="notificationStatus === 'notification'"/>
		<Validations />
	</div>
</template>

<script>
import sidebar from '@/components/Sidebar.vue'
import Header from '@/components/Header.vue'
import Success from '@/components/Notification/Success.vue'
import Validations from '@/components/Notification/Validations.vue'
import Failure from '@/components/Notification/Failure.vue'
import Server from '@/components/Notification/Server.vue'
import Notification from '@/components/Notification'

export default {
	components: {
		sidebar,
		Header,
		Success,
		Failure,
		Server,
		Notification,
		Validations,
	},
	data() {
		return {
			active: 0,
			isActiveSidebar: false,
		}
	},
	computed: {
		notificationStatus() {
			if (this.$store.getters.notification) {
				return this.$store.getters.notification
			}
			return null;
		},
		isShowSidebar() {
			return this.getIsShowSidebar()
		},
    appClass() {
			if (this.isActiveSidebar) {
				return 'not-scroll'
      } else {
			  return ''
      }
    },
		barrierClasses() {
			if (this.isActiveSidebar) {
				return 'mobile-invisible-barrier'
      } else {
			  return ''
      }
		},
	},
	methods: {
		handleAnimation(anim) {
			this.anim = anim
		},
		clickBarier() {
			this.isActiveSidebar = !this.isActiveSidebar
		},
		getIsShowSidebar() {
			return !['auth','AuthWithCode',  'Restore', 'Reset', null].includes(this.$route.name)
		},
	},
  mounted() {
    // Closing modal windows by pressing the Escape key
    var self = this;
    document.addEventListener('keyup', function(e) {
      if (e.keyCode === 27) {
        self.$store.commit("showModal", false);
        self.$store.commit("notification", null);
      }
    });
  },
}
</script>

<style lang="scss">
.wrapper {
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
	display: flex;
	justify-content: center;
	height: 100vh;
	background-color: #f4f7f8;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.not-scroll {
  overflow: hidden;
}
</style>
