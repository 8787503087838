<template>
  <div>
    <div :class="classes">
      <BaseSearch
        v-model="search"
        :filterAcive="$store.getters.stationFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
      <vs-button
        v-if="
          $access.stations.create.includes(access_level) ||
          $role.stations.update.includes(member_role)
        "
        flat
        style="min-width: max-content; margin-left: 20px"
        :active="true"
        icon
        @click="addServer"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить станцию</span>
      </vs-button>
    </div>
    <StationsView :loading-data="loadingData" @back="back" @block="block" />
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" @back="back" @block="block" />
    </vs-dialog>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import StationsAdd from "@/components/Stations/Add.vue";
import StationsEdit from "@/components/Stations/Edit.vue";
import StationsView from "@/components/Stations/View.vue";
import BaseSearch from "@/components/base/Search";

export default {
  name: "Stations",
  components: {
    StationsAdd,
    StationsEdit,
    StationsView,
    BaseSearch,
  },
  data() {
    return {
      loadingData: true,
      view: StationsView,
      search: this.$store.getters.stationFilterValue,
    };
  },
  created() {
    this.$store.commit("stations", []);
    this.$store.commit("stationsMeta", {});
    this.$store.dispatch("stations").finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
    search() {
      this.loadingData = true;
      this.$store.commit("stations", []);
      this.$store.commit("stationsMeta", {});
      this.$store.dispatch("stationsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
  },
  computed: {
    action: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      },
    },
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      } else {
        return 0;
      }
    },
    filters() {
      return this.$store.getters.stationsFilters;
    },
    access_level() {
      return this.$store.getters.userAccessLevel;
    },
    isStationsAdd() {
      return this.view.name === "StationsAdd";
    },
    isStationsEdit() {
      return this.view.name === "StationsEdit";
    },
    classes() {
      return this.getClasses(this.view.name);
    },
    select_organization() {
      return this.$store.getters.userSelectOrganization;
    },
  },
  beforeCreate() {
    this.$store.dispatch("validations", null);
  },
  methods: {
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("stationFilterActive", value);
      this.$store.dispatch("stationsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    setAction(action) {
      switch (action) {
        case "viewing":
          this.view = StationsView;
          break;
        case "editing":
          this.view = StationsEdit;
          break;
        case "addition":
          this.view = StationsAdd;
          break;
        default:
          this.view = StationsView;
      }
    },
    block(data) {
      this.view = StationsEdit;
      this.action = true;
    },
    getClasses(viewName) {
      if (viewName === "StationsAdd" || viewName === "StationsEdit") {
        return "x-flex-end flex header-table";
      }
      return "x-flex-end flex header-table";
    },
    back(parameters) {
      /*let page = 1;
      if (parameters?.page) {
        page = parameters.page;
      }*/
      if (parameters.update) {
        this.loadingData = true;
        this.$store.commit("stations", []);
        this.$store.commit("stationsMeta", {});
        this.$store.dispatch("stations").finally(() => {
          this.loadingData = false;
        });
      }
      this.action = false;
    },
    addServer() {
      this.view = StationsAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
  body {
    .vs-button {
      margin-left: auto !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
