var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",staticClass:"base-select",class:[
    _vm.state ? `base-select--${_vm.state}` : '',
    _vm.optionsTop ? 'base-select--options-top' : '',
    _vm.loading ? 'base-select--not-click' : '',
    _vm.readonly ? 'base-select--not-click' : '',
  ],attrs:{"tabIndex":"1"},on:{"blur":_vm.blur}},[_c('div',{staticClass:"base-select__label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"base-select__label--required"},[_vm._v(" *")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"base-select__value",class:_vm.filter ? 'base-select__value--filter' : '',attrs:{"type":"text","readonly":!_vm.filter},domProps:{"value":(_vm.input)},on:{"input":[function($event){if($event.target.composing)return;_vm.input=$event.target.value},_vm.change],"blur":_vm.blurInput}}),_c('i',{staticClass:"base-select__icon",class:_vm.loading ? 'bx bx-loader-alt base-select--loading' : 'bx bxs-chevron-down'}),_c('div',{ref:"options",staticClass:"base-select__options",on:{"scroll":_vm.scroll}},[(_vm.isFirstItem && typeof _vm.firstItem === 'object' && Object.keys(_vm.firstItem).length)?_c('div',{staticClass:"base-select__option",class:_vm.firstItem[_vm.field] === _vm.value[_vm.field] ? 'base-select__option--active' : '',on:{"click":function($event){return _vm.select(_vm.firstItem)}}},[_vm._v(" "+_vm._s(_vm.firstItem[_vm.firstTitle])+" ")]):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('div',{key:'option-' + option[_vm.field] + '-' + index,staticClass:"base-select__option",class:{
        'base-select__option--active':
          (_vm.multiselect && _vm.value.filter((v) => v[_vm.field] === option[_vm.field])?.length) ||
          (!_vm.multiselect && option[_vm.field] === _vm.value[_vm.field]),
        'base-select__option--blocked': option?.blocked,
      },attrs:{"data-message":option?.message},on:{"click":function($event){return _vm.select(option)}}},[(_vm.multiselect && _vm.value.filter((v) => v[_vm.field] === option[_vm.field])?.length)?_c('i',{staticClass:"bx bx-check"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.convertTitle(_vm.title, option)))])])}),(_vm.optionsLoading)?_c('div',{staticClass:"base-select__option--loading"},[_vm._v("Загрузка...")]):_vm._e(),(_vm.optionsNotData)?_c('div',{staticClass:"base-select__option--not-data"},[_vm._v("Нет данных")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }