import axios from "axios";

// const state = {
//   jobs: null,
// };
//
// const getters = {
//   jobs: (s) => s.jobs,
// };

const actions = {
  async tasks(_, uuid) {
    const response = await axios.get(`/tasks/${uuid}`);
    return response.data.data;
  },
};

// const mutations = {
//   jobFilterActive(state, filter) {
//     state.jobFilterActive = filter;
//   },
// };

export default {
  // state,
  // getters,
  actions,
};
