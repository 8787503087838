<template>
  <span class="copy-text" :data-tooltip="tooltip_text" @click="copy"><slot>Point me</slot></span>
</template>

<script>
export default {
  name: "TextCopy",
  props: {
    textCopy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tooltip_text: "Нажмите для копирования",
      timer_id: 0,
    };
  },
  methods: {
    copy() {
      const text = this.textCopy;
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.tooltip_text = "Скопировано";
      clearTimeout(this.timer_id);
      this.timer_id = setTimeout(() => {
        this.tooltip_text = "Нажмите для копирования";
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-text {
  cursor: pointer;
  display: inline-block;
  position: relative;
  &:hover {
    &:before {
      content: attr(data-tooltip);
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      z-index: 999;
      background: #195bff;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      width: max-content;
    }
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -10px;
      z-index: 998;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 12px solid #195bff;
    }
  }
}
</style>
