<template>
  <vs-dialog
    @close="close(false)"
    style="z-index: 999999"
    auto-width
    not-padding
    class="as-notification"
    v-model="isShow"
  >
    <div class="p20 notification-alert" style="width: 250px">
      <div v-if="isLoading" class="notification-alert__loader">
      <Loader :width="80" :height="80" />
      </div>
      <div v-else>
        <lottie :options="defaultOptions" :height="120" :width="120" />
        <div class="flex x-center notification-title">предупреждение</div>
        <div class="flex x-center">
          <span style="text-align: center">{{ message }}</span>
        </div>
        <div class="flex x-center mt20">
          <vs-button @click="close(true)" transparent>ОК</vs-button>
          <vs-button @click="close(false)" dark transparent> Назад </vs-button>
        </div>
      </div>
    </div>
  </vs-dialog>
</template>

<script>
import Loader from "@/components/base/Loader";
import Lottie from "../../../node_modules/vue-lottie/src/lottie.vue";
import * as animationData from "../../assets/animate/notification/warning.json";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  components: {
    Loader,
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
    };
  },
  methods: {
    close(bool) {
      this.$emit("close", bool);
    },
  },
  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(value) {},
    },
  },
};
</script>

<style lang="scss">
.notification-alert {
  min-width: 260px;
  min-height: 260px;
  &__loader {
    height: 260px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  body {
    .as-notification {
      align-items: center;
      .vs-dialog {
        border-radius: 16px;
        max-width: 300px;
        max-height: 300px;
        min-height: 300px;
      }
    }
  }
}
</style>
