<template>
  <div class="add-organization">
    <div class="organization-header">Добавление сотрудника</div>
    <div v-if="!loadingUser && !loader">
      <BaseSelect
        v-if="$access.members.update.includes(access_level)"
        :label="'Способ добавления'"
        v-model="invite"
        :options="inviteOptions"
        :field="'field'"
        :title="'label'"
        required
      />
      <div v-if="invite.field === 'user'">
        <div class="h20 w20"></div>
        <div class="required">
          <BaseSelect
            :label="'Пользователь'"
            required
            v-model="user"
            :options="users"
            :field="'id'"
            :title="'{name} ({email})'"
            :loading="loadingUser"
            :filter="true"
            :method="'users'"
            :search="'usersSearch'"
          />
        </div>
      </div>
      <div v-if="invite.field === 'email'">
        <div class="h20 w20"></div>
        <div class="required">
          <BaseInput
            :state="isDanger('email')"
            label-placeholder="E-mail"
            required
            v-model="email"
          />
        </div>
      </div>
      <div class="h20 w20"></div>
      <div class="required">
        <BaseSelect
          :label="'Роль'"
          v-model="member_role"
          :options="member_roles"
          required
          options-top
          :field="'field'"
          :title="'label'"
        />
      </div>
      <div class="h20 w20"></div>
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addMember" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Добавить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "OrganizationEdit",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loader: false,
      id: null,
      invite: {
        label: "Из списка пользователей",
        field: "user",
      },
      inviteOptions: [
        {
          label: "Из списка пользователей",
          field: "user",
        },
        {
          label: "Отправить приглашение",
          field: "email",
        },
      ],
      user: {},
      email: "",
      member_role: {
        label: "Просмотр",
        field: 1,
      },
      roles: {
        1: "Просмотр",
        2: "Управление",
        3: "Администрирование",
      },
      member_roles: [
        {
          label: "Просмотр",
          field: 1,
        },
        {
          label: "Управление",
          field: 2,
        },
        {
          label: "Администрирование",
          field: 3,
        },
      ],
      loadingUser: true,
    };
  },
  created() {
    if (this.access_level === 1) {
      this.invite = {
        label: "Отправить приглашение",
        field: "email",
      };
      this.loadingUser = false;
    } else {
      this.$store.dispatch("users").then((response) => {
        this.user = response[0];
        this.userOptions = response;
        this.loadingUser = false;
      });
    }
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    users() {
      if (this.$store.getters.users?.length) {
        return this.$store.getters.users;
      } else {
        return [];
      }
    },
  },
  methods: {
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    addMember() {
      this.loader = true;
      this.$store.commit("validations", null);

      const data = {
        role: this.member_role.field,
      };
      if (this.invite.field === "user") {
        data.user_id = this.user.id;
      } else {
        data.email = this.email;
      }
      this.$store
        .dispatch("addMember", {
          organizationId: this.organizationId,
          data: data,
        })
        .then(() => {
          this.back(true);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно добавили сотрудника");
        }).finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-organization {
  padding: 20px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.organization-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.box-form {
  .vs-input-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .add-organization {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
  .organization-btn-box {
    .vs-button {
      margin: 0;
      margin-bottom: 20px;
      margin-right: 0 !important;
      width: 100%;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
