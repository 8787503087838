<template>
  <div class="help-field" :class="[{ required: required }]">
    <slot></slot>
    <div v-if="help.length" class="help">?</div>
    <div v-if="help.length" v-html="help" class="help__text"></div>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isHelp: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.help-field {
  position: relative;
  .help {
    position: absolute;
    top: 7px;
    right: -28px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #6a7784;
    color: #6a7784;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      &~.help__text {
        display: block;
      }
    }
    &:active {
      transform: scale(0.9);
    }
    &__text {
      display: none;
      color: #969fa8;
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 0px;
      background: #fff;
      border: 1px solid #0404044a;
      border-radius: 10px;
      padding: 10px;
      z-index: 2999;
      transform: translateY(-50%);
      width: 200px;
    }
  }
  &.required {
    &:before {
      content: "*";
      position: absolute;
      top: 10px;
      left: -12px;
      color: #6a7784;
      font-weight: bold;
    }
  }
}
</style>
