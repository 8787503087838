<template>
  <div class="edit-organization">
    <div class="organization-header">Редактирование организации</div>
    <div v-if="!clustersLoading && !loader">
      <help-field :help="'Введите название организации'">
        <BaseInput :state="isDanger('name')" label-placeholder="Название" required v-model="name" />
      </help-field>
      <help-field :help="'Включение безопасности потоков'">
        <label class="stream-security">
          <vs-switch :state="isDanger('stream_security')" success v-model="streamSecurity">
            <template #off>
              <i class="bx bx-x"></i>
            </template>
            <template #on>
              <i class="bx bx-check"></i>
            </template>
          </vs-switch>
          <span>Безопасность потоков</span>
        </label>
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Выберите кластер'"
      >
        <BaseSelect
          :label="'Кластер'"
          required
          v-model="cluster"
          :options="clusters"
          :field="'id'"
          :title="'name'"
          :filter="true"
          :method="'clusters'"
          :search="'clustersSearch'"
          :loading="clustersLoading"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Выберите лимит битрейта у потока в kb/s'"
      >
        <BaseSelect
          :state="isDanger('stream_bitrate_limit')"
          :label="'Максимальный битрейт потока'"
          v-model="stream_bitrate_limit"
          :options="bitrateOptions"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Введите суммарный битрейт потоков в kb/s'"
      >
        <BaseInput
          :state="isDanger('bandwidth_limit')"
          label-placeholder="Суммарный битрейт потоков"
          required
          v-model="bandwidth_limit"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Введите лимит слушателей'"
      >
        <BaseInput
          :state="isDanger('listeners_limit')"
          label-placeholder="Лимит слушателей"
          required
          v-model="listeners_limit"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Введите лимит станций'"
      >
        <BaseInput
          :state="isDanger('stations_limit')"
          label-placeholder="Лимит станций"
          required
          v-model="stations_limit"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Введите лимит потоков'"
      >
        <BaseInput
          :state="isDanger('streams_limit')"
          label-placeholder="Лимит потоков"
          required
          v-model="streams_limit"
        />
      </help-field>
      <div v-if="$access.organizations.update.includes(access_level)" class="h20 w20"></div>
      <help-field
        v-if="$access.organizations.update.includes(access_level)"
        :help="'Введите кол-во веб-сайтов'"
      >
        <BaseInput
          :state="isDanger('web_sites_limit')"
          label-placeholder="Лимит веб-сайтов"
          v-model="web_sites_limit"
        />
      </help-field>
      <div
        v-if="
          access_level >= 90 ||
          ($role.organizations.update.includes(member_role) && feature_flag_records)
        "
        class="h20 w20"
      ></div>
      <help-field
        v-if="
          access_level >= 90 ||
          ($role.organizations.update.includes(member_role) && feature_flag_records)
        "
        :help="'Введите место под запись (GB)'"
      >
        <BaseInput
          :state="isDanger('records_space')"
          label-placeholder="Место под запись (GB)"
          v-model="records_space"
        />
      </help-field>
      <div class="h20 w20"></div>
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button
          v-if="$access.organizations.delete.includes(access_level)"
          @click="del"
          flat="flat"
          :active="true"
          icon="icon"
          danger="danger"
          class="mr20"
        >
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="editOrganization" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import AsNumber from "@/components/Fields/AsNumber.vue";
import Alert from "@/components/Notification/Alert.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "OrganizationEdit",
  components: {
    HelpField,
    Alert,
    AsNumber,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loader: false,
      feature_flag_records: process.env.VUE_APP_FEATURE_FLAG_RECORDS === "true" ? true : false,
      id: null,
      name: "",
      streamSecurity: false,
      web_sites_limit: "0",
      stream_bitrate_limit: {
        label: 32,
        field: 32,
      },
      web_sites_limit: "",
      bandwidth_limit: "",
      bitrateOptions: [
        {
          label: 32,
          field: 32,
        },
        {
          label: 64,
          field: 64,
        },
        {
          label: 128,
          field: 128,
        },
        {
          label: 192,
          field: 192,
        },
        {
          label: 256,
          field: 256,
        },
        {
          label: 320,
          field: 320,
        },
      ],
      listeners_limit: "",
      stations_limit: "",
      streams_limit: "",
      cluster: {},
      clustersLoading: false,
      oldData: null,
      newData: null,
      records_space: "0",
      message: "",
      showModal: false,
    };
  },
  created() {
    this.clustersLoading = true;
    this.$store.dispatch("organization", this.$store.getters.idOrganization).then(() => {
      this.id = this.$store.getters.organization.id;
      this.cluster = this.$store.getters.organization.cluster;
      this.web_sites_limit = this.$store.getters.organization.web_sites_limit;
      this.name = this.$store.getters.organization.name;
      this.streamSecurity = this.$store.getters.organization.stream_security ? true : false;
      (this.records_space = this.$store.getters.organization.records_space || "0"),
        (this.stream_bitrate_limit = {
          label: this.$store.getters.organization.stream_bitrate_limit,
          field: this.$store.getters.organization.stream_bitrate_limit,
        }),
        (this.bandwidth_limit = this.$store.getters.organization.bandwidth_limit),
        (this.listeners_limit = this.$store.getters.organization.listeners_limit),
        (this.stations_limit = this.$store.getters.organization.stations_limit),
        (this.streams_limit = this.$store.getters.organization.streams_limit);
      this.web_sites_limit = this.$store.getters.organization.web_sites_limit;

      this.oldData = {
        name: this.name,
      };

      if (this.$access.organizations.update.includes(this.access_level)) {
        this.oldData["stream_bitrate_limit"] = this.stream_bitrate_limit.field;
        this.oldData["bandwidth_limit"] = this.bandwidth_limit;
        this.oldData["listeners_limit"] = this.listeners_limit;
        this.oldData["stations_limit"] = this.stations_limit;
        this.oldData["streams_limit"] = this.streams_limit;
        this.oldData["web_sites_limit"] = this.web_sites_limit;
        this.oldData["cluster_id"] = this.cluster.id;
        this.oldData["web_sites_limit"] = this.web_sites_limit;
        this.oldData["stream_security"] = this.streamSecurity;
      }
      if (
        this.access_level >= 90 ||
        (this.$role.organizations.update.includes(this.member_role) && this.feature_flag_records)
      ) {
        this.oldData["records_space"] = this.records_space;
      }

      if (this.$access.clusters.update.includes(this.access_level)) {
        this.$store.dispatch("clusters").finally(() => {
          this.clustersLoading = false;
        });
      } else {
        this.clustersLoading = false;
      }
    });
  },
  computed: {
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      }
      return 0;
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    clusters() {
      if (this.$store.getters.clusters?.length) {
        return this.$store.getters.clusters;
      } else {
        return [];
      }
    },
  },
  methods: {
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    alert(bool) {
      if (bool) {
        this.loader = true;
        this.$store
          .dispatch("delOrganization", this.id)
          .then(() => {
            this.back(true);
            this.$store.dispatch("organizations");
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили организацию");
          })
          .catch((e) => {
            if (e.response) {
              this.back();
            } else if (e.request) {
              this.$store.dispatch("notification", "server");
            } else {
              console.log("Error", e.message);
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
      this.showModal = false;
    },
    changeShow(bool) {
      this.showModal = bool;
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    del() {
      this.message = "Вы действительно хотите удалить данную организацию?";
      this.showModal = true;
    },
    editOrganization() {
      this.$store.commit("validations", null);

      this.newData = {
        name: this.name,
        stream_security: this.streamSecurity,
      };

      if (this.$access.organizations.update.includes(this.access_level)) {
        this.newData["stream_bitrate_limit"] = this.stream_bitrate_limit.field;
        this.newData["bandwidth_limit"] = this.bandwidth_limit;
        this.newData["listeners_limit"] = this.listeners_limit;
        this.newData["stations_limit"] = this.stations_limit;
        this.newData["streams_limit"] = this.streams_limit;
        this.newData["cluster_id"] = this.cluster.id;
        this.newData["web_sites_limit"] = this.web_sites_limit;
      }

      if (
        this.access_level >= 90 ||
        (this.$role.organizations.update.includes(this.member_role) && this.feature_flag_records)
      ) {
        this.newData["records_space"] = this.records_space;
      }

      const temp = {};
      Object.keys(this.newData).map((key) => {
        if ("" + this.oldData[key] !== "" + this.newData[key]) {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editOrganization", {
            id: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            if (this.$store.getters.userMemberRole) {
              Cookies.remove("select_organization");
              this.$store.dispatch("getUser");
            }
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили организацию");
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-organization {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.organization-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.box-form {
  .vs-input-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .edit-organization {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
  .organization-btn-box {
    .vs-button {
      margin: 0;
      margin-bottom: 20px;
      margin-right: 0 !important;
      width: 100%;
    }
  }
}
.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.stream-security {
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
