<template>
  <div class="table">
    <div class="table__header" :class="[headerRadius ? 'table--radius' : '']"></div>
    <div class="table__content">
      <table v-if="!isLoading && rows.length" key="view">
        <thead v-if="rows.length">
          <tr>
            <th
              :key="i"
              v-for="(th, i) in columns"
              :data="th"
              sort="sort"
              :style="th.style"
              v-html="th.label"
            ></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(tr, i) in rows">
            <tr
              :key="tr + '-' + i"
              :class="[
                expandable ? 'table__row' : '',
                tr.blocked ? 'in-progress' : '',
                tr.status === 'blocked' ? 'in-progress' : '',
                tr.status ? (progressStatus.includes(tr.status) ? 'in-progress' : '') : '',
              ]"
              @click="rowClick(tr, i)"
            >
              <td
                :style="item.style"
                :key="index"
                v-for="(item, index) in columns"
                :data-field="item.field"
              >
                <div class="table__label">{{ item.label }}</div>
                <div class="table__value">
                  <slot
                    name="table-row"
                    :props="{
                      column: {
                        field: item.field,
                        label: item.label,
                      },
                      row: tr,
                    }"
                  >
                    {{ tr[item.field] || item.default }}
                  </slot>
                </div>
              </td>
            </tr>
            <tr
              v-if="expandable"
              :key="i + 'expandable'"
              :class="i === activeRow ? 'table__row-expandable--active' : 'table__row-expandable'"
            >
              <td :colspan="Object.keys(columns).length">
                <slot
                  name="expandable"
                  :props="{
                    column: columns,
                    row: tr,
                  }"
                ></slot>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="isLoading" class="table__loading">
        <loader />
      </div>
      <div v-else-if="!isLoading && !Object.keys(rows).length" class="table__not-found">
        <slot name="not-data">
          <div style="font-size: 14px; margin: 70px 0; opacity: 0.5">Нет данных</div>
        </slot>
      </div>
    </div>
    <div class="table__footer">
      <BasePagination
        v-if="!isLoading && meta && meta.total > meta.per_page"
        v-model="page"
        :name="name"
        :pages="meta.last_page"
      />
    </div>
  </div>
</template>

<script>
import * as animationData from "../assets/animate/notData.json";
import BasePagination from "@/components/base/Pagination";
import Loader from "@/components/base/Loader";

export default {
  name: "Table",
  components: { BasePagination, Loader },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    headerRadius: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // page: 1,
      defaultOptions: { animationData: animationData.default },
      progressStatus: ["updating", "updated", "deleting", "deleted", "publishing"],
      animationSpeed: 1,
      activeRow: null,
    };
  },
  watch: {
    rows() {
      this.activeRow = null;
    },
  },
  computed: {
    page: {
      get() {
        if (this.meta && this.meta.current_page) {
          return this.meta.current_page;
        } else {
          return 1;
        }
      },
      set(page) {
        this.$emit("pagination", page);
        this.toPage(page);
      },
    },
  },
  methods: {
    rowClick(row, index) {
      if (this.expandable) {
        this.active(index);
      }
      this.$emit("row-click", row);
    },
    active(index) {
      if (index === this.activeRow) {
        this.activeRow = null;
      } else {
        this.activeRow = index;
      }
    },
    edit() {
      this.$emit("edit");
    },
    toPage(page) {
      let parameters = { page: page };
      this.$store.dispatch(this.name, parameters);
    },
  },
};
</script>

<style lang="scss" scoped>
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
.in-progress {
  opacity: 0.5;
}
body {
  .vs-pagination-content {
    width: max-content;
    margin: 0 auto;
  }
}
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__content {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__label {
    display: none;
  }
  &--radius {
    background: #fff;
    padding: 15px !important;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  &__header {
    padding: 0;
    height: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    background: #fff;
  }
  &__loading {
    background: #fff;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__not-found {
    background: #fff;
    font-size: 14px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__footer {
    background: #fff;
    padding: 15px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &__row {
    &:hover {
      td {
        cursor: pointer;
      }
    }
    &-expandable {
      display: none !important;
      &--active {
        display: table-row;
      }
    }
  }
  thead {
    tr {
      border-bottom: 2px solid #f0f3f4;
      border-top: 2px solid #f0f3f4;
      th {
        padding: 10px 12px 10px 25px;
        text-align: left;
        &:hover {
          cursor: default;
          background: #f0f3f4;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 10px 12px 10px 25px;
        text-align: left;
        .table__value {
          white-space: nowrap;
          /* overflow: hidden; */
          text-overflow: ellipsis;
        }
      }
      &:hover {
        td {
          background: rgb(249, 252, 253);
          cursor: default;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .table {
    table {
      border: 0;
    }
    tbody {
      display: block;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border-top: 1px solid #f0f3f4;
      display: block;
      padding: 10px;
      &:first-child {
        border-top: 0px;
      }
    }
    td {
      width: 100% !important;
      max-width: 100% !important;
      padding: 10px 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8em;
    }
    td {
      .table__label {
        width: 100%;
        color: #545454;
        font-weight: bold;
        text-align: left;
        display: block;
      }
      .table__value {
        width: 100%;
        text-align: left;
        width: 240px;
        margin-right: auto;
      }
    }
    td:first-child {
      border-top: 0;
    }
  }
}
</style>
