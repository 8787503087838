export const DEVELOPER = 100;
export const ADMIN = 90;
export const SUPPORT_2 = 70;
export const SUPPORT_1 = 50;
export const SIMPLE_USER = 1;

export default {
  clusters: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  servers: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  stations: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  streams: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
    unblock: [DEVELOPER, ADMIN, SUPPORT_2],
  },
  security: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
    unblock: [DEVELOPER, ADMIN, SUPPORT_2],
  },
  "security-update": {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
    unblock: [DEVELOPER, ADMIN, SUPPORT_2],
  },
  organizations: {
    create: [DEVELOPER, ADMIN, SUPPORT_2],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1, SIMPLE_USER],
    update: [DEVELOPER, ADMIN, SUPPORT_2],
    delete: [DEVELOPER, ADMIN, SUPPORT_2],
    blocked: [DEVELOPER, ADMIN, SUPPORT_2],
    support: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
  },
  members: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  users: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  jobs: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  websites: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
    blocked: [],
  },
  help: {
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1, SIMPLE_USER],
  },
  analytics: {
    create: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    delete: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
  },
  instructions: {
    create: [],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1, SIMPLE_USER],
    update: [],
    delete: [],
  },
  playlist_history: {
    create: [DEVELOPER, ADMIN],
    read: [DEVELOPER, ADMIN],
    update: [DEVELOPER, ADMIN],
    delete: [DEVELOPER, ADMIN],
  },
  violations: {
    create: [],
    read: [DEVELOPER, ADMIN, SUPPORT_2, SUPPORT_1],
    update: [DEVELOPER, ADMIN, SUPPORT_2],
    delete: [],
  },
};
