import Axios from "axios";
import Cookies from "js-cookie";
import store from "../store";

const baseUrl = process.env.VUE_APP_HOST || "https://drh-cp.dline-rhost.ru";
Axios.defaults.baseURL = baseUrl;

const errorHandling = (response) => {
  const excludedUrls = ["/auth"];
  if (response?.status) {
    switch (response.status) {
      case 401:
        if (!excludedUrls.includes(location.pathname)) {
          store.dispatch("logout");
          store.dispatch("notification", "failure");
          store.dispatch("notificationMessage", "Авторизация неуспешна");
        }
        break;
      case 422:
        const exclude = ["/jobs", "/auth-with-code"];
        if (!exclude.includes(location.pathname)) {
          if (Object.keys(response.data.errors).length) {
            store.dispatch("validations", response.data.errors);
          } else {
            store.dispatch("notification", "server");
          }
        }
        break;
      case 429:
        store.dispatch("notification", "failure");
        store.dispatch("notificationMessage", "Слишком много запросов");
        break;
    }
  } else {
    if (!excludedUrls.includes(location.pathname)) {
      store.dispatch("logout");
    }
  }
};

export default function interceptorSetup() {
  Axios.interceptors.request.use(
    (config) => {
      const token = Cookies.get("token");
      const configuration = config;
      configuration.headers["Content-Type"] = "application/json";
      configuration.url = baseUrl + config.url;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return configuration;
    },
    (err) => Promise.reject(err)
  );
  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      errorHandling(error.response);
      return Promise.reject(error);
    }
  );
}
