import axios from "axios";

const state = {
  bitrateOverlimitsPending: false,
  bitrateOverlimits: [],
  bitrateOverlimitsMeta: {},
};

const getters = {
  bitrateOverlimits: (s) => s.bitrateOverlimits,
  bitrateOverlimitsMeta: (s) => s.bitrateOverlimitsMeta,
  bitrateOverlimitsPending: (s) => s.bitrateOverlimitsPending,
};

const actions = {
  async bitrateOverlimits({ commit }, config) {
    commit("bitrateOverlimitsPending", true);
    const { data: response } = await axios.get(`/bitrate-overlimits`, config);

    if (response) {
      commit("bitrateOverlimits", response.data);
      commit("bitrateOverlimitsMeta", response.meta);
      commit("bitrateOverlimitsPending", false);
      return response;
    }
  },
  async bitrateOverlimitBlock(_, { id, config }) {
    const response = await axios.get(`/bitrate-overlimits/${id}/block`, config);
    return response;
  },
  async bitrateOverlimitPass(_, { id, config }) {
    const response = await axios.get(`/bitrate-overlimits/${id}/pass`, config);
    return response;
  },
};

const mutations = {
  bitrateOverlimitsPending(state, pending) {
    state.bitrateOverlimitsPending = pending;
  },
  bitrateOverlimits(state, data) {
    state.bitrateOverlimits = data;
  },
  bitrateOverlimitsMeta(state, meta) {
    state.bitrateOverlimitsMeta = meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
