<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'jobs'"
    :headerRadius="false"
    :isLoading="loadingData || pending"
  >
    <template v-slot:table-row="{ props }">
      <div
        v-if="
          (props.column.field === 'actions' && $access.websites.update.includes(access_level)) ||
          (props.column.field === 'actions' && checkRoleLevel())
        "
      >
        <a
          :href="'//' + props.row.domain"
          target="__blank"
          class="editing link"
          style="margin-right: 10px"
          >Перейти</a
        >
        <span class="editing" @click="update(props.row.id)" style="margin-right: 10px"
          >Редактировать</span
        >
        <span @click="remove(props.row.id)" class="editing" style="margin-right: 10px"
          >Удалить</span
        >
        <span
          v-if="
            !checkStatusIdList.includes(props.row.id) &&
            ['new', 'failed'].includes(props.row.status)
          "
          @click="checkStatus(props.row.id)"
          class="editing"
          >Подключить</span
        >
        <base-loader
          :width="18"
          :height="18"
          class="dns--check"
          v-else-if="checkStatusIdList.includes(props.row.id)"
        />
      </div>
      <span
        class="web-site__stations"
        v-else-if="props.column.field === 'stations'"
        v-html="convertStations(props.row.stations)"
      ></span>
      <span v-else-if="props.column.field === 'template'">
        {{
          templatesValue[props.row.template]
            ? templatesValue[props.row.template]
            : props.row.template
        }}
      </span>
      <span
        v-else-if="props.column.field === 'status'"
        :style="
          statuses[props.row.status]?.color ? 'color:' + statuses[props.row.status]?.color : ''
        "
      >
        {{ statuses[props.row.status]?.value || props.row.status }}
      </span>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import WebsitesTableColumns from "@/data/Websites/WebsitesTableColumns.json";
import BaseLoader from "@/components/base/Loader";

export default {
  name: "Websites",
  components: {
    Table,
    BaseLoader,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkStatusIdList: [],
      templatesValue: {
        player: "Плеер (зелёный)",
      },
      columns: WebsitesTableColumns,
      loadingData: true,
      statuses: {
        new: {
          value: "Создан",
          color: "#dfdfdf",
        },
        ["ns-check"]: {
          value: "Проверка DNS-записи",
          color: "#dfdfdf",
        },
        ["acme-config-generation"]: {
          value: "Настройка",
          color: "#dfdfdf",
        },
        ["final-config-generation"]: {
          value: "Финальная настройка",
          color: "#dfdfdf",
        },
        ["updating-nginx"]: {
          value: "Обновление",
          color: "#dfdfdf",
        },
        ["obtaining-ssl"]: {
          value: "Выпуск SSL-сертификата",
          color: "#dfdfdf",
        },
        ready: {
          value: "Домен подключен",
          color: "green",
        },
        failed: {
          value: "Ошибка",
          color: "#ff0000",
        },
        deleting: {
          value: "Удаление домена",
          color: "#dfdfdf",
        },
      },
    };
  },
  created() {
    this.$store.commit("websites", []);
    this.$store.commit("websitesMeta", {});
    this.$store.dispatch("websites").finally(() => {
      this.loadingData = false;
    });
  },
  computed: {
    data() {
      if (this.$store.getters.websites) {
        return this.$store.getters.websites;
      } else {
        return [];
      }
    },
    meta() {
      if (this.$store.getters.websitesMeta) {
        return this.$store.getters.websitesMeta;
      } else {
        return {};
      }
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
  },
  watch: {
    "$store.getters.websiteCheck"(value) {
      if (value) {
        this.checkStatus(value);
      }
    },
  },
  methods: {
    checkStatus(id, first = true) {
      if (!this.checkStatusIdList.includes(id)) {
        this.checkStatusIdList.push(id);
      }
      this.$store
        .dispatch(first ? "websiteConnect" : "website", id)
        .then((response) => {
          if (!["ready", "failed"].includes(response.status)) {
            setTimeout(() => {
              this.checkStatus(id, false);
            }, 5000);
          } else {
            const index = this.checkStatusIdList.indexOf(id);
            if (index + 1) {
              this.checkStatusIdList.splice(index, 1);
            }
            this.loadingData = true;
            this.$store.commit("websites", []);
            this.$store.commit("websitesMeta", {});
            this.$store.dispatch("websites").finally(() => {
              this.loadingData = false;
            });
          }
        })
        .catch(() => {
          const index = this.checkStatusIdList.indexOf(id);
          if (index + 1) {
            this.checkStatusIdList.splice(index, 1);
          }
          this.loadingData = true;
          this.$store.commit("websites", []);
          this.$store.commit("websitesMeta", {});
          this.$store.dispatch("websites").finally(() => {
            this.loadingData = false;
          });
        });
    },
    checkRoleLevel() {
      let member_role = 1;
      if (this.$store.getters.userAccessOrganizations?.length) {
        this.$store.getters.userAccessOrganizations.forEach((organization) => {
          if (organization.member_role > member_role) {
            member_role = organization.member_role;
          }
        });
      }
      if (this.$role.websites.update.includes(member_role)) {
        return true;
      } else {
        return false;
      }
    },
    convertStations(stations) {
      let convert = "";
      if (stations.length) {
        stations.forEach((station, index) => {
          convert += index !== stations.length - 1 ? station.name + ", " : station.name;
        });
      }
      return convert;
    },
    update(id) {
      this.$store.commit("idWebsite", id);
      this.$emit("block", "editing");
    },
    remove(id) {
      this.$emit("remove", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #2c3e50;
  font-weight: normal;
  text-decoration: none;
}
.web-site {
  &__stations {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}
.dns--check {
  display: inline-block;
  height: 18px;
  transform: translateY(-4px);
}
</style>
