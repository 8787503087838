<template>
  <section class="security-setup">
    <label class="security-label">
      В этом разделе Вы можете ограничить доступ к прослушиванию трансляции на основании
      местонахождения слушателя.
    </label>

    <loader v-if="countryLoading" style="margin: 30px" />
    <section v-if="!countryLoading" class="geo-header">
      <div>
        <Radio v-model="security" :value="true"
          >Прослушивание запрещено всем указанным странам</Radio
        >
        <Radio v-model="security" :value="false"
          >Прослушивание разрешено всем указанным странам</Radio
        >
      </div>
      <vs-button
        class="country-button"
        flat
        icon
        :active="true"
        :loading="geoCreateLoading"
        @click="isAddCountry = !isAddCountry"
      >
        <i class="bx bx-plus"></i> <span class="country-button__label">Добавить страну</span>
      </vs-button>
    </section>
    <SecurityModalCountries
      :display="isAddCountry"
      @changeCountry="onChangeCountry"
      @back="isAddCountry = false"
    />
    <table v-if="!countryLoading" class="table-xxx">
      <thead>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="securityList?.length === 0">
          <td colspan="2" class="not-security">Защита отключена</td>
        </tr>
        <tr
          v-for="(security, index) in securityList"
          :key="security.id"
          class="animation-row"
          :ref="'row-' + index"
        >
          <td>{{ security.name }}</td>
          <td>
            <section class="actions">
              <vs-button
                style="margin-right: 10px"
                icon
                color="danger"
                flat="flat"
                title="Удалить"
                :active="false"
                :loading="geoRemoveIndexLoading === index"
                @click="removeSecurity(security.id, index)"
              >
                <i class="bx bx-x-circle"></i>
              </vs-button>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import "./style.scss";
import BaseSelect from "@/components/base/Select";
import Radio from "@/components/Radio";
import Loader from "@/components/base/Loader";
import { InputAutocomplete } from "@/components/input";
import SecurityModalCountries from "./countries.vue";

export default {
  name: "page-security-geo",
  components: { SecurityModalCountries, Radio, BaseSelect, Loader, InputAutocomplete },
  data() {
    return {
      isAddCountry: false,
      countryLoading: false,
      countryList: [],
      country: null,
      securityList: [],
      security: false,
      securityId: null,
      geoCreateLoading: false,
      geoRemoveIndexLoading: null,
      isEditSecurity: false,
    };
  },
  created() {
    this.countryLoading = true;
    this.$store
      .dispatch("securities", {
        id: this.$route.params.stationId,
        additionFilter: "filter[security_type]=country",
      })
      .then((response) => {
        if (response?.length) {
          this.isEditSecurity = true;

          this.securityId = response[0].id;
          this.security = !Boolean(response[0].white_list);
          this.securityList = response[0].security_countries?.map((item) => item.country);
        }

        // if (response[0]?.security_countries?.length) {
        //   this.securityId = response[0].id;
        //   this.security = !Boolean(response[0].white_list);
        //   this.securityList = response[0].security_countries.map((item) => item.country);
        // }
        this.countryLoading = false;
      });
  },
  watch: {
    security(value) {
      // if (this.securityList?.length) {
      if (this.isEditSecurity) {
        this.$store.dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !value,
            countries: this.securityList.map((country) => {
              country.country_id = country.id;
              return country;
            }),
          },
        });
      }
    },
  },
  methods: {
    onChangeCountry(country) {
      this.country = country;
      this.createSecurity();
    },
    removeSecurity(countryId, index) {
      this.geoRemoveIndexLoading = index;
      const countries = this.securityList
        .filter((country) => country.id !== countryId)
        .map((country) => {
          country.country_id = country.id;
          return country;
        });
      this.$store
        .dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !this.security,
            countries,
          },
        })
        .then(() => {
          const row = this.$refs["row-" + index][0];
          row.classList.remove("animation-row");
          row.classList.add("animation-remove-row");
          this.securityList = countries;
        })
        .finally(() => {
          this.geoRemoveIndexLoading = null;
        });
    },
    createSecurity() {
      if (this.country) {
        this.geoCreateLoading = true;
        this.country.country_id = this.country.id;
        // if (this.securityList?.length) {
        if (this.isEditSecurity) {
          this.$store
            .dispatch("updateSecurity", {
              id: this.securityId,
              data: {
                white_list: !this.security,
                countries: this.securityList
                  .map((country) => {
                    country.country_id = country.id;
                    return country;
                  })
                  .concat([this.country]),
              },
            })
            .then((response) => {
              const countries = response.data.data.security_countries.filter(
                (country) => country.country.name === this.name
              );
              if (countries.length) {
                this.securityList.push(countries[0]);
              } else {
                this.securityList.push(this.country);
              }
              this.country = null;
            })
            .finally(() => {
              this.geoCreateLoading = false;
            });
        } else {
          this.$store
            .dispatch("createSecurity", {
              station_id: this.$route.params.stationId,
              security_type: "country",
              white_list: !this.security,
              order: 1,
              countries: [this.country],
            })
            .then((response) => {
              const countries = response.data.data.security_countries.filter(
                (country) => country.country.name === this.name
              );
              if (countries.length) {
                this.securityList.push(countries[0]);
              } else {
                this.securityList.push(this.country);
              }
              this.country = null;
            })
            .finally(() => {
              this.geoCreateLoading = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-xxx {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  thead {
    td {
      border-bottom: 1px solid #f0f3f4;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #f9fcfd;
      }
      td {
        padding: 10px 10px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: block;
      }
      td {
        border: none;
        display: block;
      }
    }
  }
}
.cotroll {
  background: none !important;
  td {
    border-top: 1px solid #f0f3f4;
  }
}
.actions {
  display: flex;
  transition: height 0.3s ease;
  justify-content: flex-end;
}
// .security-create-country {
//   display: flex;
//   align-items: center;
//   &__label {
//     margin-left: 10px;
//     font-size: 13px;
//     color: #324455;
//   }
// }
.security-mode {
  display: flex;
  align-items: center;
  &__label {
    font-size: 13px;
    color: #324455;
    margin-right: 20px;
  }
  .vs-switch {
    transform: scale(0.7);
  }
  &__description {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.animation-remove-row {
  td {
    animation: 0.3s 1 linear removeRow forwards;
    .actions {
      height: 0px !important;
      overflow: hidden;
    }
  }
}
.animation-row {
  td {
    animation: 0.3s 1 linear row;
  }
}
.country-button {
  &__label {
    margin-left: 5px;
    white-space: nowrap;
  }
}
.geo-header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: normal;
    .country-button {
      margin-top: 20px;
    }
  }
}
@keyframes row {
  from {
    opacity: 0;
    padding: 0px 10px;
  }
  to {
    opacity: 1;
    padding: 10px;
  }
}
@keyframes removeRow {
  from {
    opacity: 1;
    padding: 10px;
  }
  to {
    font-size: 0;
    opacity: 0;
    padding: 0px 10px;
  }
}
.not-security {
  opacity: 0.5;
  text-align: center;
  padding: 30px !important;
}
.security-label {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 14px;
  border-left: 3px solid #508ffc;
  padding: 5px 10px;
  color: #508ffc;
  background-color: #f1f7ff;
}
</style>
