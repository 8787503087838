<template>
  <vs-dialog prevent-close not-padding v-model="display">
    <section class="modal-content">
      <h3>Выберите страну</h3>
      <base-input class="modal-content__input" label-placeholder="Поиск" v-model="search" />
      <ul>
        <li v-for="country in countries" @click="onSelect(country)">
          <span>{{ capitalize(search) }}</span
          >{{ country.name.slice(search.length) }}
        </li>
      </ul>
      <vs-button class="modal-content__button" flat="flat" :active="false" @click="onBack">
        Назад
      </vs-button>
    </section>
  </vs-dialog>
</template>

<script>
import BaseInput from "@/components/base/Input";

export default {
  name: "security-modal-countries",
  components: { BaseInput },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  created() {
    this.getCountries();
  },
  computed: {
    countries() {
      const countries = this.$store.getters.countries;

      if (!countries) return [];

      return countries.filter((country) => {
        if (!this.search) return true;
        return country.name?.toLowerCase().startsWith(this.search.toLowerCase());
      });
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getCountries() {
      this.$store.dispatch("countries");
    },
    onSelect(country) {
      this.$emit("changeCountry", country);
      this.onBack();
    },
    onBack() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  width: 100%;
  min-height: 300px;
  max-height: 500px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  h3 {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
  &__input {
    margin-bottom: 20px;
  }
  ul {
    height: 192px;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    li {
      margin: 0;
      padding: 5px 10px;
      cursor: pointer;
      list-style-type: none;
      &:hover {
        color: #195bff;
        background: #f9fcfd;
      }
      span {
        color: #185bff;
      }
    }
  }
  &__button {
    margin-top: 20px;
    margin-left: auto;
  }
}
</style>
