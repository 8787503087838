<template>
  <transition-group name="errors" tag="ul" class="validations">
    <li
        @touchstart="(event) => startSwipe(event, key)"
      class="validation"
      v-for="key in Object.keys(validations)"
      :key="'validation-' + key"
    >
      <i class="bx bx-error validation__icon"></i>
      <div class="validation__content">
        <h4 class="validation__title">Ошибка валидации</h4>
        <p
          class="validation__item"
          v-for="(error, index) in validations[key]"
          :key="'error-' + index"
        >
          {{ error }}
        </p>
      </div>
      <i class="bx bx-x validation__close" @touchstart="notSwipe" @click="close(key)"></i>
    </li>
  </transition-group>
</template>
<script>
export default {
  data() {
    return {
      notification: [],
      mouseOrigin: null,
      isSwiping: false,
      element: null,
      key: '',
    };
  },
  created() {
    document.addEventListener("touchend", this.endSwipe);
    document.addEventListener("touchmove", this.detectMouse);
  },
  computed: {
    validations() {
      if (this.$store.getters.errorUpdate) {
        return this.$store.getters.validations;
      }
      return {};
    },
  },
  methods: {
    close(key) {
      this.$store.commit("errorDelete", key);
    },
    notSwipe() {
      this.isSwiping = false;
    },
    startSwipe(evt, key) {
      this.key = key;
      this.element = evt.target;
      this.mouseOrigin = evt.touches[0].clientX;
      this.isSwiping = true;
    },
    endSwipe() {
      this.mouseOrigin = null;
      this.isSwiping = false;
      if (this.element) {
        this.element.style.transform = 'translateX(0)';
      }
    },
    detectMouse(evt) {
      if (!this.isSwiping) return;

      const mouseStep = 139;
      const currentMousePosition = evt.touches[0].clientX;

      this.element.style.transition = 'none';
      this.element.style.transform = `translateX(${
        -1 * (this.mouseOrigin - currentMousePosition)
      }px)`;

      // SWIPE LEFT
      if (this.mouseOrigin + mouseStep < currentMousePosition) {
        this.close(this.key);
        this.isSwiping = false;
      }

      // SWIPE RIGHT
      if (this.mouseOrigin - mouseStep > currentMousePosition) {
        this.close(this.key);
        this.isSwiping = false;
      }
    },
  },
};
</script>

<style lang="scss">
.validations {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999999999999;
  padding-top: 10px;
  margin: 0;
}
.validation {
  position: relative;
  display: flex;
  background: #f24a56;
  list-style-type: none;
  color: #fff;
  margin-bottom: 6px;
  width: 340px;
  transition: all 0.45s ease;
  &:hover {
    margin-left: -5px;
  }
  &__icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 20px;
  }
  &__title {
    margin-bottom: 5px;
  }
  &__content {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  &__close {
    font-size: 18px;
    height: 18px;
    margin-right: 20px;
    margin-top: 20px;
    transform: translateX(0) !important;
    &:hover {
      cursor: pointer;
    }
  }
  &__item {
    font-size: 14px;
  }
}

.errors-move,
.errors-enter-active,
.errors-leave-active {
  transition: all 0.45s ease;
}

.errors-enter-from,
.errors-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.errors-leave-active {
  position: absolute;
}

@media (max-width: 700px) {
  .validation {
    &:hover {
      margin-left: 0;
    }
    &__icon {
      pointer-events: none;
    }
    &__title {
      pointer-events: none;
    }
    &__content {
      pointer-events: none;
    }
    /* &__close { */
    /*   pointer-events: none; */
    /* } */
    &__item {
      pointer-events: none;
    }
  }
}
@media (max-width: 500px) {
  .validations {
    padding: 0;
    width: 100%;
  }
  .validation {
    width: 100%;
  }
}
</style>
