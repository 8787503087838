<template>
  <div class="added-cluster">
    <div class="cluster-header">Добавление кластера</div>
    <div v-if="!loader">
    <help-field :help="'Придумайте название для Вашего кластера'">
      <BaseInput :state="isDanger('name')" label-placeholder="Название" required v-model="name" />
    </help-field>
    <div class="h20 w20"></div>
    <help-field
      :help="'Выберите тип кластера:<br>1. <strong>standalone</strong> - 1 сервер<br>2. <strong>ha</strong> - высокодоступный кластер с несколькими серверами'"
    >
      <BaseSelect v-model="type" :label="'Тип кластера'" :options="typeOptions" />
    </help-field>
    <div class="h20 w20"></div>
    <help-field :help="'Сервер прослушивания и просмотра статистики'">
      <BaseInput :state="isDanger('listen_host_fqdn')" label-placeholder="Сервер статистики" required v-model="listen_host_fqdn" />
    </help-field>
    <div class="h20 w20"></div>
    <div class="flex x-flex-end mt20">
      <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
      <vs-button @click="addCluster" flat="flat" :active="true" icon="icon">
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить</span>
      </vs-button>
    </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import AsNumber from "@/components/Fields/AsNumber.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "ClustersAdd",
  components: {
    BaseSelect,
    AsNumber,
    BaseInput,
    HelpField,
    Loader,
  },
  data() {
    return {
      loader: false,
      name: "",
      listen_host_fqdn: "",
      type: {
        field: "standalone",
        label: "Standalone",
      },
      typeOptions: [
        {
          field: "standalone",
          label: "Standalone",
        },
        {
          field: "ha",
          label: "High-Availability",
        },
      ],
    };
  },
  methods: {
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    async addCluster() {
      this.loader = true;
      this.$store.commit("validations", null);
      const data = {
        name: this.name,
        type: this.type.field,
        listen_host_fqdn: this.listen_host_fqdn,
      };
      this.$store.dispatch("addCluster", data).then(() => {
        this.$store.dispatch("notification", "success");
        this.$store.dispatch("notificationMessage", "Вы успешно создали кластер");
        this.back(true);
      }).finally(() => {
        this.loader = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-cluster {
  padding: 35px;
}
.cluster-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .vs-input-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 450px) {
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
