<template>
  <div class="edit-station">
    <div class="station-header">Редактирование станции</div>
    <div v-if="!load_station && !loader">
      <help-field :help="'Введите название станции'">
        <BaseInput
          class="width"
          :state="isDanger('name')"
          label-placeholder="Название"
          required
          v-model="name"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Лимит слушателей'">
        <BaseInput
          class="width"
          :state="isDanger('listeners_limit')"
          label-placeholder="Лимит слушателей"
          v-model="listeners_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field
        :help="'При выключенной опции Ваша радиостанция будет отображаться на публичной странице статистики radio.dline-media.com'"
        style="padding-top: 5px"
      >
        <label class="station__public-show">
          <vs-switch :state="isDanger('public_show')" success v-model="public_show">
            <template #off>
              <i class="bx bx-x"></i>
            </template>
            <template #on>
              <i class="bx bx-check"></i>
            </template>
          </vs-switch>
          <span>Отображать в каталоге</span>
        </label>
      </help-field>
      <div class="h20 w20"></div>
      <div class="flex x-flex-end mt20 station-button-box">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="mr20">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="editStation" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
        <Alert :show="showModal" :message="message" @close="alert" />
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Notification/Alert.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "StationsEdit",
  components: {
    HelpField,
    Alert,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loader: false,
      id: "",
      name: "",
      load_station: true,
      listeners_limit: "",
      public_show: false,
      status: {
        field: "published",
        label: "published",
      },
      statusOptions: [
        {
          field: "published",
          label: "published",
        },
        {
          field: "unpublished",
          label: "unpublished",
        },
      ],
      oldData: {},
      newData: {},
      showModal: false,
      message: "",
      convertStatus: {},
    };
  },
  created() {
    this.$store
      .dispatch("station", this.$store.getters.idStation)
      .then(() => {
        this.id = this.$store.getters.station.id;
        this.name = this.$store.getters.station.name;
        this.public_show = this.$store.getters.station.public_show ? true : false;
        this.listeners_limit = this.$store.getters.station.listeners_limit;
        this.status = {
          field: this.$store.getters.station.status,
          label: this.$store.getters.station.status,
        };
        this.oldData = {
          name: this.name,
          listeners_limit: this.listeners_limit,
          status: this.status.field,
          public_show: this.public_show,
        };
      })
      .finally(() => {
        this.load_station = false;
      });
  },
  methods: {
    del() {
      this.message = "Вы действительно хотите удалить данный станцию?";
      this.showModal = true;
    },
    editStation() {
      this.$store.commit("validations", null);
      this.newData = {
        name: this.name,
        listeners_limit: this.listeners_limit,
        status: this.status.field,
        public_show: this.public_show,
      };

      const temp = {};
      Object.keys(this.oldData).map((key) => {
        if (this.oldData[key] + "" !== this.newData[key] + "") {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editStation", {
            id: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили станцию");
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    alert(bool) {
      if (bool) {
        this.loader = true;
        this.$store
          .dispatch("delStation", this.id)
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили станцию");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось удалить станцию");
              this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            } else {
              // Что-то произошло при настройке запроса, вызвавшего ошибку
              console.log("Error", e.message);
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
      this.showModal = false;
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      const page = this.$store.getters.stationsMeta?.current_page || 1;
      this.$emit("back", { update: update, page: page });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-station {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.station-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .width {
    width: calc(20% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
    .width {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .edit-station {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .station-button-box {
    flex-direction: column;
  }
  .station-button-box button {
    margin: 0;
    margin-bottom: 20px;
    margin-right: 0 !important;
    width: 100%;
  }
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .width {
      width: 100%;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}

.station__public-show {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
