<template>
  <div>
    <div class="header-table">
      <BaseSearch
        v-model="search"
        :filterAcive="$store.getters.userFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
    </div>
    <Table
      :columns="columns"
      :rows="data"
      :meta="meta"
      :name="'users'"
      :expandable="false"
      :isLoading="loadingData"
      :headerRadius="false"
    >
      <template v-slot:table-row="{ props }">
        <span
          @click="edit(props.row)"
          v-if="props.column.field === 'actions' && $access.users.update.includes(access_level)"
          class="editing"
          >Редактировать</span
        >
        <span v-else-if="props.column.field === 'access_level'">{{
          access[props.row.access_level] || "Не указано"
        }}</span>
        <span v-else-if="props.column.field === 'created_at'">{{
          props.row.created_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <span v-else-if="props.column.field === 'updated_at'">{{
          props.row.updated_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
      </template>
    </Table>
    <vs-dialog prevent-close not-padding v-model="active">
      <UserEdit :data="row" @close="close" />
    </vs-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import UsersTableColumns from "@/data/Users/UsersTableColumns.json";
import UserEdit from "@/components/Users/Edit";
import BaseSearch from "@/components/base/Search";

export default {
  components: {
    Table,
    UserEdit,
    BaseSearch,
  },
  data() {
    return {
      columns: UsersTableColumns,
      loadingData: true,
      row: {},
      search: this.$store.getters.userFilterValue,
      // active: false,
      access: {
        1: "Пользователь",
        50: "Агент поддержки (1)",
        70: "Агент поддержки (2)",
        90: "Администратор",
        100: "Разработчик",
      },
    };
  },
  created() {
    this.$store.commit("users", []);
    this.$store.commit("usersMeta", {});
    this.$store.dispatch("users").finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    search() {
      this.loadingData = true;
      this.$store.dispatch("usersSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    active: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      },
    },
    filters() {
      return this.$store.getters.usersFilters;
    },
    data() {
      if (this.$store.getters.users) {
        return this.$store.getters.users;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.usersMeta) {
        return this.$store.getters.usersMeta;
      }
      return {};
    },
  },
  methods: {
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("userFilterActive", value);
      this.$store.dispatch("usersSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    close(parameters) {
      let page = 1;
      if (parameters?.page) {
        page = parameters.page;
      }
      if (parameters.update) {
        this.$store.dispatch("users", {
          page: page,
        });
      }
      this.active = false;
    },
    edit(row) {
      this.row = row;
      this.active = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
