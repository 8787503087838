<template>
  <div>
    <div :class="classes">
      <BaseSearch
        v-model="search"
        :filterAcive="$store.getters.clusterFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
      <vs-button
        v-if="$access.clusters.create.includes(access_level)"
        flat
        :active="true"
        icon
        @click="addCluster"
        style="min-width: max-content; margin-left: 20px"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить кластер</span>
      </vs-button>
    </div>
    <ClustersView @back="back" :loading="loadingData" @block="block" />
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" @back="back" @block="block" />
    </vs-dialog>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import ClustersAdd from "@/components/Clusters/Add.vue";
import ClustersEdit from "@/components/Clusters/Edit.vue";
import ClustersView from "@/components/Clusters/View.vue";
import BaseSearch from "@/components/base/Search";

export default {
  name: "Clusters",
  components: {
    ClustersAdd,
    ClustersEdit,
    ClustersView,
    BaseSearch,
  },
  data() {
    return {
      loadingData: true,
      view: ClustersView,
      search: this.$store.getters.clusterFilterValue,
    };
  },
  watch: {
    search() {
      this.loadingData = true;
      this.$store.dispatch("clustersSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    action: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      },
    },
    filters() {
      return this.$store.getters.clustersFilters;
    },
    isClustersAdd() {
      return this.view.name === "ClustersAdd";
    },
    isClustersEdit() {
      return this.view.name === "ClustersEdit";
    },
    classes() {
      return this.getClasses(this.view.name);
    },
  },
  beforeCreate() {
    this.$store.dispatch("validations", null);
    this.$store.commit("clusters", []);
    this.$store.commit("clustersMeta", {});
    this.$store.dispatch("clusters").finally(() => {
      this.loadingData = false;
    });
  },
  methods: {
    close() {
      this.action = false;
    },
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("clusterFilterActive", value);
      this.$store.dispatch("clustersSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    setAction(action) {
      switch (action) {
        case "viewing":
          this.view = ClustersView;
          break;
        case "editing":
          this.view = ClustersEdit;
          break;
        case "addition":
          this.view = ClustersAdd;
          break;
        default:
          this.view = ClustersView;
      }
    },
    block() {
      this.view = ClustersEdit;
      this.action = true;
    },
    getClasses(viewName) {
      if (viewName === "ClustersAdd" || viewName === "ClustersEdit") {
        return "x-flex-end flex header-table";
      }
      return "x-flex-end flex header-table";
    },
    back(update = false) {
      if (update) {
        this.loadingData = true;
        this.$store.commit("clusters", []);
        this.$store.commit("clustersMeta", {});
        this.$store.dispatch("clusters").finally(() => {
          this.loadingData = false;
        });
      }
      this.action = false;
    },
    addCluster() {
      this.view = ClustersAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform-origin: 0 50%;
  transform-origin: 0 0;

  transform: translateY(-20px);
  opacity: 0;
}

@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
  body {
    .vs-button {
      margin-left: auto !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
