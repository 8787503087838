var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{attrs:{"columns":_vm.columns,"rows":_vm.data,"meta":_vm.meta,"name":'jobs',"headerRadius":false,"isLoading":_vm.loadingData || _vm.pending},scopedSlots:_vm._u([{key:"table-row",fn:function({ props }){return [(
        (props.column.field === 'actions' && _vm.$access.websites.update.includes(_vm.access_level)) ||
        (props.column.field === 'actions' && _vm.checkRoleLevel())
      )?_c('div',[_c('a',{staticClass:"editing link",staticStyle:{"margin-right":"10px"},attrs:{"href":'//' + props.row.domain,"target":"__blank"}},[_vm._v("Перейти")]),_c('span',{staticClass:"editing",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.update(props.row.id)}}},[_vm._v("Редактировать")]),_c('span',{staticClass:"editing",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.remove(props.row.id)}}},[_vm._v("Удалить")]),(
          !_vm.checkStatusIdList.includes(props.row.id) &&
          ['new', 'failed'].includes(props.row.status)
        )?_c('span',{staticClass:"editing",on:{"click":function($event){return _vm.checkStatus(props.row.id)}}},[_vm._v("Подключить")]):(_vm.checkStatusIdList.includes(props.row.id))?_c('base-loader',{staticClass:"dns--check",attrs:{"width":18,"height":18}}):_vm._e()],1):(props.column.field === 'stations')?_c('span',{staticClass:"web-site__stations",domProps:{"innerHTML":_vm._s(_vm.convertStations(props.row.stations))}}):(props.column.field === 'template')?_c('span',[_vm._v(" "+_vm._s(_vm.templatesValue[props.row.template] ? _vm.templatesValue[props.row.template] : props.row.template)+" ")]):(props.column.field === 'status')?_c('span',{style:(_vm.statuses[props.row.status]?.color ? 'color:' + _vm.statuses[props.row.status]?.color : '')},[_vm._v(" "+_vm._s(_vm.statuses[props.row.status]?.value || props.row.status)+" ")]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }