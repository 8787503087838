<template>
  <div class="added-organization">
    <div class="organization-header">Добавление организации</div>
    <div v-if="!clustersLoading && !usersLoading && !loader">
      <help-field :help="'Введите название организации'">
        <BaseInput :state="isDanger('name')" label-placeholder="Название" required v-model="name" />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите кластер'">
        <BaseSelect
          :label="'Кластер'"
          required
          v-model="cluster"
          :options="clusters"
          :field="'id'"
          :title="'name'"
          :filter="true"
          :method="'clusters'"
          :search="'clustersSearch'"
          :loading="clustersLoading"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите владельца'">
        <BaseSelect
          :label="'Владелец'"
          required
          v-model="user"
          :options="users"
          :field="'id'"
          :title="'{name} ({email})'"
          :filter="true"
          :method="'users'"
          :search="'usersSearch'"
          :loading="usersLoading"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите лимит битрейта у потока в kb/s'">
        <BaseSelect
          :state="isDanger('stream_bitrate_limit')"
          :label="'Максимальный битрейт потока'"
          v-model="stream_bitrate_limit"
          :options="bitrateOptions"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите суммарный битрейт потоков в kb/s'">
        <BaseInput
          :state="isDanger('bandwidth_limit')"
          label-placeholder="Суммарный битрейт потоков"
          required
          v-model="bandwidth_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите лимит слушателей'">
        <BaseInput
          :state="isDanger('listeners_limit')"
          label-placeholder="Лимит слушателей"
          required
          v-model="listeners_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите лимит станций'">
        <BaseInput
          :state="isDanger('stations_limit')"
          label-placeholder="Лимит станций"
          required
          v-model="stations_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите лимит потоков'">
        <BaseInput
          :state="isDanger('streams_limit')"
          label-placeholder="Лимит потоков"
          required
          v-model="streams_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите кол-во веб-сайтов'">
        <BaseInput
          :state="isDanger('web_sites_limit')"
          label-placeholder="Лимит веб-сайтов"
          v-model="web_sites_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите место под запись (GB)'">
        <BaseInput
          :state="isDanger('records_space')"
          label-placeholder="Место под запись (GB)"
          v-model="records_space"
        />
      </help-field>
      <label class="stream-security">
        <vs-switch :state="isDanger('stream_security')" success v-model="streamSecurity">
          <template #off>
            <i class="bx bx-x"></i>
          </template>
          <template #on>
            <i class="bx bx-check"></i>
          </template>
        </vs-switch>
        <span>Безопасность потоков</span>
      </label>
      <div class="h20 w20"></div>
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addOrganization" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Добавить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import AsNumber from "@/components/Fields/AsNumber.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "OrganizationAdd",
  components: {
    HelpField,
    BaseInput,
    AsNumber,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      name: "",
      streamSecurity: false,
      loader: false,
      feature_flag_records: process.env.VUE_APP_FEATURE_FLAG_RECORDS === "true" ? true : false,
      records_space: "0",
      web_sites_limit: "0",
      stream_bitrate_limit: {
        label: 32,
        field: 32,
      },
      web_sites_limit: "",
      bandwidth_limit: "",
      bitrateOptions: [
        {
          label: 32,
          field: 32,
        },
        {
          label: 64,
          field: 64,
        },
        {
          label: 128,
          field: 128,
        },
        {
          label: 192,
          field: 192,
        },
        {
          label: 256,
          field: 256,
        },
        {
          label: 320,
          field: 320,
        },
      ],
      listeners_limit: "",
      stations_limit: "",
      streams_limit: "",
      cluster: {},
      clustersLoading: false,
      user: {},
      usersLoading: false,
      errorsField: [],
    };
  },
  created() {
    this.clustersLoading = true;
    this.$store.dispatch("clusters").finally(() => {
      this.clustersLoading = false;
      if (this.clusters.length) {
        this.cluster = this.clusters[0];
      }
    });
    this.usersLoading = true;
    this.$store.dispatch("users").finally(() => {
      this.usersLoading = false;
      if (this.users.length) {
        this.user = this.users[0];
      }
    });
  },
  computed: {
    accessLevel() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    users() {
      if (this.$store.getters.users?.length) {
        return this.$store.getters.users;
      } else {
        return [];
      }
    },
    clusters() {
      if (this.$store.getters.clusters?.length) {
        return this.$store.getters.clusters;
      } else {
        return [];
      }
    },
  },
  methods: {
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    async addOrganization() {
      this.loader = true;
      this.$store.commit("validations", null);
      const data = {
        name: this.name,
        stream_bitrate_limit: this.stream_bitrate_limit.field,
        owner_id: this.user.id,
        bandwidth_limit: this.bandwidth_limit,
        listeners_limit: this.listeners_limit,
        stations_limit: this.stations_limit,
        streams_limit: this.streams_limit,
        web_sites_limit: this.web_sites_limit,
        cluster_id: this.cluster.id,
        web_sites_limit: parseInt(this.web_sites_limit),
        records_space: parseInt(this.records_space),
        stream_security: this.streamSecurity,
      };
      this.$store
        .dispatch("addOrganization", data)
        .then(() => {
          this.back(true);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно создали организацию");
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-organization {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.organization-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .BaseInput-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .BaseInput-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .added-organization {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .BaseInput-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}
.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.stream-security {
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
