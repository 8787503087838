<template>
  <ul class="tabs-simple" v-if="tabs?.length" ref="tabs">
    <li
      v-for="(tab, index) in tabs"
      class="tabs-simple__item"
      :class="[value?.id === tab.id ? 'tabs-simple__item--active' : '']"
      :key="tab.id"
      @click="changeTab(tab, index)"
    >
      {{ tab.name }}
    </li>
    <li
      style="display: none"
      class="tabs-simple__item--other"
      v-bind:class="{ 'tabs-simple__item--show': isOpenMenu }"
      @click="toogleMenu"
      ref="menu"
    >
      <i class="bx bx-dots-vertical-rounded"></i>
      <ul class="tabs-simple__hidden">
        <li
          v-for="(item, index) in liHiddenList"
          class="tabs-simple__hidden-item"
          :key="index"
          @click="(e) => changeActiveTab(e, item, index)"
        >
          {{ item }}
        </li>
      </ul>
    </li>
    <aside class="tabs-simple__item--active-line" ref="activeLine"></aside>
  </ul>
</template>

<script>
export default {
  name: "tabs-simple",
  props: {
    value: {
      type: Object,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      liHiddenList: [],
      isOpenMenu: false,
    };
  },
  mounted() {
    if (!this.value && this.tabs?.length) {
      this.$emit("input", this.tabs[0]);
      this.moveActiveTab(0);
      // const tabs = this.$refs.tabs;
      // const tabsWidth = tabs.clientWidth;
      // const liList = document.querySelectorAll(".tabs-simple li");
      // let sumWidth = 0;
      // liList.forEach((li, index) => {
      //   if (index === 0) {
      //     sumWidth += li.clientWidth;
      //     return;
      //   }
      //   if (li.clientWidth + sumWidth > tabsWidth && index !== liList?.length - 1) {
      //     this.liHiddenList.push(li.innerHTML);
      //     sumWidth += li.clientWidth;
      //     li.remove();
      //   } else {
      //     sumWidth += li.clientWidth;
      //   }
      //   if (this.liHiddenList?.length && index === liList?.length - 1) {
      //     // li.style.display = "block";
      //   }
      // });
      // if (this.liHiddenList?.length) {
      //   tabs.style.justifyContent = "space-between";
      // }
    }
  },
  methods: {
    toogleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    moveActiveTab(index) {
      const activeLine = this.$refs.activeLine;
      const liList = document.querySelectorAll(".tabs-simple li");
      const activeLi = liList[index];
      activeLine.style.width = activeLi.clientWidth + "px";
      activeLine.style.left = activeLi.offsetLeft + "px";
    },
    changeActiveTab(event, item, index) {
      const tabs = this.tabs;
      tabs.forEach((tab) => {
        if (tab.name.trim() === item.trim()) {
          this.$emit("input", tab);
        }
      });
    },
    changeTab(tab, index) {
      this.$emit("input", tab);
      this.moveActiveTab(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-simple {
  position: relative;
  display: flex;
  padding: 0;
  margin: 0 0 13px 0;
  @media (max-width: 500px) {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 29px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__hidden {
    position: absolute;
    right: 10px;
    background: #fff;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    z-index: 3;
    overflow: hidden;
    display: none;
    &-item {
      list-style-type: none;
      padding: 5px 10px;
      &:hover {
        background: #f9fcfd;
      }
    }
  }
  &__item {
    color: #6c7784;
    list-style-type: none;
    padding: 0 10px;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    transition: color 0.4s ease;
    white-space: nowrap;
    cursor: pointer;
    &--show {
      .tabs-simple__hidden {
        display: block;
      }
    }
    &--other {
      list-style-type: none;
      padding: 0 10px;
      margin: 0;
      cursor: pointer;
      position: relative;
    }
    &--active {
      color: #2c3e50;
      &-line {
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 30px;
        height: 3px;
        background-color: #2c3e50;
        border-radius: 1px;
        transition: all 0.3s ease;
        @media (max-width: 500px) {
          bottom: 0px;
        }
      }
    }
    &:hover {
      color: #2c3e50;
    }
  }
}
</style>
