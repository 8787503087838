<template>
  <section class="security-setup">
    <label class="security-label">
      В этом разделе Вы можете управлять учетными записями, которым разрешено прослушивание
      трансляции.
    </label>
    <loader v-if="securityLoading" style="margin: 30px" />
    <section v-if="securityList?.length && !securityLoading" class="listeners-search">
      <input-simple v-model="search" placeholder="Поиск..." />
    </section>
    <table v-if="!securityLoading" class="table-xxx">
      <thead>
        <tr>
          <td style="[securitylist?.length ? 'width: 30px' : '']"></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="securityList?.length === 0">
          <td colspan="3" class="not-security">Защита отключена</td>
        </tr>
        <tr
          v-for="(security, index) in securityList.filter((security) =>
            security.login.includes(search)
          )"
          :key="security.id"
          class="animation-row"
          :ref="'row-' + index"
        >
          <td style="width: 30px; opacity: 0.5">{{ index + 1 }}</td>
          <td>
            <span
              title="Нажимите для копирования"
              style="cursor: pointer"
              @click="copy(security.login)"
              >{{ security.login }}</span
            >
          </td>
          <td>
            <span
              title="Нажимите для копирования"
              style="cursor: pointer"
              @click="copy(security.password)"
              >{{ showPasswordIndex === index ? security.password : "************" }}</span
            >
          </td>
          <td>
            <section class="actions">
              <vs-button
                style="margin-right: 10px"
                icon
                flat="flat"
                :title="showPasswordIndex === index ? 'Скрыть пароль' : 'Показать пароль'"
                :active="false"
                :loading="listenerRemoveIndexLoading === index"
                @click="showPassword(index)"
              >
                <i v-if="showPasswordIndex === index" class="bx bx-show-alt"></i>
                <i v-else class="bx bx-hide"></i>
              </vs-button>
              <vs-button
                style="margin-right: 10px"
                icon
                color="danger"
                flat="flat"
                title="Удалить"
                :active="false"
                :loading="listenerRemoveIndexLoading === index"
                @click="removeSecurity(security.id, index)"
              >
                <i class="bx bx-x-circle"></i>
              </vs-button>
            </section>
          </td>
        </tr>
        <tr class="cotroll">
          <td colspan="3">
            <div class="input__wrapper">
              <input-simple v-model="login" class="input" type="text" placeholder="Логин" />
              <input-password v-model="password" class="input" placeholder="Пароль" />
            </div>
          </td>
          <td>
            <vs-button
              icon
              flat="flat"
              title="Добавить"
              :loading="loading"
              :active="false"
              @click="createSecurity"
            >
              <i class="bx bx-plus-circle"></i>
            </vs-button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import "./style.scss";
import { InputPassword, InputSimple } from "@/components/input";
import Loader from "@/components/base/Loader";

export default {
  name: "page-security-listeners",
  components: { Loader, InputPassword, InputSimple },
  data() {
    return {
      search: "",
      showPasswordIndex: null,
      listenerRemoveIndexLoading: null,
      security: null,
      securityLoading: false,
      securityList: [],
      loading: false,
      login: null,
      password: null,
      isEditSecurity: false,
    };
  },
  created() {
    this.securityLoading = true;
    this.$store
      .dispatch("securities", {
        id: this.$route.params.stationId,
        additionFilter: "filter[security_type]=auth",
      })
      .then((response) => {
        // if (response[0]?.security_users?.length) {
        if (response?.length) {
          this.isEditSecurity = true;

          this.securityId = response[0].id;
          this.security = !Boolean(response[0].white_list);
          this.securityList = response[0].security_users;
        }
      })
      .finally(() => {
        this.securityLoading = false;
      });
  },
  watch: {
    security(value) {
      // if (this.securityList?.length) {
      if (this.isEditSecurity) {
        this.$store.dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !value,
            users: this.securityList,
          },
        });
      }
    },
  },
  methods: {
    showPassword(index) {
      if (this.showPasswordIndex === index) {
        this.showPasswordIndex = null;
      } else {
        this.showPasswordIndex = index;
      }
    },
    copy(value) {
      const el = document.createElement("textarea");
      el.value = value;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    removeSecurity(userId, index) {
      this.listenerRemoveIndexLoading = index;
      const users = this.securityList.filter((listener) => listener.id !== userId);
      this.$store
        .dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !this.security,
            users,
          },
        })
        .then(() => {
          const row = this.$refs["row-" + index][0];
          row.classList.remove("animation-row");
          row.classList.add("animation-remove-row");
          this.securityList = users;
        })
        .finally(() => {
          this.listenerRemoveIndexLoading = null;
        });
    },
    createSecurity() {
      this.loading = true;
      if (this.login && this.password) {
        // if (this.securityList?.length) {
        if (this.isEditSecurity) {
          this.$store
            .dispatch("updateSecurity", {
              id: this.securityId,
              data: {
                white_list: !this.security,
                users: this.securityList.concat([
                  {
                    login: this.login,
                    password: this.password,
                  },
                ]),
              },
            })
            .then((response) => {
              const createUsers = response.data.data.security_users.filter(
                (user) => user.login === this.login
              );
              if (createUsers?.length) {
                this.securityList.push(createUsers[0]);
              }

              this.login = "";
              this.password = "";
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$store
            .dispatch("createSecurity", {
              station_id: this.$route.params.stationId,
              security_type: "auth",
              white_list: !this.security,
              order: 3,
              users: [
                {
                  login: this.login,
                  password: this.password,
                },
              ],
            })
            .then((response) => {
              const createUsers = response.data.data.security_users.filter(
                (user) => user.login === this.login
              );
              if (createUsers?.length) {
                this.securityList.splice(0, 0, createUsers[0]);
              }

              this.login = "";
              this.password = "";
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-xxx {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  thead {
    td {
      border-bottom: 1px solid #f0f3f4;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #f9fcfd;
      }
      td {
        padding: 10px 10px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: block;
      }
      td {
        border: none;
        display: block;
      }
    }
  }
}
.cotroll {
  background: none !important;
  td {
    border-top: 1px solid #f0f3f4;
  }
}
.actions {
  display: flex;
  transition: height 0.3s ease;
}
.security-mode {
  margin-top: 20px;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
  /* justify-content: flex-end; */
  &__label {
    font-size: 13px;
    color: #324455;
    margin-right: 20px;
  }
  .vs-switch {
    transform: scale(0.7);
  }
  &__description {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.animation-remove-row {
  td {
    animation: 0.3s 1 linear removeRow forwards;
    .actions {
      height: 0px !important;
      overflow: hidden;
    }
  }
}
.animation-row {
  td {
    animation: 0.3s 1 linear row;
  }
}
@keyframes row {
  from {
    opacity: 0;
    padding: 0px 10px;
  }
  to {
    opacity: 1;
    padding: 10px;
  }
}
@keyframes removeRow {
  from {
    opacity: 1;
    padding: 10px;
  }
  to {
    font-size: 0;
    opacity: 0;
    padding: 0px 10px;
  }
}
.input {
  max-width: 220px;
  &__wrapper {
    display: flex;
  }
}
.listeners-search {
  margin-top: 20px;
  max-width: 250px;
  padding-left: 10px;
  @media (max-width: 500px) {
    max-width: 100%;
  }
}
.not-security {
  opacity: 0.5;
  text-align: center;
  padding: 30px !important;
}
</style>
