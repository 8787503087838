<template>
  <div class="analytics-chart__box" ref="box">
    <div v-if="loadingData" class="loader__wrapper">
      <Loader />
    </div>
    <div v-if="!loadingData && !data.length" class="not__data">
      Нет данных
    </div>
    <div
      ref="grid_y"
      class="analytics-chart__values-y"
      :style="`opacity: ${!loadingData && data.length ? 1 : 0};`"
    >
      <div
        v-for="(y, index) in grid_y"
        :key="`y-${y}-${index}`"
        :style="`text-align: right;transform: translateY(-5px);font-size: 12px;height: 0;position: relative;top:${
          380 - getScaleY(y)
        }px;`"
      >
        {{ y }}
      </div>
    </div>
    <div style="width: 100%" :style="`opacity: ${!loadingData && data.length ? 1 : 0};`">
      <div class="analytics-chart__wrapper" ref="wrapper">
        <div
          v-if="!loadingData && data.length"
          class="analytics-chart__aim-x"
          :style="`left: ${aim_x}px;`"
        ></div>
        <div
          v-if="!loadingData && data.length"
          class="analytics-chart__aim-y"
          :style="`top: ${aim_y}px;`"
        ></div>
        <div
          v-if="!loadingData && data.length"
          class="analytics-chart__active-point"
          :style="`top: ${point_y - 2.5}px;left: ${point_x - 2.5}px;`"
        ></div>
        <div
          v-if="!loadingData && data.length"
          class="analytics-chart__tooltip"
          :style="`opacity: ${!loadingData && data.length && is_view_tooltip ? 1 : 0}; top: ${
            aim_y + tooltip_offset_y
          }px;left: ${aim_x + tooltip_offset_x}px;`"
          ref="tooltip"
        >
          <div style="font-size: 14px; opacity: 0.5; margin-bottom: 5px">
            {{ data[aim_i] ? convertDate(data[aim_i]["from"]) : "???" }}
          </div>
          <div>
            Слушателей: <strong>{{ data[aim_i] ? data[aim_i][field] : "???" }}</strong>
          </div>
        </div>
        <canvas
          @mousemove="aim"
          class="analytics-chart"
          v-show="!loadingData && data.length"
          ref="canvas"
          width="800"
          height="400"
        ></canvas>
      </div>
      <div class="analytics-chart__values-x">
        <div
          v-for="(x, index) in grid_x"
          :key="`x-${index}-${x}`"
          :style="`white-space: nowrap;width: max-content;font-size: 12px;transform: translateX(-50%);height: 0;position: relative; left: ${x}px`"
        >
          {{ grid_x_value[index] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/base/Loader";
// import TestData from "./Test.json";

export default {
  name: "analytics_chart",
  components: {
    Loader,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timer_id: 0,
      is_view_aim: false,
      is_view_tooltip: false,
      // data: TestData,
      data: [],
      distance: 10,
      empty_space: 0,
      max_value: 0,
      tooltip_offset_x: 20,
      tooltip_offset_y: 20,
      aim_x: 0,
      aim_y: 0,
      aim_i: 0,
      point_x: 0,
      point_y: 0,
      grid_x: [],
      grid_x_value: [],
      grid_y: [],
      diff_days: 1,
    };
  },
  mounted() {
    if (this.$store.getters.analytics?.length) {
      this.data = this.$store.getters.analytics;
      this.init();
      this.draw();
    }
    window.addEventListener("resize", this.updateWidthWrapper);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidthWrapper);
  },
  watch: {
    "$store.getters.analytics"() {
      if (this.$store.getters.analytics?.length) {
        this.data = this.$store.getters.analytics;
        this.init();
        this.draw();
      }
    },
  },
  methods: {
    init() {
      this.findMaxValue();
      this.scaleDistance(this.data);
      this.getDiffDays();
      this.getGridY();
      this.getGridX();
    },
    getDiffDays() {
      var date1 = new Date(this.data[0].from.replace(/-/g, "/"));
      var date2 = new Date(this.data[this.data.length - 1].to.replace(/-/g, "/"));
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.diff_days = diffDays;
    },
    getGridY() {
      if (this.max_value < 5) {
        this.grid_y = [5, 4, 3, 2, 1, 0];
      }
      if (this.max_value > 4) {
        this.grid_y = [
          this.max_value,
          Math.round(this.max_value / 1.25),
          Math.round(this.max_value / 1.66666666667),
          Math.round(this.max_value / 2.5),
          Math.round(this.max_value / 5),
          0,
        ];
      }
    },
    getGridX() {
      this.grid_x_value = [];
      this.grid_x = [];

      let count = Math.floor(100 / this.distance);
      if (count < 1) {
        count = 1;
      }
      for (let i = count; i < this.data.length - 1; i += count) {
        const temp = this.empty_space / 2 + i * this.distance;
        this.grid_x.push(temp);
        this.grid_x_value.push(this.convertDate(this.data[i].from, true));
      }
    },
    draw() {
      const canvas = this.$refs.canvas;
      const width = this.$refs.box.clientWidth - 40 - ((this.max_value + "").length * 6.7 + 10);
      this.setCanvasSize(canvas, width - 2, 400);

      const ctx = canvas.getContext("2d");
      ctx.webkitImageSmoothingEnabled = false;
      ctx.mozImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;
      this.drawGrid(ctx);
      this.drawLine(ctx, this.data);
      this.is_view_aim = true;
    },

    findMaxValue() {
      this.max_value = 0;
      this.data.forEach((value) => {
        if (value[this.field] > this.max_value) {
          this.max_value = value[this.field];
        }
      });
    },
    scaleDistance(data) {
      const wrapper = this.$refs.wrapper;
      const width = wrapper.clientWidth - 2;
      const count = data.length - 1;
      // this.distance = Math.floor(width / count);
      this.distance = (width - 60) / count;
      this.empty_space = width - count * this.distance;
    },
    drawLine(ctx, data) {
      ctx.beginPath();
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.strokeStyle = "#195bff";
      ctx.fillStyle = "#fff";
      ctx.lineWidth = 2.5;
      data.forEach((value, index) => {
        ctx.lineTo(
          (this.empty_space / 2 + index * this.distance),
          380 + 0.5 - this.getScaleY(value[this.field])
        );
      });
      ctx.stroke();
    },
    drawGrid(ctx) {
      ctx.beginPath();
      ctx.strokeStyle = "#e5e5e5";
      ctx.lineWidth = 1.0;
      this.grid_y.forEach((line) => {
        ctx.moveTo(0.5, 380 - this.getScaleY(line) + 0.5);
        ctx.lineTo(this.$refs.canvas.width + 0.5, 380 - this.getScaleY(line) + 0.5);
      });
      this.grid_x.forEach((value) => {
        ctx.moveTo(value, 0);
        ctx.lineTo(value, 400);
      });
      ctx.stroke();
    },
    getScaleY(y) {
      const max_value = this.max_value < 5 ? 5 : this.max_value;
      const percent = (y / max_value) * 100;
      return 360 * (percent / 100);
    },
    aim(e) {
      this.aim_x = e.layerX;
      this.aim_y = e.layerY;
      const x = Math.round((e.layerX - 0.5 - this.empty_space / 2) / this.distance);
      if (x < 0) {
        this.aim_i = 0;
      } else if (x > this.data.length - 1) {
        this.aim_i = this.data.length - 1;
      } else {
        this.aim_i = x;
      }

      this.point_x = this.empty_space / 2 + this.aim_i * this.distance;
      this.point_y = 380 - this.getScaleY(this.data[this.aim_i][this.field]);

      if (e.x + this.$refs.tooltip.offsetWidth + 50 > document.body.clientWidth) {
        this.tooltip_offset_x = -1 * (this.$refs.tooltip.offsetWidth + 20);
      } else {
        this.tooltip_offset_x = 20;
      }
      if (e.layerY + 55 + 20 > this.$refs.canvas.height) {
        this.tooltip_offset_y = -1 * (55 + 20);
      } else {
        this.tooltip_offset_y = 20;
      }
      this.is_view_tooltip = true;
    },
    setCanvasSize(canvas, width, height) {
      canvas.width = width;
      canvas.height = height;
    },
    convertDate(date, min = false) {
      date = new Date(date.replace(/-/g, "/"));
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

      if (min) {
        // меньше 1 дня
        if (this.diff_days < 2) {
          return `${hours}:${minutes}`;
        }
        // меньше 7 дней
        if (this.diff_days < 8) {
          return `${day}/${month} ${hours}:${minutes}`;
        }
        // больше 7 дней
        if (this.diff_days > 7) {
          return `${day}/${month}`;
        }
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      }
    },
    updateWidthWrapper() {
      if (this.$store.getters.analytics?.length) {
        clearTimeout(this.timer_id);
        this.grid_x_value = [];
        this.grid_x = [];

        const canvas = this.$refs.canvas;
        const width = this.$refs.box.clientWidth - 40 - this.$refs.grid_y.clientWidth;
        this.setCanvasSize(canvas, width - 2, 400);

        this.timer_id = setTimeout(() => {
          this.scaleDistance(this.data);
          this.getGridX();
          const ctx = canvas.getContext("2d");
          ctx.webkitImageSmoothingEnabled = false;
          ctx.mozImageSmoothingEnabled = false;
          ctx.imageSmoothingEnabled = false;
          this.drawGrid(ctx);
          this.drawLine(ctx, this.data);
          this.is_view_aim = true;
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-chart {
  border: 1px solid #e5e5e5;
  &__active-point {
    display: none;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: red;
    pointer-events: none;
  }
  &__aim-x {
    display: none;
  }
  &__aim-y {
    display: none;
  }
  &__tooltip {
    display: none;
    position: absolute;
    background: #e0e7eb;
    color: #324455;
    padding: 10px;
    min-width: max-content;
    border-radius: 5px;
    pointer-events: none;
  }
  &__box {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &__values-x {
    position: relative;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 22px;
  }
  &__values-y {
    position: relative;
    padding-right: 10px;
    box-sizing: border-box;
  }
  &__wrapper {
    position: relative;
    height: 402px;
    width: 100%;
    &:hover {
      .analytics-chart__active-point {
        display: block;
      }
      .analytics-chart__tooltip {
        display: block;
      }
      .analytics-chart__aim-y {
        display: block;
        position: absolute;
        width: 100%;
        height: 0px;
        border-top: 1px dashed #909090;
        pointer-events: none;
      }
      .analytics-chart__aim-x {
        display: block;
        position: absolute;
        width: 0px;
        border-left: 1px dashed #909090;
        height: 100%;
        pointer-events: none;
      }
    }
  }
}
.loader__wrapper {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.not__data {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
</style>
