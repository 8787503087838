<template>
  <section class="input-search__box">
    <aside class="input-search__autocomplete">{{ autocomplete }}</aside>
    <input
      :value="currentValue"
      class="input-search"
      placeholder="Начните вводить страну"
      type="text"
      @input="changeInput"
      @keydown="pressKey"
      @blur="blurInput"
    />
    <ul v-if="variants?.length" class="input-search__variants">
      <li
        v-for="(variant, index) in variants"
        class="input-search__variant"
        :key="index"
        @click="selectCountry(variant)"
        @mouseover="mouseoverCountry"
        @mouseout="mouseoutCountry"
      >
        <span>{{ variant[check].slice(0, currentValue?.length) }}</span
        ><span style="opacity: 0.5">{{ variant[check].slice(currentValue?.length) }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "input-autocomplete",
  props: {
    value: {
      type: Object,
    },
    check: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentValue: "",
      autocomplete: "",
      variants: [],
      preventBlur: false,
    };
  },
  watch: {
    value(value) {
      if (!value) {
        this.currentValue = "";
        this.autocomplete = "";
        this.variants = [];
        this.$emit("input", null);
      }
    },
  },
  methods: {
    mouseoverCountry() {
      this.preventBlur = true;
    },
    mouseoutCountry() {
      this.preventBlur = false;
    },
    blurInput() {
      if (this.preventBlur) {
        this.preventBlur = false;
        return;
      }

      if (this.value) {
        this.autocomplete = "";
        this.currentValue = this.value[this.check];
        this.variants = [];
      } else if (!this.variants?.length) {
        this.currentValue = "";
        this.autocomplete = "";
        this.$emit("input", null);
      } else {
        this.currentValue = "";
        this.variants = [];
        this.autocomplete = "";
      }
    },
    pressKey(e) {
      if (this.variants?.length && (e.keyCode === 13 || e.keyCode === 9)) {
        e.preventDefault();
        this.autocomplete = "";
        this.currentValue = this.variants[0][this.check];
        this.$emit("input", this.variants[0]);
        this.preventBlur = true;
        e.target.blur();
        this.variants = [];
      }
    },
    selectCountry(country) {
      this.autocomplete = "";
      this.currentValue = country[this.check];
      this.$emit("input", country);
      this.variants = [];
    },
    checkBeginningWord(checkWord, word) {
      const string = "^" + word.toLowerCase();
      const regexp = new RegExp(string);
      return regexp.test(checkWord.toLowerCase());
    },
    changeInput(event) {
      this.currentValue = event.target.value;
      if (event?.target?.value?.length) {
        this.variants = [];
        this.autocomplete = "";
        this.list.forEach((country) => {
          if (
            this.checkBeginningWord(country[this.check], event.target.value) &&
            country[this.check]?.length !== event.target.value?.length &&
            this.variants?.length < 3
          ) {
            this.variants.push(country);
          }
        });

        if (this.variants?.length) {
          this.autocomplete =
            event.target.value + this.variants[0][this.check].slice(event.target.value.length);
        }
      } else {
        this.variants = [];
        this.autocomplete = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  max-width: 250px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  outline: none;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  background: none;
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  &:focus {
    border-bottom: 1px solid #999999;
  }
  &__box {
    position: relative;
    height: 48.5px;
    width: 250px;
  }
  &__autocomplete {
    font-size: 14px;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 5px;
    left: 0;
    opacity: 0.5;
    z-index: 1;
  }
  &__variant {
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__variants {
    display: flex;
    margin: 5px 0 0 -10px;
    padding: 0;
  }
}
</style>
