import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "@/assets/scss/main.scss";
import interceptorSetup from "./_http/index";
import vuesax from "../node_modules/vuesax";
import "vuesax/dist/vuesax.css";
import access from "@/data/ConfiguringAccess";
import role from "@/data/OrganizationsRole";
import moment from "moment";
import gsap from "gsap";

Vue.config.productionTip = false;
Vue.prototype.$access = access;
Vue.prototype.$role = role;

Vue.use(vuesax);

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  }
});

Vue.filter("moment", function (value, format) {
  if (value === null || value === undefined || value == "" || format === undefined) {
    return "";
  }
  if (format === "from") {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});

interceptorSetup();

if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== "local") {
  Sentry.init({
    Vue,
    dsn: `https://d8e065bd9be1419081f341dcd98e6b05@${process.env.VUE_APP_SENTRY_DOMEN}/3`,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    logErrors: process.env.VUE_APP_SENTRY_LOG,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "drh-cp.projects.dl-net.ru",
          "radio-hosting.dline-media.com",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
