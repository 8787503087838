<template>
  <div class="added-station">
    <div class="station-header">Добавление станции</div>
    <div v-if="!organizationsLoading && !loader">
      <help-field :help="'Введите название станции'">
        <BaseInput
          class="width"
          :state="isDanger('name')"
          label-placeholder="Название"
          required
          v-model="name"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите организацию'">
        <BaseSelect
          :state="isDanger('organization_id')"
          required
          :label="'Организация'"
          v-model="organization"
          :options="organizations"
          :field="'id'"
          :title="'name'"
          :filter="true"
          :method="'organizations'"
          :search="'organizationsSearch'"
          :loading="organizationsLoading"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Лимит слушателей'">
        <BaseInput
          class="width"
          :state="isDanger('listeners_limit')"
          label-placeholder="Лимит слушателей"
          v-model="listeners_limit"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field
        :help="'При выключенной опции Ваша радиостанция будет отображаться на публичной странице статистики radio.dline-media.com'"
        style="padding-top: 5px"
      >
        <label class="station__public-show">
          <vs-switch :state="isDanger('public_show')" success v-model="public_show">
            <template #off>
              <i class="bx bx-x"></i>
            </template>
            <template #on>
              <i class="bx bx-check"></i>
            </template>
          </vs-switch>
          <span>Отображать в каталоге</span>
        </label>
      </help-field>
      <div class="h20 w20"></div>
      <div
        v-if="$access.stations.update.includes(accessLevel) || organization?.blocked === false"
        class="flex x-flex-end mt20"
      >
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addStation" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Добавить</span>
        </vs-button>
      </div>
      <div
        v-else-if="!$access.stations.update.includes(accessLevel) && organization?.blocked === true"
        class="organization--blocked"
      >
        Ваша организация заблокирована, поэтому это действие совершить нельзя. Проверьте срок
        действия услуги в
        <a href="https://bill.dline-media.com" target="__blank">биллинг-системе</a>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import HelpField from "@/components/base/HelpField";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "StationsAdd",
  components: {
    HelpField,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loader: false,
      name: "",
      listeners_limit: "",
      public_show: false,
      organization: {},
      organizationsLoading: false,
      status: {
        field: "published",
        label: "published",
      },
      statusOptions: [
        {
          field: "published",
          label: "published",
        },
        {
          field: "unpublished",
          label: "unpublished",
        },
      ],
    };
  },
  created() {
    this.organizationsLoading = true;

    this.$store.dispatch("organizations").finally(() => {
      this.organizationsLoading = false;
      if (this.organizations.length) {
        this.organization = this.organizations[0];
      }
    });
  },
  watch: {
    listeners_limit() {
      this.listeners_limit = this.listeners_limit.toString().replace(/[^\d]+/g, "");
    },
  },
  computed: {
    accessLevel() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    organizations() {
      if (this.$store.getters.userAccessLevel === 1) {
        return [this.$store.getters.userSelectOrganization];
      } else if (
        this.$store.getters.userAccessLevel > 1 &&
        this.$store.getters.organizations?.length
      ) {
        return this.$store.getters.organizations;
      } else {
        return [];
      }
    },
  },
  methods: {
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      const page = this.$store.getters.stationsMeta?.current_page || 1;
      this.$emit("back", { update: update, page: page });
    },
    async addStation() {
      this.loader = true;
      this.$store.commit("validations", null);
      this.$store
        .dispatch("addStation", {
          name: this.name,
          public_show: this.public_show,
          organization_id: this.organization.id,
          listeners_limit: this.listeners_limit,
          status: this.status.field,
        })
        .then(() => {
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно создали станцию");
          this.back(true);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-station {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  max-width: 320px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.station-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .width {
    width: calc(20% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
.organization--blocked {
  color: #666;
}
@media (max-width: 800px) {
  .box-form {
    .BaseInput-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
    .width {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .added-station {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .BaseInput-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .width {
      width: 100%;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.station__public-show {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
