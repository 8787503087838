var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-sidebar',{attrs:{"open":""},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}}),_c('span',{staticClass:"logo__title"},[_vm._v("DLine Radio Hosting")])])]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.isShowAdminSection && _vm.$access.clusters.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"clusters"},nativeOn:{"click":function($event){return _vm.to('/clusters')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-grid-alt"})]},proxy:true}],null,false,3285225389)},[_vm._v(" Кластеры ")]):_vm._e(),(_vm.isShowAdminSection && _vm.$access.servers.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"servers"},nativeOn:{"click":function($event){return _vm.to('/servers')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-server"})]},proxy:true}],null,false,804373828)},[_vm._v(" Серверы ")]):_vm._e(),(_vm.$access.organizations.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"organizations"},nativeOn:{"click":function($event){return _vm.to('/organizations')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-buildings"})]},proxy:true}],null,false,1277858788)},[_vm._v(" Организации ")]):_vm._e(),(
      _vm.$role.stations.read.includes(_vm.member_role) || _vm.$access.stations.read.includes(_vm.access_level)
    )?_c('vs-sidebar-item',{attrs:{"id":"stations"},nativeOn:{"click":function($event){return _vm.to('/stations')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-station"})]},proxy:true}],null,false,170116571)},[_vm._v(" Станции ")]):_vm._e(),(
      _vm.$role.security.read.includes(_vm.member_role) || _vm.$access.security.read.includes(_vm.access_level)
    )?_c('vs-sidebar-item',{attrs:{"id":"security"},nativeOn:{"click":function($event){return _vm.to('/security')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-lock-alt"})]},proxy:true}],null,false,3849471326)},[_vm._v(" Безопасность ")]):_vm._e(),(_vm.$role.streams.read.includes(_vm.member_role) || _vm.$access.streams.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"streams"},nativeOn:{"click":function($event){return _vm.to('/streams')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-git-branch"})]},proxy:true}],null,false,497880354)},[_vm._v(" Потоки ")]):_vm._e(),(
      _vm.$access.websites.read.includes(_vm.access_level) ||
      (_vm.future_websites && _vm.$role.websites.read.includes(_vm.member_role))
    )?_c('vs-sidebar-item',{attrs:{"id":"websites","to":"/websites"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-compass"})]},proxy:true}],null,false,1523495793)},[_vm._v(" Веб-сайты ")]):_vm._e(),(
      _vm.$access.analytics.read.includes(_vm.access_level) ||
      (_vm.future_analytics && _vm.$role.analytics.read.includes(_vm.member_role))
    )?_c('vs-sidebar-item',{attrs:{"id":"analytics"},nativeOn:{"click":function($event){return _vm.to('/analytics')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-line-chart"})]},proxy:true}],null,false,771709678)},[_vm._v(" Аналитика ")]):_vm._e(),(
      _vm.$access.violations.read.includes(_vm.access_level) ||
      (_vm.future_analytics && _vm.$role.violations.read.includes(_vm.member_role))
    )?_c('vs-sidebar-item',{attrs:{"id":"violations"},nativeOn:{"click":function($event){return _vm.to('/violations')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-error"})]},proxy:true}],null,false,3711603417)},[_vm._v(" Нарушения ")]):_vm._e(),(
      _vm.$access.playlist_history.read.includes(_vm.access_level) ||
      (_vm.future_playlist_history && _vm.$role.playlist_history.read.includes(_vm.member_role))
    )?_c('vs-sidebar-item',{attrs:{"id":"playlist_history"},nativeOn:{"click":function($event){return _vm.to('/playlist-history')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-history"})]},proxy:true}],null,false,1327807235)},[_vm._v(" История эфира ")]):_vm._e(),_c('vs-sidebar-item',{attrs:{"id":"instructions"},nativeOn:{"click":function($event){return _vm.to('/instructions')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-help-circle"})]},proxy:true}])},[_vm._v(" Инструкции ")]),(_vm.isShowAdminSection && _vm.$access.jobs.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"jobs"},nativeOn:{"click":function($event){return _vm.to('/jobs')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-task"})]},proxy:true}],null,false,4283648428)},[_vm._v(" Очередь задач ")]):_vm._e(),(_vm.isShowAdminSection && _vm.$access.users.read.includes(_vm.access_level))?_c('vs-sidebar-item',{attrs:{"id":"users"},nativeOn:{"click":function($event){return _vm.to('/users')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-user"})]},proxy:true}],null,false,1893308880)},[_vm._v(" Пользователи ")]):_vm._e(),_c('vs-sidebar-item',{attrs:{"id":"billing"},nativeOn:{"click":function($event){return _vm.newWindow('https://bill.dline-media.com')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"bx bx-wallet-alt"})]},proxy:true}])},[_vm._v(" Биллинг ")]),(
      _vm.organizations.length > 1 &&
      (_vm.$access.organizations.support.includes(_vm.access_level) || _vm.access_level === 1)
    )?_c('div',{staticClass:"sidebar__group"},[_c('div',{staticClass:"sidebar__label"},[_vm._v("Активная организация")]),_c('BaseSelect',{staticClass:"sidebar__select",attrs:{"field":"id","title":_vm.title,"options":_vm.organizations,"isFirstItem":_vm.isFirstItem,"filter":_vm.isFilter,"method":'organizationsAdmin',"search":'organizationsSearchAdmin',"firstTitle":'name',"firstItem":{
        id: '',
        name: 'Все организации',
      }},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }