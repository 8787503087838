<template>
  <div>
    <div class="header-table">
      <span
        >Количество слушателей:
        <strong :style="count_listeners_style">{{ animatedNumber }}</strong></span
      >
      <span class="header__help-utc">График по UTC+3</span>
      <vs-switch v-if="false" style="margin-left: auto" v-model="auto_update">
        Автообновление
      </vs-switch>
    </div>
    <div class="header-actions">
      <BaseSelect
        class="header-select"
        style="width: 150px; margin-top: 20px; margin-right: 20px"
        v-model="view"
        label="Вид"
        field="id"
        title="name"
        :options="views"
      />
      <date-range-picker
        class="header-select"
        style="width: 285px; margin-top: 20px; margin-right: 20px"
        ref="picker"
        v-model="date_range"
        opens="right"
        :ranges="false"
        :minDate="min_date"
        :maxDate="max_date"
        :time-picker="true"
        @update="updateRangePicker"
        :locale-data="{
          direction: 'ltr',
          format: 'dd.mm.yyyy hh:MM',
          separator: ' - ',
          applyLabel: 'Сохранить',
          cancelLabel: 'Отмена',
          weekLabel: 'Н',
          customRangeLabel: '',
          daysOfWeek: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
          monthNames: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Ноя',
            'Дек',
          ],
          firstDay: 1,
        }"
      >
        <template v-slot:input="picker">
          {{ convertDate(picker.startDate, true) }} - {{ convertDate(picker.endDate, true) }}
        </template>
      </date-range-picker>
      <BaseSelect
        class="header-select"
        v-if="stations?.length > 1"
        style="width: 200px; margin-top: 20px; margin-right: 20px"
        v-model="station"
        label="Станция"
        field="id"
        title="name"
        :options="stations"
        method="stations"
        :filter="true"
        search="stationsSearch"
        @change="changeStation"
      />
      <BaseSelect
        class="header-select"
        style="width: 200px; margin-top: 20px"
        v-if="streams?.length > 1"
        v-model="stream"
        label="Поток"
        field="id"
        title="mount"
        :options="streams"
        @change="changeStream"
      />
    </div>
    <component :is="view.component" field="max_listeners" :loading-data="loading_data" />
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import TableAnalytics from "@/components/Analytics/Table";
import ChartNewAnalytics from "@/components/Analytics/ChartNew";
import Chart from "@/components/Analytics/Chart";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "analytics",
  components: {
    BaseSelect,
    TableAnalytics,
    ChartNewAnalytics,
    Chart,
    DateRangePicker,
  },
  data() {
    return {
      count_listeners_style: "",
      count_listeners: 0,
      tweenedNumber: 0,
      max_date: new Date(),
      min_date: new Date(),
      station: {},
      stream: {},
      loading_data: false,
      active_method: null,
      active_method_id: null,
      timer_id_two: 0,
      view: {
        id: 0,
        name: "График",
        component: Chart,
        // component: ChartNewAnalytics,
      },
      date_range: {
        startDate: null,
        endDate: null,
      },
      views: [
        {
          id: 0,
          name: "График",
          component: Chart,
          // component: ChartNewAnalytics,
        },
        {
          id: 1,
          name: "Таблица",
          component: TableAnalytics,
        },
      ],
      auto_update: false,
      pending: false,
      timer_id: 0,
      interval: 60,
    };
  },
  created() {
    this.loading_data = true;
    this.min_date = new Date(
      this.max_date.getTime() - parseInt(process.env.VUE_APP_ANALYTICS_MAX_RANGE_MILLISECONDS)
    );
    this.date_range = {
      startDate: new Date(this.max_date.getTime() - 86400000), // отнимаем день
      endDate: this.max_date,
    };

    this.$store.commit("stations", []);
    this.$store.commit("select_streams", []);
    this.$store.commit("analytics", []);

    this.$store
      .dispatch("stations")
      .then(() => {
        if (this.stations.length) {
          this.stream = {};
          this.station = this.stations[0];

          if (this.station?.streams_count) {
            this.$store.dispatch("streamsStation", this.station.id).finally(() => {
              if (this.$store.getters.select_streams?.length) {
                this.stream = this.$store.getters.select_streams[0];

                this.listeners_count = this.stream.listeners_count || 0;
                this.active_method = "select_stream";
                this.active_method_id = this.stream.id;
              }

              this.pending = true;
              this.updateData().finally(() => {
                this.pending = false;
              });
            });
          } else {
            this.listeners_count = this.station.listeners_count || 0;
            this.active_method = "station";
            this.active_method_id = this.station.id;
            this.loading_data = false;
          }
          this.getCountListeners();
        } else {
          this.loading_data = false;
        }
      })
      .catch(() => {
        this.loading_data = false;
      });
  },
  watch: {
    station(station) {
      console.log("station");
      this.stream = {};
      this.$store.commit("streams", []);

      if (!station) return;
      if (!Object.keys(station)?.length) return;
      if (!station?.streams_count) {
        this.listeners_count = this.station.listeners_count || 0;
        this.active_method = "station";
        this.active_method_id = this.station.id;

        this.getCountListeners();
        this.pending = true;
        this.updateData().finally(() => {
          this.pending = false;
        });
        return;
      }

      // получение всех потоков у станции
      this.$store.dispatch("streamsStation", this.station.id).finally(() => {
        if (this.$store.getters.select_streams?.length) {
          this.stream = this.$store.getters.select_streams[0];
          this.listeners_count = this.stream.listeners_count || 0;
          this.active_method = "select_stream";
          this.active_method_id = this.stream.id;
        }

        this.getCountListeners();
        this.pending = true;
        this.updateData().finally(() => {
          this.pending = false;
        });
      });
    },
    stream(stream) {
      if (!stream) return;
      if (!Object.keys(stream).length) return;

      this.loading_data = true;
      this.$store.commit("analytics", []);
      this.listeners_count = stream?.listeners_count || 0;

      this.active_method = "select_stream";
      this.active_method_id = stream.id;
      this.getCountListeners();

      this.$store
        .dispatch("analytics", {
          entity: "streams",
          id: stream.id,
          date_from: this.convertDate(this.date_range.startDate),
          date_to: this.convertDate(this.date_range.endDate),
        })
        .finally(() => {
          this.loading_data = false;
        });
    },
    count_listeners(newValue) {
      this.count_listeners_style = "color: red;";
      this.gsap.to(this.$data, { duration: 1, tweenedNumber: newValue }).then(() => {
        setTimeout(() => {
          this.count_listeners_style = "";
        }, 800);
      });
    },
    date_range() {
      this.check_interval();
      if (this.auto_update) {
        this.auto_update_r();
      }
    },
    auto_update(value) {
      if (value) {
        this.check_interval();
        this.auto_update_r();
      } else {
        clearTimeout(this.timer_id);
      }
    },
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
    streams() {
      if (this.$store.getters.select_streams?.length) {
        return this.$store.getters.select_streams;
      } else {
        return [];
      }
    },
    stations() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
  },
  methods: {
    getCountListeners() {
      clearTimeout(this.timer_id_two);
      if (this.active_method && this.active_method_id) {
        this.$store.dispatch(this.active_method, this.active_method_id).then((response) => {
          this.count_listeners = response.listeners_count;
        });
      }
      this.timer_id_two = setTimeout(() => this.getCountListeners(), 60000);
    },
    check_interval() {
      const date1 = new Date(this.date_range.startDate);
      const date2 = new Date(this.date_range.endDate);
      const time_diff = Math.abs(date2.getTime() - date1.getTime()) / 1000; // разница в секундах

      // до 2 часов
      if (time_diff <= 7200) {
        this.interval = 60; // 1m
      }
      // до 6 часов
      else if (time_diff <= 21600) {
        this.interval = 300; // 5m
      }
      // до 12 часов
      else if (time_diff <= 43200) {
        this.interval = 600; // 10m
      }
      // до 24 часов
      else if (time_diff <= 86400) {
        this.interval = 900; // 15m
      }
      // до 7 дней
      else if (time_diff <= 604800) {
        this.interval = 3600; // 1h
      }
      // больше 7 дней
      else if (time_diff > 604800) {
        this.interval = 3600; // 1h
      }
    },
    auto_update_r() {
      this.updateData().finally(() => {
        clearTimeout(this.timer_id);
        this.timer_id = setTimeout(() => {
          this.auto_update_r();
        }, this.interval * 1000);
      });
    },
    updateData() {
      return new Promise((resolve) => {
        this.loading_data = true;
        this.$store.commit("analytics", []);
        if (this.stream?.id) {
          this.$store
            .dispatch("analytics", {
              entity: "streams",
              id: this.stream.id,
              date_from: this.convertDate(this.date_range.startDate),
              date_to: this.convertDate(this.date_range.endDate),
            })
            .finally(() => {
              this.loading_data = false;
              resolve();
            });
        } else if (this.station?.id) {
          this.$store
            .dispatch("analytics", {
              entity: "stations",
              id: this.station.id,
              date_from: this.convertDate(this.date_range.startDate),
              date_to: this.convertDate(this.date_range.endDate),
            })
            .finally(() => {
              this.loading_data = false;
              resolve();
            });
        }
      });
    },
    changeStation() {
      // this.stream = {};
      // this.$store.commit("streams", []);
      // this.$store.dispatch("streamsStation", this.station.id).finally(() => {
      //   if (this.$store.getters.select_streams?.length) {
      //     this.stream = this.$store.getters.select_streams[0];
      //     this.listeners_count = this.stream.listeners_count || 0;
      //     this.active_method = "select_stream";
      //     this.active_method_id = this.stream.id;
      //   } else {
      //     this.listeners_count = this.station.listeners_count || 0;
      //     this.active_method = "station";
      //     this.active_method_id = this.station.id;
      //   }
      //   this.getCountListeners();
      //   this.pending = true;
      //   this.updateData().finally(() => {
      //     this.pending = false;
      //   });
      // });
    },
    changeStream(stream) {
      //   this.loading_data = true;
      //   this.$store.commit("analytics", []);
      //   this.listeners_count = stream.listeners_count || 0;
      //   this.active_method = "select_stream";
      //   this.active_method_id = stream.id;
      //   this.getCountListeners();
      //   this.$store
      //     .dispatch("analytics", {
      //       entity: "streams",
      //       id: stream.id,
      //       date_from: this.convertDate(this.date_range.startDate),
      //       date_to: this.convertDate(this.date_range.endDate),
      //     })
      //     .finally(() => {
      //       this.loading_data = false;
      //     });
    },
    updateRangePicker() {
      this.pending = true;
      this.updateData().finally(() => {
        this.pending = false;
      });
    },
    convertDate(date, view = false) {
      if (date) {
        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        if (view) {
          return `${day}.${month}.${year} ${hours}:${minutes}`;
        } else {
          return `${day}-${month}-${year} ${hours}:${minutes}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header__help-utc {
  margin-left: auto;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
}
.header-table {
  background: #fff;
  padding: 10px 20px 0 20px;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.header-actions {
  background: #fff;
  padding: 10px 20px 22px 20px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .header-select {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
  body {
    .vs-button {
      margin-left: auto !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
