<template>
  <div class="vs-loading--corners">
    <div class="vs-loading__load">
      <div class="vs-loading__load__animation" :style="`width: ${width}px; height: ${height}px;`">
        <div class="vs-loading__load__percent"></div>
        <div class="vs-loading__load__animation__1"></div>
        <div class="vs-loading__load__animation__2"></div>
        <div class="vs-loading__load__animation__3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
}
</script>
