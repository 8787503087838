<template>
  <!-- <vs-dialog prevent-close not-padding v-model="view"> -->
  <section class="setup-notification">
    <h3>Настройка уведомлений</h3>
    <section v-if="!pending">
      <ul class="notifications">
        <li v-for="(notification, key) in notifications" class="notification" :key="key">
          <label>
            <checkbox-simple v-model="notifications[key]['value']" />
            <span>{{ notification.label }}</span>
          </label>
        </li>
      </ul>
      <section class="actions">
        <vs-button @click="back" class="button" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="save" class="button" flat="flat" :active="true" icon="icon">
          <i class="bx bx-save" style="margin-right: 5px"></i>
          <span>Сохранить</span>
        </vs-button>
      </section>
    </section>
    <section v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </section>
  </section>
  <!-- </vs-dialog> -->
</template>

<script>
import { ui } from "@/shared";
import Loader from "@/components/base/Loader";

export default {
  name: "notification",
  components: {
    CheckboxSimple: ui.checkbox.simple,
    Loader,
  },
  data() {
    return {
      pending: true,
      view: true,
      notifications: {
        station_record: {
          label: "Информация о рекордах станции",
          value: false,
        },
        stream_status_changed: {
          label: "Подключение / отключение потока",
          value: false,
        },
        limits: {
          label: "Превышение лимитов",
          value: false,
        },
        // functional: {
        //   label: "функциональные (тишина в эфире)",
        //   value: false,
        // },
      },
    };
  },
  created() {
    this.$store
      .dispatch("organizationNotification", this.$store.getters.idOrganization)
      .then((response) => {
        if (response?.data?.data && typeof response.data.data === "object") {
          Object.keys(response.data.data).forEach((key) => {
            if (this.notifications[key]) {
              this.notifications[key].value = response.data.data[key];
            }
          });
        }
        this.pending = false;
      })
      .catch(() => {
        this.pending = false;
      });
  },
  watch: {
    view(value) {
      if (!value) {
        this.$emit("close");
      }
    },
  },
  methods: {
    save() {
      if (!this.pending) {
        this.pending = true;
        let data = {};
        Object.keys(this.notifications).forEach((key) => {
          data[key] = this.notifications[key].value;
        });
        this.$store
          .dispatch("organizationChangeNotification", {
            id: this.$store.getters.idOrganization,
            data,
          })
          .finally(() => {
            this.pending = false;
          });
      }
    },
    back() {
      this.$emit("back", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-notification {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.notifications {
  margin: 0 0 20px 0;
  padding: 0;
}
h3 {
  text-align: center;
  margin-bottom: 20px;
}
.notification {
  list-style-type: none;
  label {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
}
.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .button {
    margin-left: 10px;
  }
}
.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
