const state = {
	validations: null,
	notification: null,
	notificationTitle: null,
	notificationMessage: null,
	notificationButtons: null,
	notificationStyle: null,
	notificationButton: null,
	errorUpdate: 0,
}

const getters = {
	validations: (s) => s.validations,
	errorUpdate: (s) => s.errorUpdate,
	notification: (s) => s.notification,
	notificationTitle: (s) => s.notificationTitle,
	notificationMessage: (s) => s.notificationMessage,
	notificationButtons: (s) => s.notificationButtons,
	notificationStyle: (s) => s.notificationStyle,
	notificationButton: (s) => s.notificationButton,
}

const actions = {
	async validations({ commit }, validations) {
		commit('validations', null)
		commit('validations', validations)
	},
	async notificationTitle({ commit }, title) {
		commit('notificationTitle', null)
		commit('validations', null)
		commit('notificationTitle', title)
	},
	async notificationMessage({ commit }, message) {
		commit('notificationMessage', null)
		commit('validations', null)
		commit('notificationMessage', message)
	},
	async notificationButtons({ commit }, buttons) {
		commit('notificationButtons', null)
		commit('validations', null)
		commit('notificationButtons', buttons)
	},
	async notificationStyle({ commit }, style) {
		commit('notificationStyle', null)
		commit('validations', null)
		commit('notificationStyle', style)
  },
	async notificationButton({ commit }, button) {
		commit('notificationButton', button)
	},
	async notification({ commit }, noti) {
		commit('notification', null)
		commit('notification', noti)
	},
}

const mutations = {
	validations(state, validations) {
		state.validations = validations
		if (validations !== null) {
			state.errorUpdate += 1
		} else {
			state.errorUpdate = 0
		}
	},
  errorDelete(state, key) {
    delete state.validations[key];
    state.errorUpdate += 1;
  },
	notificationTitle(state, title) {
		state.notificationTitle = title
	},
	notificationMessage(state, message) {
		state.notificationMessage = message
	},
	notificationButtons(state, buttons) {
		state.notificationButtons = buttons
	},
	notificationStyle(state, style) {
		state.notificationStyle = style
	},
	notification(state, noti) {
		state.notification = noti
	},
	notificationButton(state, button) {
		state.notificationButton = button
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
