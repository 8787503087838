<template>
  <input v-model="simple" type="text" :placeholder="placeholder" />
</template>

<script>
export default {
  name: "input-simple",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    simple: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  outline: none;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  background: none;
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  margin-right: 20px;
  &:focus {
    border-bottom: 1px solid #999999;
  }
}
</style>
