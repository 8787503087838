<template>
  <div>
    <ViolationList />
  </div>
</template>

<script>
import { ViolationList } from "@/components/Violations";

export default {
  name: "page-violations",
  components: { ViolationList },
};
</script>

<style lang="scss" scoped></style>
