<template>
	<div class="as-number">
		<div class="increase" @click="increase">+</div>
		<div class="reduce" @click="reduce">-</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: String,
	},
	methods: {
		increase() {
			if (this.value === '') this.value = 0
			this.$emit('change', parseInt(this.value, 10) + 1)
		},
		reduce() {
			if (this.value === '') this.value = 0
			this.$emit('change', parseInt(this.value, 10) - 1)
		},
	},
}
</script>
<style lang="scss">
.as-number {
	width: auto;
	position: relative;
	.vs-input__label {
    left: 20px;
	}
	.increase:hover, .reduce:hover {
		cursor: pointer;
		padding-bottom: 5px;
	}
	.increase, .reduce {
		position: absolute;
		transition: all 0.25s ease;
		transform: translateY(-50%);
		top: 50%;
	}
	.reduce {
		left: 10px;
    z-index: 1;
	}
	.increase {
		right: 10px;
    z-index: 1;
	}
	.vs-input-parent {
		width: 100% !important;
		.vs-input {
			text-align: center;
		}
	}
}
</style>
