<template>
  <div>
    <header ref="header">
      <div class="title">{{ this.$route.meta.title }}</div>
      <div class="flex" style="align-items: center">
        <div
          v-if="
            organizationsCount > 1 &&
            ($access.organizations.support.includes(access_level) ||
              (access_level === 1 && organizations?.length))
          "
          class="header__group"
        >
          <BaseSelect
            class="header__select"
            v-model="organization"
            field="id"
            :title="title"
            :options="organizations"
            :isFirstItem="isFirstItem"
            :filter="isFilter"
            :method="'organizationsAdmin'"
            :search="'organizationsSearchAdmin'"
            :firstTitle="'name'"
            :firstItem="{
              id: '',
              name: 'Все организации',
            }"
          />
        </div>
        <div class="profile-dialog__box">
          <vs-avatar circle @click="toogleMenu">
            <template #text>{{
              $store.getters.userName ? $store.getters.userName[0] : ""
            }}</template>
          </vs-avatar>
          <div class="profile-dialog" :class="isMenu ? 'profile-dialog--active' : ''">
            <div class="profile-dialog__wrapper">
              <div class="profile-dialog__name">{{ $store.getters.userName }}</div>
              <div class="profile-dialog__email">{{ $store.getters.userEmail }}</div>
            </div>
            <ul class="profile-dialog__menu unselectable">
              <li v-if="access_level >= 50" class="profile-dialog__item" @click="viewSection">
                <i v-if="isShowAdminSection" class="bx bx-hide"></i>
                <i v-else class="bx bx-show-alt"></i>
                {{ isShowAdminSection ? "Скрыть " : "Показать " }}разделы
              </li>
              <li class="profile-dialog__item" @click="logout">
                <i class="bx bx-log-out"></i>
                Выйти
              </li>
            </ul>
          </div>
        </div>
        <vs-button class="mobile-menu-button ml20" @click="activeSidebar" flat icon>
          <i class="bx bx-menu"></i>
        </vs-button>
      </div>
    </header>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";

export default {
  components: {
    BaseSelect,
  },
  data() {
    return {
      isSetupNotification: false,
      isMenu: false,
      flag: true,
    };
  },
  created() {
    document.addEventListener("click", (event) => {
      const classList = [
        "profile-dialog__box",
        "vs-avatar",
        "profile-dialog",
        "profile-dialog__wrapper",
        "profile-dialog__name",
        "profile-dialog__email",
        "profile-dialog__menu",
        "profile-dialog__item",
        "bx bx-log-out",
      ];
      if (!classList.includes(event.target.classList["value"])) {
        this.isMenu = false;
      }
    });
    this.$store.dispatch("organizationsAdmin");
  },
  computed: {
    isFilter() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return true;
      } else {
        return false;
      }
    },
    isFirstItem() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return true;
      } else {
        return false;
      }
    },
    title() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return "ID {id} ({name})";
      } else {
        return "name";
      }
    },
    organizationsCount() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return this.$store.getters.organizationCount;
      } else if (this.$store.getters.userAccessOrganizations?.length) {
        return this.$store.getters.userAccessOrganizations.length;
      } else {
        return 0;
      }
    },
    organization: {
      get() {
        const isAdmin = this.$access.organizations.support.includes(
          this.$store.getters.userAccessLevel
        );
        const isUserSelectOrganization = this.$store.getters.userSelectOrganization;
        const isAccessOrganization = this.$store.getters.userAccessOrganizations;

        if (this.flag && isAdmin) {
          this.flag = false;
          return {
            id: "",
            name: "Все организации",
          };
        } else if (isUserSelectOrganization) {
          return this.$store.getters.userSelectOrganization;
        } else if (isAccessOrganization?.length) {
          return this.organizations[0];
        } else {
          return {};
        }
      },
      set(value) {
        this.$store.commit("userSelectOrganization", value);
      },
    },
    organizations() {
      const newOrganizations = [];

      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        this.$store.getters.organizationsAdmin.forEach((organization) => {
          if (!organization.blocked) {
            newOrganizations.push(organization);
          }
        });
      } else if (this.$store.getters.userAccessOrganizations?.length) {
        this.$store.getters.userAccessOrganizations.forEach((organization) => {
          if (!organization.blocked) {
            newOrganizations.push(organization);
          }
        });
      }
      return newOrganizations;
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    isShowAdminSection: {
      get() {
        return this.$store.getters.showAdminSection;
      },
      set(value) {
        this.$store.commit("showAdminSection", value);
      },
    },
  },
  methods: {
    viewSection() {
      this.isShowAdminSection = !this.isShowAdminSection;
    },
    activeSidebar() {
      this.$emit("activeSidebar");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    changeMenu() {
      this.isMenu = !this.isMenu;
    },
    toogleMenu() {
      const body = document.querySelector("body");
      if (!this.isMenu) {
        body.addEventListener("focus", this.changeMenu());
      } else {
        body.removeEventListener("focus", this.changeMenu());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-menu-button {
  width: 44px;
  border-radius: 22px;
}
.title {
  font-weight: bold;
  text-transform: uppercase;
}
.profile-dialog {
  background: #fff;
  position: absolute;
  top: 50px;
  right: -20px;
  z-index: 99;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  overflow: hidden;
  width: 240px;
  max-width: 280px;
  /* opacity: 0; */
  transition: all 0.5s ease;
  display: none;
  /* min-height: 0px; */
  /* height: 0px; */
  &--active {
    display: block;
    /* min-height: 110px; */
    /* opacity: 1; */
  }
  &__box {
    position: relative;
  }
  &__wrapper {
    background: #2968fa;
    color: #fff;
    padding: 15px 20px;
  }
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__email {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__item {
    padding: 10px;
    display: flex;
    color: #9ca4ad;
    transition: all 0.5s ease;
    i {
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      color: #2c3e50;
      background: #f4f7f8;
    }
  }
}
.header__select {
  background: #e0e7eb;
}
.header__group {
  margin-right: 15px;
  width: 270px;
}
@media (max-width: 620px) {
  .header__group {
    display: none;
  }
}
</style>
