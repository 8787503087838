<template>
  <div>
    <div class="x-flex-end flex header-table">
      <BaseSearch
        v-model="search"
        :filterAcive="$store.getters.jobFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
    </div>
    <Table
      :columns="columns"
      :rows="data"
      :meta="meta"
      :name="'jobs'"
      :expandable="true"
      :headerRadius="false"
      :isLoading="loadingData"
    >
      <template v-slot:table-row="{ props }">
        <span
          @click="restart(props.row)"
          v-if="props.column.field === 'actions' && $access.jobs.update.includes(access_level)"
          class="editing"
          >Перезапустить</span
        >
        <span v-else-if="props.column.field === 'created_at'">{{
          props.row.created_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <span v-else-if="props.column.field === 'status'">{{
          convertStatus(props.row.status)
        }}</span>
      </template>
      <template v-slot:expandable="{ props }">
        <div>
          <strong>batch_id:</strong> <span>{{ props.row.batch_id }}</span>
        </div>
        <div>
          <strong>Старт: </strong>
          <span>{{ props.row.started_at | moment("DD.MM.YYYY HH:mm") }}</span>
        </div>
        <div>
          <strong>Обновлена: </strong>
          <span>{{ props.row.updated_at | moment("DD.MM.YYYY HH:mm") }}</span>
        </div>
        <div>
          <strong>Завершена: </strong>
          <span>{{ props.row.finished_at | moment("DD.MM.YYYY HH:mm") }}</span>
        </div>
        <div>
          <strong>job_id: </strong> <span>{{ props.row.job_id }}</span>
        </div>
        <div v-if="props.row.input">
          <strong>input: </strong>
          <div class="code__wrapper">
            <pre class="code">{{ props.row.input }}</pre>
          </div>
        </div>
        <div
          v-if="
            props.row.output &&
            typeof props.row.output === 'object' &&
            !Array.isArray(props.row.output)
          "
        >
          <strong>output: </strong>
          <div class="code__wrapper">
            <pre class="code">{{ props.row.output }}</pre>
          </div>
        </div>
      </template>
    </Table>
    <Alert :show="showModal" :message="message" @close="alert" />
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import BaseSearch from "@/components/base/Search";
import Table from "@/components/Table.vue";
import JobsTableColumns from "@/data/Jobs/JobsTableColumns.json";
import Alert from "@/components/Notification/Alert.vue";

export default {
  name: "Jobs",
  components: {
    Table,
    Alert,
    BaseSearch,
  },
  data() {
    return {
      columns: JobsTableColumns,
      loadingData: true,
      message: "",
      search: this.$store.getters.jobFilterValue,
      showModal: false,
      id: null,
    };
  },
  created() {
    this.$store.commit("jobs", []);
    this.$store.commit("jobsMeta", {});
    this.$store.dispatch("jobs").finally(() => (this.loadingData = false));
  },
  watch: {
    search() {
      this.loadingData = true;
      this.$store.dispatch("jobsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    filters() {
      return this.$store.getters.jobsFilters;
    },
    data() {
      if (this.$store.getters.jobs?.length) {
        return this.$store.getters.jobs;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.jobsMeta) {
        return this.$store.getters.jobsMeta;
      }
      return {};
    },
  },
  methods: {
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("jobFilterActive", value);
      this.$store.dispatch("jobsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    convertStatus(status) {
      switch (status) {
        case "failed":
          return "Ошибка";
        case "finished":
          return "Завершена";
        case "queued":
          return "В очереди";
        case "retrying":
          return "Перезапущенна";
        case "skipped":
          return "Пропущена";
        default:
          return status;
      }
    },
    alert(bool) {
      if (bool) {
        this.$store
          .dispatch("jobRestart", this.id)
          .then(() => {
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно перезапустили задачу.");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось перезапустить задачу.");
              // this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            }
          });
      }
      this.showModal = false;
    },
    restart(row) {
      this.id = row.id;
      this.message = "Вы действительно хотите перезапустить данную задачу?";
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform-origin: 0 50%;
  // transform: translate3d();
  transform-origin: 0 0;

  transform: translateY(-20px);
  opacity: 0;
}
.code {
  overflow: auto;
  max-height: 300px;
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  &__wrapper {
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-left: 4px solid #d5d5d5;
    background: #f1f1f1;
  }
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
