<template>
  <vs-sidebar v-model="active" open>
    <template #logo>
      <div class="logo">
        <img src="@/assets/logo.png" />
        <span class="logo__title">DLine Radio Hosting</span>
      </div>
    </template>
    <vs-sidebar-item
      v-if="isShowAdminSection && $access.clusters.read.includes(access_level)"
      id="clusters"
      @click.native="to('/clusters')"
    >
      <template #icon>
        <i class="bx bx-grid-alt"></i>
      </template>
      Кластеры
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="isShowAdminSection && $access.servers.read.includes(access_level)"
      id="servers"
      @click.native="to('/servers')"
    >
      <template #icon>
        <i class="bx bx-server"></i>
      </template>
      Серверы
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="$access.organizations.read.includes(access_level)"
      id="organizations"
      @click.native="to('/organizations')"
    >
      <template #icon>
        <i class="bx bx-buildings"></i>
      </template>
      Организации
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $role.stations.read.includes(member_role) || $access.stations.read.includes(access_level)
      "
      id="stations"
      @click.native="to('/stations')"
    >
      <template #icon>
        <i class="bx bx-station"></i>
      </template>
      Станции
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $role.security.read.includes(member_role) || $access.security.read.includes(access_level)
      "
      id="security"
      @click.native="to('/security')"
    >
      <template #icon>
        <i class="bx bx-lock-alt"></i>
      </template>
      Безопасность
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="$role.streams.read.includes(member_role) || $access.streams.read.includes(access_level)"
      id="streams"
      @click.native="to('/streams')"
    >
      <template #icon>
        <i class="bx bx-git-branch"></i>
      </template>
      Потоки
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $access.websites.read.includes(access_level) ||
        (future_websites && $role.websites.read.includes(member_role))
      "
      id="websites"
      to="/websites"
    >
      <template #icon>
        <i class="bx bx-compass"></i>
      </template>
      Веб-сайты
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $access.analytics.read.includes(access_level) ||
        (future_analytics && $role.analytics.read.includes(member_role))
      "
      id="analytics"
      @click.native="to('/analytics')"
    >
      <template #icon>
        <i class="bx bx-line-chart"></i>
      </template>
      Аналитика
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $access.violations.read.includes(access_level) ||
        (future_analytics && $role.violations.read.includes(member_role))
      "
      id="violations"
      @click.native="to('/violations')"
    >
      <template #icon>
        <i class="bx bx-error"></i>
      </template>
      Нарушения
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="
        $access.playlist_history.read.includes(access_level) ||
        (future_playlist_history && $role.playlist_history.read.includes(member_role))
      "
      id="playlist_history"
      @click.native="to('/playlist-history')"
    >
      <template #icon>
        <i class="bx bx-history"></i>
      </template>
      История эфира
    </vs-sidebar-item>

    <vs-sidebar-item id="instructions" @click.native="to('/instructions')">
      <template #icon>
        <i class="bx bx-help-circle"></i>
      </template>
      Инструкции
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="isShowAdminSection && $access.jobs.read.includes(access_level)"
      id="jobs"
      @click.native="to('/jobs')"
    >
      <template #icon>
        <i class="bx bx-task"></i>
      </template>
      Очередь задач
    </vs-sidebar-item>
    <vs-sidebar-item
      v-if="isShowAdminSection && $access.users.read.includes(access_level)"
      id="users"
      @click.native="to('/users')"
    >
      <template #icon>
        <i class="bx bx-user"></i>
      </template>
      Пользователи
    </vs-sidebar-item>
    <vs-sidebar-item id="billing" @click.native="newWindow('https://bill.dline-media.com')">
      <template #icon>
        <i class="bx bx-wallet-alt"></i>
      </template>
      Биллинг
    </vs-sidebar-item>
    <div
      v-if="
        organizations.length > 1 &&
        ($access.organizations.support.includes(access_level) || access_level === 1)
      "
      class="sidebar__group"
    >
      <div class="sidebar__label">Активная организация</div>
      <BaseSelect
        class="sidebar__select"
        v-model="organization"
        field="id"
        :title="title"
        :options="organizations"
        :isFirstItem="isFirstItem"
        :filter="isFilter"
        :method="'organizationsAdmin'"
        :search="'organizationsSearchAdmin'"
        :firstTitle="'name'"
        :firstItem="{
          id: '',
          name: 'Все организации',
        }"
      />
    </div>
  </vs-sidebar>
</template>

<script>
import BaseSelect from "@/components/base/Select";

export default {
  components: {
    BaseSelect,
  },
  data() {
    return {
      flag: true,
      future_analytics: process.env.VUE_APP_FEATURE_ANALYTICS === "true" ? true : false,
      future_websites: process.env.VUE_APP_FEATURE_WEBSITES === "true" ? true : false,
      future_playlist_history:
        process.env.VUE_APP_FEATURE_PLAYLIST_HISTORY === "true" ? true : false,
    };
  },
  created() {
    this.$store.dispatch("organizationsAdmin");
  },
  computed: {
    isShowAdminSection() {
      return this.$store.getters.showAdminSection;
    },
    isFilter() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return true;
      } else {
        return false;
      }
    },
    isFirstItem() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return true;
      } else {
        return false;
      }
    },
    title() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return "ID {id} ({name})";
      } else {
        return "name";
      }
    },
    organization: {
      get() {
        const isAdmin = this.$access.organizations.support.includes(
          this.$store.getters.userAccessLevel
        );
        const isUserSelectOrganization = this.$store.getters.userSelectOrganization;
        const isAccessOrganization = this.organizations;

        if (this.flag && isAdmin) {
          this.flag = false;
          return {
            id: "",
            name: "Все организации",
          };
        } else if (isUserSelectOrganization) {
          return this.$store.getters.userSelectOrganization;
        } else if (isAccessOrganization.length) {
          return this.organizations[0];
        } else {
          return {};
        }
      },
      set(value) {
        this.$store.commit("userSelectOrganization", value);
      },
    },
    organizations() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return this.$store.getters.organizationsAdmin;
      } else if (this.$store.getters.userAccessOrganizations?.length) {
        return this.$store.getters.userAccessOrganizations;
      } else {
        return [];
      }
    },
    access_level() {
      return this.$store.getters.userAccessLevel;
    },
    member_role() {
      return this.$store.getters.userMemberRole;
    },
    active: {
      get() {
        return this.$route.name;
      },
      set(value) {},
    },
  },
  methods: {
    newWindow(path) {
      window.open(path, "_blank");
    },
    to(path) {
      this.$router.push(path).catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  &__label {
    opacity: 0.5;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 29px;
  }
  &__select {
    width: 160px;
    margin-left: 29px;
    margin-top: 10px;
  }
  &__group {
    display: none;
  }
}
.logo {
  font-weight: bold;
  display: flex;
  align-items: center;
  &__title {
    margin-top: 5px;
  }
  img {
    height: 25px;
    margin-right: 10px;
  }
}
@media (max-width: 620px) {
  .sidebar {
    &__group {
      display: block;
    }
  }
}
</style>
