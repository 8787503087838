<template>
  <section class="input-password">
    <input v-model="password" type="text" :placeholder="placeholder" @keydown="keydown" />
    <div class="input-password__controls">
      <button @click="randomPassword">
        <i class="bx bxs-dice-3"></i>
      </button>
      <button @click="hasVisiblePassword = !hasVisiblePassword">
        <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
        <i v-else class="bx bx-hide"></i>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "input-password",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Пароль",
    },
  },
  data() {
    return {
      hasVisiblePassword: false,
      availableSymbol: "aAbBcCdDeEfFgGhGiIkKlLmMnNoOpPqQrRsStTvVxXyYzZ0123456789",
    };
  },
  methods: {
    keydown(e) {
      if (![8, 39, 38, 37, 40].includes(e.keyCode)) {
        if (this.value?.length + 1 > 18 || !this.availableSymbol.includes(e.key)) {
          e.preventDefault();
        }
      }
    },
    randomPassword() {
      const maxPasswordLength = 8;
      let password = "";
      for (let i = 0; i < maxPasswordLength; i++) {
        password += this.availableSymbol[Math.floor(Math.random() * this.availableSymbol.length)];
      }
      this.$emit("input", password);
    },
  },
  computed: {
    password: {
      get() {
        if (this.hasVisiblePassword) {
          return this.value.replace(/./g, "*");
        } else {
          return this.value;
        }
      },
      set(value) {
        if (this.hasVisiblePassword) {
          let temp = value.replace(/\*/g, "");
          temp = this.value + temp;
          this.$emit("input", temp);
        } else {
          this.$emit("input", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-password {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    outline: none;
    background: none;
    padding: 5px 0;
    margin: 0;
    font-size: 14px;
    margin-right: 20px;
    &:focus {
      border-bottom: 1px solid #999999;
    }
  }
  &__controls {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    transform: translateY(-50%);
    button {
      display: block;
      padding: 0;
      margin: 0 0 0 10px;
      border: none;
      outline: none;
      background: none;
      color: #999999;
      font-size: 20px;
      &:active {
        transform: scale(0.98);
      }
      &:hover {
        color: #000000;
      }
    }
  }
}
</style>
