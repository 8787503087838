<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'clusters'"
    :headerRadius="false"
    :isLoading="loading"
  >
    <template v-slot:table-row="{ props }">
      <span
        @click="edit(props.row)"
        v-if="props.column.field === 'actions' && $access.clusters.update.includes(access_level)"
        class="editing"
        >Редактировать</span
      >
      <span v-else-if="props.column.field === 'type'">{{ convertType(props.row.type) }}</span>
      <div v-else-if="props.column.field === 'status'">
        <span
          class="indicator-cluster"
          :class="convertStatus(props.row.activity)"
          :data-tooltip="statusTooltip(props.row.activity)"
        ></span>
      </div>
      <span v-else-if="props.column.field === 'listeners_limit'"
        >{{ props.row.listeners_count }} /
        {{
          ![null, undefined].includes(props.row.listeners_limit) ? props.row.listeners_limit : "∞"
        }}</span
      >
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import ClustersTableColumns from "@/data/Clusters/ClustersTableColumns.json";

export default {
  name: "ClustersView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Table,
  },
  data() {
    return {
      columns: ClustersTableColumns,
      dataNone: [],
    };
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    data() {
      if (this.$store.getters.clusters) {
        return this.$store.getters.clusters;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.clustersMeta) {
        return this.$store.getters.clustersMeta;
      }
      return {};
    },
  },
  methods: {
    statusTooltip(status) {
      switch (status) {
        case "green":
          return "ОК";
        case "yellow":
          return "Используется";
        case "red":
          return "Не используется";
        default:
          return status;
      }
    },
    convertStatus(status) {
      if (status) {
        return "indicator-cluster--" + status;
      } else {
        return "";
      }
    },
    convertType(type) {
      switch (type) {
        case "standalone":
          return "Standalone";
        case "ha":
          return "High-Availability";
        default:
          return type;
      }
    },
    edit(row) {
      this.$store.commit("idCluster", row.id);
      this.$emit("block", "editing");
    },
  },
};
</script>

<style lang="scss" scoped>
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
