<template>
  <section class="security-setup">
    <label class="security-label">
      В этом разделе Вы можете ограничить доступ к прослушиванию трансляции на основании
      пользовательских IP-адресов или подсетей.
    </label>

    <loader v-if="networkLoading" style="margin: 30px" />
    <section v-if="!networkLoading">
      <div style="margin-top: 20px">
        <Radio v-model="security" :value="true"
          >Прослушивание запрещено со всех указанных сетей</Radio
        >
        <Radio v-model="security" :value="false"
          >Прослушивание разрешено со всех указанных сетей</Radio
        >
      </div>
    </section>
    <section v-if="securityList?.length && !networkLoading" class="network-search">
      <input-simple v-model="search" placeholder="Поиск..." />
    </section>
    <table v-if="!networkLoading" class="table-xxx">
      <thead>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="securityList?.length === 0">
          <td colspan="2" class="not-security">Защита отключена</td>
        </tr>
        <tr
          v-for="(security, index) in securityList.filter((security) =>
            security.sidr.includes(search)
          )"
          class="animation-row"
          :key="security.id"
          :ref="'row-' + index"
        >
          <td>{{ security.sidr }}</td>
          <td>
            <section class="actions">
              <vs-button
                style="margin-right: 10px"
                icon
                color="danger"
                flat="flat"
                title="Удалить"
                :active="false"
                :loading="networkRemoveIndexLoading === index"
                @click="removeSecurity(security.id, index)"
              >
                <i class="bx bx-x-circle"></i>
              </vs-button>
            </section>
          </td>
        </tr>
        <tr class="cotroll">
          <td>
            <input-network v-model="network" :danger="isDanger" />
          </td>
          <td>
            <vs-button
              icon
              flat="flat"
              title="Добавить"
              :loading="loading"
              :active="false"
              @click="createSecurity"
            >
              <i class="bx bx-plus-circle"></i>
            </vs-button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import "./style.scss";
import Radio from "@/components/Radio";
import Loader from "@/components/base/Loader";
import { InputNetwork, InputSimple } from "@/components/input";

export default {
  name: "page-security-network",
  components: { InputNetwork, Loader, InputSimple, Radio },
  data() {
    return {
      search: "",
      networkLoading: false,
      securityId: null,
      security: false,
      securityList: [],
      network: "",
      isDanger: false,
      loading: false,
      networkRemoveIndexLoading: null,
      isEditSecurity: false,
    };
  },
  watch: {
    security(value) {
      // if (this.securityList?.length) {
      if (this.isEditSecurity) {
        this.$store.dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !value,
            networks: this.securityList,
          },
        });
      }
    },
  },
  created() {
    this.networkLoading = true;
    this.$store
      .dispatch("securities", {
        id: this.$route.params.stationId,
        additionFilter: "filter[security_type]=network",
      })
      .then((response) => {
        if (response?.length) {
          this.isEditSecurity = true;

          this.securityId = response[0].id;
          this.security = !Boolean(response[0].white_list);
          this.securityList = response[0].security_network;
        }
      })
      .finally(() => {
        this.networkLoading = false;
      });
  },
  methods: {
    removeSecurity(networkId, index) {
      this.isDanger = false;
      this.networkRemoveIndexLoading = index;
      const networks = this.securityList.filter((network) => network.id !== networkId);

      this.$store
        .dispatch("updateSecurity", {
          id: this.securityId,
          data: {
            white_list: !this.security,
            networks,
          },
        })
        .then(() => {
          const row = this.$refs["row-" + index][0];
          row.classList.remove("animation-row");
          row.classList.add("animation-remove-row");
          this.securityList = networks;
        })
        .catch(() => {
          this.isDanger = true;
        })
        .finally(() => {
          this.networkRemoveIndexLoading = null;
        });
    },
    createSecurity() {
      this.loading = true;
      this.isDanger = false;
      if (this.network) {
        // if (this.securityList?.length) {
        if (this.isEditSecurity) {
          this.$store
            .dispatch("updateSecurity", {
              id: this.securityId,
              data: {
                white_list: !this.security,
                networks: this.securityList
                  .map((network) => {
                    return {
                      sidr: network.sidr,
                    };
                  })
                  .concat([{ sidr: this.network }]),
              },
            })
            .then((response) => {
              const networks = response.data.data.security_network.filter(
                (network) => network.sidr === this.network
              );
              if (networks.length) {
                this.securityList.push(networks[0]);
              } else {
                this.securityList.push({ sidr: this.network });
              }
              this.network = "";
            })
            .catch(() => {
              this.isDanger = true;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$store
            .dispatch("createSecurity", {
              station_id: this.$route.params.stationId,
              security_type: "network",
              white_list: !this.security,
              order: 2,
              networks: [{ sidr: this.network }],
            })
            .then((response) => {
              const networks = response.data.data.security_network.filter(
                (network) => network.sidr === this.network
              );
              if (networks.length) {
                this.securityList.push(networks[0]);
              } else {
                this.securityList.push({ sidr: this.network });
              }
              this.network = "";
              this.loading = false;
            })
            .catch(() => {
              this.isDanger = true;
              this.loading = false;
            });
        }
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.security-mode {
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  &__label {
    font-size: 13px;
    color: #324455;
    margin-right: 20px;
  }
  .vs-switch {
    transform: scale(0.7);
  }
}

.table-xxx {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  thead {
    td {
      border-bottom: 1px solid #f0f3f4;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #f9fcfd;
      }
      td {
        padding: 10px 10px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: block;
      }
      td {
        border: none;
        display: block;
      }
    }
  }
}
.cotroll {
  background: none !important;
  td {
    border-top: 1px solid #f0f3f4;
  }
}
.actions {
  display: flex;
  transition: height 0.3s ease;
}
.security-mode {
  margin-top: 20px;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
  /* justify-content: flex-end; */
  &__label {
    font-size: 13px;
    color: #324455;
    margin-right: 20px;
  }
  .vs-switch {
    transform: scale(0.7);
  }
  &__description {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.animation-remove-row {
  td {
    animation: 0.3s 1 linear removeRow forwards;
    .actions {
      height: 0px !important;
      overflow: hidden;
    }
  }
}
.animation-row {
  td {
    animation: 0.3s 1 linear row;
  }
}
@keyframes row {
  from {
    opacity: 0;
    padding: 0px 10px;
  }
  to {
    opacity: 1;
    padding: 10px;
  }
}
@keyframes removeRow {
  from {
    opacity: 1;
    padding: 10px;
  }
  to {
    font-size: 0;
    opacity: 0;
    padding: 0px 10px;
  }
}
.network-search {
  margin-top: 20px;
  max-width: 250px;
  padding-left: 10px;
  @media (max-width: 500px) {
    max-width: 100%;
  }
}
.not-security {
  opacity: 0.5;
  text-align: center;
  padding: 30px !important;
}
</style>
