<template>
  <div class="edit-stream">
    <div class="stream-header">Редактирование потока</div>
    <div v-if="!errorMessage && !stationsLoading && !loading">
      <div class="two-column">
        <div class="column-1">
          <help-field :help="'Выберите станцию'">
            <BaseSelect
              required
              :label="'Станция'"
              v-model="station"
              :options="stations"
              :field="'id'"
              :title="'name'"
              :filter="true"
              :method="'stations'"
              :search="'stationsSearch'"
              :loading="stationsLoading"
            />
          </help-field>
          <div class="h20"></div>
          <help-field
            :help="'Выберите режим:<br>1. <strong>Обычный</strong> - для вещания со своего компьютера<br>2. <strong>Ретранслятор</strong> - для копирования потока с другого сервера'"
          >
            <BaseSelect
              required
              :state="isDanger('mode')"
              :label="'Режим'"
              v-model="mode"
              :options="modeOptions"
            />
          </help-field>
          <div class="h20"></div>
          <help-field
            :help="'Укажите название потока на латинице без символа «/». Это название будет использовано в ссылке для прослушивания.'"
          >
            <BaseInput
              :state="isDanger('mount')"
              label-placeholder="Mount"
              required
              v-model="mount"
            />
          </help-field>
          <div class="h20"></div>
          <div v-if="mode.field === 'live'">
            <help-field :help="'Задайте пароль для подключения с программы источника'">
              <BaseInput
                type="password"
                :state="isDanger('password')"
                label-placeholder="Пароль"
                required
                v-model="password"
                :autocomplete="'new-password'"
                icon-after
                :visiblePassword="hasVisiblePassword"
                @click-icon="hasVisiblePassword = !hasVisiblePassword"
              >
                <template #icon>
                  <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
                  <i v-else class="bx bx-hide"></i>
                </template>
              </BaseInput>
              <i @click="genPassword" class="generate-password bx bx-dice-5"></i>
            </help-field>
            <div class="h20"></div>
          </div>
          <help-field :help="'Лимит слушателей'">
            <BaseInput
              :state="isDanger('listeners_limit')"
              label-placeholder="Лимит слушателей"
              v-model="listeners_limit"
            />
          </help-field>
          <div v-if="mode.field === 'relay'">
            <div class="h20"></div>
            <help-field :help="'Порт источника'">
              <BaseInput
                :state="isDanger('relay_port')"
                label-placeholder="Порт источника"
                v-model="relay_port"
              />
            </help-field>
            <div class="h20"></div>
            <help-field :help="'Укажите название потока на латинице без символа «/».'">
              <BaseInput
                required
                :state="isDanger('relay_mount')"
                label-placeholder="Mount источника"
                v-model="relay_mount"
              />
            </help-field>
          </div>
        </div>
        <div class="column-2">
          <help-field :help="'Выберите битрейт в kb/s'">
            <BaseSelect
              :state="isDanger('bitrate')"
              :label="'Битрейт'"
              v-model="bitrate"
              :options="bitrateOptions"
            />
          </help-field>
          <div class="h20"></div>
          <help-field v-if="['encoder'].includes(mode.field)" :help="'Выберите выходной формат'">
            <BaseSelect
              required
              :state="isDanger('output_format')"
              :label="'Выходной формат'"
              v-model="outputFormat"
              :options="outputFormats"
            />
          </help-field>
          <div v-if="['encoder'].includes(mode.field)" class="h20"></div>
          <help-field v-if="['encoder'].includes(mode.field)" help="Выберите источник сигнала">
            <BaseSelect
              required
              v-model="source_stream"
              :label="'Источник сигнала'"
              :options="sourceStreams"
              :field="'id'"
              :title="'mount'"
              :filter="true"
              :method="'select_streams'"
              :search="'select_streams_search'"
              :loading="streamEncoderLoading"
            />
          </help-field>
          <div v-if="['encoder'].includes(mode.field)" class="h20"></div>
          <help-field
            v-if="['live', 'relay'].includes(mode.field)"
            help="Поток, на который будут переправлены слушатели в случае отключения редактируемого потока"
          >
            <BaseSelect
              :label="'Резервный поток'"
              v-model="fallback_stream"
              :options="streams"
              :field="'id'"
              :title="'mount'"
              :filter="true"
              :method="'select_streams'"
              :search="'select_streams_search'"
            />
          </help-field>
          <div v-if="['live', 'relay'].includes(mode.field)" class="h20"></div>
          <help-field
            v-if="['live', 'relay'].includes(mode.field) && fallback_stream.id !== 0"
            help="Возврат слушателей в случае возобновления вещания редактируемого потока"
            style="margin-bottom: 24px"
          >
            <div class="fallback-stream">
              <div class="fallback-stream__label">Возврат с резерва</div>
              <vs-switch
                class="mobile"
                v-model="fallback_override"
                style="margin-left: -10px"
              ></vs-switch>
            </div>
          </help-field>
          <div class="h20" v-if="mode.field === 'live'"></div>
          <div v-if="mode.field === 'relay'">
            <help-field :help="'Протокол источника'">
              <BaseSelect
                required
                :state="isDanger('relay_protocol')"
                :label="'Протокол источника'"
                v-model="relay_protocol"
                :options="protocolOptions"
              />
            </help-field>
            <div class="h20"></div>
            <help-field :help="'Сервер источника'">
              <BaseInput
                required
                :state="isDanger('relay_host')"
                label-placeholder="Сервер источника"
                v-model="relay_host"
              />
            </help-field>
          </div>
        </div>
      </div>
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="mr20">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="editStream" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
      </div>
    </div>
    <div v-else-if="errorMessage" class="stream__error-message">
      {{ errorMessage }}
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import Alert from "@/components/Notification/Alert.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "StreamsEdit",
  components: {
    HelpField,
    Alert,
    BaseSelect,
    BaseInput,
    Loader,
  },
  data() {
    return {
      streamEncoderLoading: false,
      activeWatch: true,
      hasVisiblePassword: false,
      fallback_override: true,
      fallback_stream: {
        id: 0,
      },
      source_stream: {},
      errorMessage: null,
      id: null,
      timerId: 0,
      station: {},
      listeners_limit: "",
      stationsLoading: true,
      mode: {
        field: "live",
        label: "Обычный",
      },
      modeOptions: [
        {
          field: "live",
          label: "Обычный",
        },
        {
          field: "relay",
          label: "Ретранслятор",
        },
        {
          field: "encoder",
          label: "Перекодировщик",
        },
      ],
      mount: "",
      password: "",
      outputFormat: {
        label: "MP3",
        field: "mp3",
      },
      outputFormats: [
        {
          label: "MP3",
          field: "mp3",
        },
        {
          label: "AAC+",
          field: "aac+",
        },
      ],
      bitrate: {
        label: 32,
        field: 32,
      },
      bitrateOptions: [
        {
          label: 32,
          field: 32,
        },
        {
          label: 64,
          field: 64,
        },
        {
          label: 128,
          field: 128,
        },
        {
          label: 192,
          field: 192,
        },
        {
          label: 256,
          field: 256,
        },
        {
          label: 320,
          field: 320,
        },
      ],
      relay_protocol: {
        field: null,
        label: null,
      },
      protocolOptions: [
        {
          field: "https",
          label: "https",
        },
        {
          field: "http",
          label: "http",
        },
      ],
      relay_host: "",
      relay_port: "",
      relay_mount: "",
      status: {
        field: "published",
        label: "published",
      },
      statusOptions: [
        {
          field: "published",
          label: "published",
        },
        {
          field: "unpublished",
          label: "unpublished",
        },
      ],
      showModal: false,
      message: "",
      loading: false,
      oldData: null,
      newData: null,
    };
  },
  watch: {
    mode(value) {
      if (this.activeWatch && value.field === "relay") {
        this.relay_protocol = {
          field: "http",
          label: "http",
        };
      }
      this.activeWatch = true;

      if (!this.streamEncoderLoading && this.station?.id && value.field === "encoder") {
        this.streamEncoderLoading = true;
        this.$store
          .dispatch("select_streams", {
            filters: `filter[mode]=live,relay${
              this.station?.id ? "&filter[station_id]=" + this.station.id : ""
            }`,
          })
          .finally(() => {
            if (this.sourceStreams?.length) {
              const sourceFilter = this.sourceStreams.filter(
                (stream) => stream.id === this.$store.getters.stream.source_stream
              );
              if (sourceFilter?.length) {
                this.source_stream = sourceFilter[0];
              } else {
                this.source_stream = this.sourceStreams[0];
              }
            }
            this.$emit("close", true);
            this.streamEncoderLoading = false;
          });
      }
    },
    station(station) {
      if (!this.streamEncoderLoading && station?.id && this.mode.field === "encoder") {
        this.streamEncoderLoading = true;
        this.$store
          .dispatch("select_streams", {
            filters: `filter[mode]=live,relay${
              station?.id ? "&filter[station_id]=" + station.id : ""
            }`,
          })
          .finally(() => {
            if (this.sourceStreams?.length) {
              const sourceFilter = this.sourceStreams.filter(
                (stream) => stream.id === this.$store.getters.stream.source_stream
              );
              if (sourceFilter?.length) {
                this.source_stream = sourceFilter[0];
              } else {
                this.source_stream = this.sourceStreams[0];
              }
            }
            this.$emit("close", true);
            this.streamEncoderLoading = false;
          });
      }
    },
  },
  created() {
    const bitrateLimit = this.$store.getters.userSelectOrganization?.stream_bitrate_limit || null;
    if (bitrateLimit) {
      this.bitrate = {
        label: bitrateLimit,
        field: bitrateLimit,
      };

      this.bitrateOptions = this.bitrateOptions.map((bitrate) => {
        if (bitrate.field > bitrateLimit) {
          bitrate["blocked"] = true;
          bitrate["message"] = " (Недоступно на Вашем тарифном плане)";
        }
        return bitrate;
      });
    }

    this.$emit("close", false);
    this.$store.dispatch("stream", this.$store.getters.idStream).then(() => {
      this.id = this.$store.getters.stream?.id;
      this.listeners_limit = this.$store.getters.stream.listeners_limit;
      this.station = this.$store.getters.stream.station;
      this.activeWatch = false;
      this.mode = {
        field: this.$store.getters.stream.mode,
        label: this.convertMode(this.$store.getters.stream.mode),
      };
      this.mount = this.$store.getters.stream.mount;

      this.bitrate = {
        label: this.$store.getters.stream.bitrate,
        field: this.$store.getters.stream.bitrate,
      };

      this.password = this.$store.getters.stream.password;

      if (this.mode.field === "encoder") {
        this.outputFormat = {
          field: this.$store.getters.stream.output_format,
          label:
            typeof this.$store.getters.stream.output_format === "string"
              ? this.$store.getters.stream.output_format.toUpperCase()
              : "",
        };

        if (this.sourceStreams?.length) {
          this.streamEncoderLoading = true;
          const sourceFilter = this.sourceStreams.filter(
            (stream) => stream.id === this.$store.getters.stream.source_stream
          );
          if (sourceFilter?.length) {
            this.source_stream = sourceFilter[0];
          }
          this.streamEncoderLoading = false;
        }
      }

      if (this.mode.field === "relay") {
        this.relay_protocol = {
          field: this.$store.getters.stream.relay_protocol,
          label: this.$store.getters.stream.relay_protocol,
        };
        this.relay_host = this.$store.getters.stream.relay_host;
        this.relay_port = this.$store.getters.stream.relay_port;
        this.relay_mount = this.$store.getters.stream.relay_mount;
      }

      this.status = {
        field: this.$store.getters.stream.status,
        label: this.$store.getters.stream.status,
      };

      this.oldData = {
        station_id: this.station?.id,
        listeners_limit: this.listeners_limit,
        mode: this.mode.field,
        mount: this.mount,
        password: this.password,
        output_format: this.outputFormat.field,
        source_stream: this.source_stream.id,
        bitrate: this.bitrate.field,
        relay_protocol: this.relay_protocol.field,
        relay_host: this.relay_host,
        relay_port: this.relay_port,
        relay_mount: this.relay_mount,
        status: this.status.field,
        fallback_stream_id: this.$store.getters.stream.fallback_stream_id || null,
        fallback_override:
          this.$store.getters.stream.fallback_override === 1
            ? true
            : this.$store.getters.stream.fallback_override === 0
            ? false
            : null,
      };

      this.$store.dispatch("stations").finally(() => {
        if (this.mode.field !== "encoder") {
          this.$store.dispatch("select_streams").finally(() => {
            if (this.$store.getters.stream.fallback_stream_id) {
              this.$store
                .dispatch("select_stream", this.$store.getters.stream.fallback_stream_id)
                .catch((error) => {
                  const status = error.response.status;
                  if (status === 403) {
                    this.errorMessage =
                      "У Вас недостаточно прав для редактирования данного потока.";
                  }
                })
                .finally(() => {
                  if (this.$store.getters.select_stream) {
                    this.fallback_stream = this.$store.getters.select_stream;
                  }
                  if (
                    this.$store.getters.stream.fallback_override === null ||
                    this.$store.getters.stream.fallback_override === undefined
                  ) {
                    this.fallback_override = true;
                  } else {
                    this.fallback_override = Boolean(this.$store.getters.stream.fallback_override);
                  }
                  this.stationsLoading = false;
                  this.$emit("close", true);
                });
            } else {
              this.fallback_stream = {
                id: 0,
                mount: "Не выбран",
              };
              if (
                this.$store.getters.stream.fallback_override === null ||
                this.$store.getters.stream.fallback_override === undefined
              ) {
                this.fallback_override = true;
              } else {
                this.fallback_override = Boolean(this.$store.getters.stream.fallback_override);
              }
              this.stationsLoading = false;
              this.$emit("close", true);
            }
          });
        } else {
          this.stationsLoading = false;
        }
      });
    });
  },
  computed: {
    sourceStreams() {
      if (this.$store.getters.select_streams?.length) {
        return this.$store.getters.select_streams;
      } else {
        return [];
      }
    },
    streams() {
      const default_response = [
        {
          id: 0,
          mount: "Не выбран",
        },
      ];

      if (this.$store.getters.select_streams?.length) {
        const filtered = this.$store.getters.select_streams.filter(
          (stream) => stream.mount !== this.oldData["mount"]
        );
        const temp = default_response.concat(filtered);
        return temp;
      } else {
        return [];
      }
    },
    stations() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
  },
  methods: {
    convertMode(mode) {
      switch (mode) {
        case "live":
          return "Обычный";
        case "relay":
          return "Ретранслятор";
        case "encoder":
          return "Перекодировщик";
        default:
          return mode;
      }
    },
    copyBuffer(text) {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    generatePassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";

      for (let i = 0, n = charset.length; i < 15; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      return password;
    },
    genPassword() {
      const password = this.generatePassword();
      this.copyBuffer(password);
      this.password = password;
    },
    alert(bool) {
      if (bool) {
        this.$emit("close", false);
        this.loading = true;
        this.$store
          .dispatch("delStream", this.id)
          .then((response) => {
            this.$store.commit("updateStream", response);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили поток");
            this.back(true);
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось удалить поток");
              this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            } else {
              // Что-то произошло при настройке запроса, вызвавшего ошибку
            }
          });
      }
      this.showModal = false;
    },
    del() {
      if (this.loading) {
        return;
      }
      this.message = "Вы действительно хотите удалить данный поток?";
      this.showModal = true;
    },
    editStream() {
      this.$emit("close", false);
      if (this.loading) {
        return;
      }
      this.errorsField = [];

      this.newData = {
        station_id: this.station?.id || null,
        mode: this.mode.field,
        mount: this.mount,
        listeners_limit: this.listeners_limit,
        bitrate: this.bitrate.field,
        status: this.status.field,
      };

      if (this.newData.mode === "live") {
        this.newData["password"] = this.password;
      }

      if (this.newData.mode === "encoder") {
        this.newData["source_stream"] = this.source_stream.id;
        this.newData["output_format"] = this.outputFormat.field;
      }

      if (this.newData.mode === "relay") {
        this.newData["relay_protocol"] = this.relay_protocol.field;
        this.newData["relay_host"] = this.relay_host;
        this.newData["relay_port"] = this.relay_port;
        this.newData["relay_mount"] = this.relay_mount;
      }
      if (this.fallback_stream.id !== 0) {
        this.newData["fallback_stream_id"] = this.fallback_stream.id;
      }

      const temp = {};
      Object.keys(this.newData).map((key) => {
        if ("" + this.oldData[key] !== "" + this.newData[key]) {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (this.mode.field !== "encoder") {
        temp["fallback_override"] = this.fallback_override;
      }

      if (Object.keys(temp).length > 0) {
        this.loading = true;
        this.$store
          .dispatch("editStream", {
            id: this.id,
            data: temp,
          })
          .then((response) => {
            this.$store.commit("updateStream", response);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили поток");
            this.back(true);
          })
          .catch(() => (this.loading = false))
          .finally(() => {
            this.$emit("close", true);
          });
      } else {
        this.$emit("close", true);
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-stream {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-height: 600px) {
    max-height: 100%;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.stream-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.generate-password {
  position: absolute;
  top: 12px;
  right: 40px;
  transition: all 0.4s ease;
  &:hover {
    cursor: pointer;
    transform: rotate(35deg);
  }
  &:active {
    transform: scale(0.9);
  }
}
.two-column {
  display: flex;
}
.stream__error-message {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  height: 70px;
}
.column-1 {
  width: 250px;
  margin-right: 35px;
}
.column-2 {
  width: 250px;
}
.loading {
  width: 100%;
  height: 390px;
  z-index: 9999999;
  top: 80px;
  left: 0;
  background: #fff;
}
@media (max-width: 600px) {
  .edit-stream {
    max-height: 100%;
  }
  .two-column {
    flex-direction: column;
  }
  .column-1 {
    width: 100%;
    margin-right: 0;
  }
  .column-2 {
    margin-top: 20px;
    width: 100%;
  }
  .loading {
    width: 200px;
    height: 200px;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.fallback-stream {
  display: flex;
  width: 100%;
  height: 38px;
  align-items: center;
  &__label {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
  }
}
</style>
