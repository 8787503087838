import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";

const state = {
  showAdminSection: true,
  name: null,
  email: null,
  access_level: null,
  created_at: null,
  updated_at: null,
  users: null,
  usersMeta: null,

  access_organizations: [],
  select_organization: null,
  member_role: null,
  pending: false,
  userFilterActive: "email",
  userFilterValue: "",
  usersFilters: {
    email: "E-mail",
    name: "Имя",
  },
};

const getters = {
  showAdminSection: (s) => s.showAdminSection,
  userName: (s) => s.name,
  userEmail: (s) => s.email,
  userAccessLevel: (s) => s.access_level,
  users: (s) => s.users,
  usersMeta: (s) => s.usersMeta,

  userAccessOrganizations: (s) => s.access_organizations,
  userSelectOrganization: (s) => s.select_organization,
  userMemberRole: (s) => s.member_role,

  userFilterActive: (s) => s.userFilterActive,
  userFilterValue: (s) => s.userFilterValue,
  usersFilters: (s) => s.usersFilters,
};

const actions = {
  async editUser({ dispatch }, { id, data }) {
    const response = await axios.patch(`/users/${id}`, data);
    return response.data;
  },
  async users({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }

    const response = await axios.get(
      `/users?page=${page}&per_page=${per_page}&&filter[${state.userFilterActive}]=${state.userFilterValue}`
    );
    commit(concat ? "usersNext" : "users", response.data.data);
    commit("usersMeta", response.data.meta);
    return response.data.data;
  },
  async usersSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.userFilterValue = value;
    const response = await axios.get(
      `/users?filter[${state.userFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("users", response.data.data);
    commit("usersMeta", response.data.meta);
    return response.data.data;
  },
  async getUser({ state, commit }) {
    if (!state.pending) {
      state.pending = true;
      const response = await axios.get("/users/current");
      commit("setUser", response.data.data);
      return response;
    }
  },
};

const mutations = {
  showAdminSection(state, value) {
    state.showAdminSection = value;
  },
  userFilterActive(state, filter) {
    state.userFilterActive = filter;
  },
  users(state, data) {
    state.users = data;
  },
  usersNext(state, data) {
    state.users = state.users.concat(data);
  },
  usersMeta(state, data) {
    state.usersMeta = data;
  },
  setUser(state, data) {
    state.name = data.name;
    state.email = data.email;
    state.access_level = data.access_level;
    state.access_organizations = data.organizations;

    if (state.access_organizations.length) {
      if (Vue.prototype.$access.organizations.support.includes(state.access_level)) {
        state.select_organization = {
          id: "",
          name: "Все организации",
        };
      } else {
        state.select_organization = state.access_organizations[0];
      }
      state.member_role = state.select_organization.member_role;
    }

    state.created_at = data.created_at;
    state.updated_at = data.updated_at;
    state.pending = false;
  },
  userSelectOrganization(state, data) {
    state.select_organization = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
