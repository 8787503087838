import axios from "axios";
import Cookies from "js-cookie";

const state = {
  countries: null,
};

const getters = {
  countries: (s) => s.countries,
};

const actions = {
  async countries({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }
    const response = await axios.get(`/countries?page=${page}&per_page=${per_page}`);
    commit("countries", response.data);
    return response.data;
  },
  async countriesSearch({ commit }, { value, parameters }) {
    let per_page = Cookies.get("perPage") || "25";
    let notStore = false;
    let additionFilter = "";

    if (parameters?.notStore) {
      notStore = parameters.notStore;
    }

    if (parameters?.additionFilter) {
      additionFilter = parameters.additionFilter;
    }

    const response = await axios.get(`/countries?filter[name]=${value}&per_page=${per_page}`);
    if (!notStore) {
      commit("countries", response.data);
    }
    return response.data;
  },
};

const mutations = {
  countries(state, data) {
    state.countries = data;
  },
  countriesNext(state, data) {
    state.countries = state.countries.concat(data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
