<template>
  <span class="icon">
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M499.987 441.994L357.588 299.594C353.604 295.611 347.148 295.611 343.163 299.594L328.585 314.172L306.661 292.248C334.315 261.209 351.149 220.328 351.149 175.584C351.149 78.772 272.386 0.0090332 175.574 0.0090332C78.762 0.0090332 0 78.772 0 175.585C0 272.398 78.763 351.16 175.575 351.16C220.319 351.16 261.199 334.327 292.239 306.673L314.163 328.597L299.585 343.175C295.602 347.158 295.602 353.616 299.585 357.6L441.983 499.998C441.983 499.998 441.983 499.998 441.984 499.998C449.98 507.993 460.482 511.991 470.985 511.991C481.488 511.991 491.991 507.993 499.986 499.998C507.734 492.252 512 481.952 512 470.997C512 460.039 507.734 449.74 499.987 441.994ZM175.575 330.761C90.01 330.761 20.398 261.149 20.398 175.585C20.398 90.02 90.01 20.408 175.575 20.408C261.14 20.408 330.752 90.02 330.752 175.585C330.752 261.15 261.14 330.761 175.575 330.761ZM485.563 485.573C477.526 493.611 464.448 493.612 456.407 485.573H456.408L321.221 350.386L350.376 321.231L485.563 456.417C489.456 460.311 491.601 465.488 491.601 470.995C491.602 476.502 489.457 481.679 485.563 485.573Z"
        fill="currentColor"
      />
      <path
        d="M58.3088 182.024C58.1938 179.894 58.1358 177.727 58.1358 175.584C58.1358 169.951 53.5698 165.385 47.9368 165.385C42.3038 165.385 37.7378 169.951 37.7378 175.584C37.7378 178.092 37.8058 180.63 37.9408 183.124C38.2348 188.561 42.7358 192.773 48.1168 192.773C48.3028 192.773 48.4888 192.768 48.6758 192.758C54.2998 192.456 58.6128 187.65 58.3088 182.024Z"
        fill="currentColor"
      />
      <path
        d="M175.575 293.024C131.218 293.024 91.116 268.465 70.921 228.933C68.358 223.917 62.217 221.927 57.198 224.49C52.182 227.053 50.192 233.196 52.755 238.213C76.454 284.604 123.516 313.423 175.574 313.423C181.207 313.423 185.773 308.857 185.773 303.224C185.773 297.591 181.208 293.024 175.575 293.024Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "iconLupa",
}
</script>

<style lang="scss" scoped>
.icon {
  color: #000;
  opacity: 0.5;
  height: 10px;
  width: 10px;
  display: inline-block;
  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
</style>
