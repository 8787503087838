<template>
  <div class="streams-info">
    <label>Информация о подключении</label>
    <div v-if="!loading">
      <div v-if="isLive" class="streams-info__text">
        Для подключения к вещательному серверу используйте следующие данные.
      </div>
      <div v-if="isLive" class="streams-info__text" style="margin-bottom: 15px">
        Сервер для подключения: <text-copy :text-copy="connection_fqdn || 'не указан'"><strong>{{ connection_fqdn || "не указан" }}</strong></text-copy>
      </div>
      <div v-if="isLive" class="streams-info__text">
        Порт{{ connection_ports.length > 1 ? "ы" : "" }} для подключения:
        <strong v-if="!connection_ports.length">не указан</strong>
        <text-copy v-for="(connection_port, index) in connection_ports" :key="'port-'+index" :text-copy="connection_port.port">
          <strong>{{ connection_port.port
            }}</strong>{{ connection_ports.length > 1 && (index + 1) < connection_ports.length ? ", " : "" }}
        </text-copy>
      </div>
      <div v-if="isLive" class="streams-info__text">
        Точка монтирования: <text-copy :text-copy="'/' + mount"><strong>/{{ mount }}</strong></text-copy>
      </div>
      <div v-if="isLive" class="streams-info__text" style="margin-bottom: 15px">
        Пароль: <text-copy :text-copy="password"><strong>{{ password }}</strong></text-copy>
      </div>
      <div class="streams-info__text" style="margin-bottom: 15px">
        Информация о количестве слушателей и плеер доступен на странице
        <strong
          ><a :href="`https://${listen_host_fqdn}`">https://{{ listen_host_fqdn }}</a></strong
        >
      </div>
      <div class="streams-info__text">
        Также вы можете слушать поток напрямую по ссылке
        <strong
          ><a :href="`https://${listen_host_fqdn}/${mount}`"
            >https://{{ listen_host_fqdn }}/{{ mount }}</a
          ></strong
        >
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/base/Loader";
import TextCopy from "@/components/base/TextCopy";

export default {
  name: "Info",
  components: {
    Loader,
    TextCopy,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$store.dispatch("stream", this.$store.getters.idStream).finally(() => {
      this.$store
        .dispatch("organizationsConnectionSettings", this.$store.getters.idOrganization)
        .finally(() => {
          this.loading = false;
        });
    });
  },
  computed: {
    isLive() {
      if (this.$store.getters.stream && this.$store.getters.stream.mode) {
        return this.$store.getters.stream.mode == "live";
      } else {
        return false;
      }
    },
    mount() {
      if (this.$store.getters.stream) {
        return this.$store.getters.stream.mount;
      } else {
        return "";
      }
    },
    password() {
      if (this.$store.getters.stream) {
        return this.$store.getters.stream.password;
      } else {
        return "";
      }
    },
    connection_fqdn() {
      if (this.$store.getters.connectionSettings) {
        return this.$store.getters.connectionSettings.connection_fqdn;
      } else {
        return "";
      }
    },
    connection_ports() {
      if (this.$store.getters.connectionSettings) {
        if (this.$store.getters.connectionSettings.connection_ports) {
          return this.$store.getters.connectionSettings.connection_ports;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    listen_host_fqdn() {
      if (this.$store.getters.connectionSettings) {
        return this.$store.getters.connectionSettings.listen_host_fqdn;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.streams-info {
  /* min-height: 300px; */
  width: 400px;
  @media (max-width: 600px) {
    width: 100%;
    box-sizing: border-box;
  }
  padding: 35px;
  &__text {
    margin-bottom: 5px;
  }
  label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    display: block;
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
