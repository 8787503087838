<template>
  <section class="security-box">
    <tabs-simple v-model="activeTab" :tabs="tabs" />
    <components v-if="activeTab?.component" :is="activeTab.component" />
  </section>
</template>

<script>
import { TabsSimple } from "@/components/tabs";
import { SecurityGeo, SecurityNetwork, SecurityListeners } from "@/components/security-new";

export default {
  name: "page-security",
  components: { TabsSimple, SecurityGeo },
  data() {
    return {
      activeTab: null,
      tabs: [
        {
          id: 0,
          name: "Гео фильтрация",
          component: SecurityGeo,
        },
        {
          id: 1,
          name: "Сетевая фильтрация",
          component: SecurityNetwork,
        },
        {
          id: 2,
          name: "Разрешенные слушатели",
          component: SecurityListeners,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.security-box {
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
}
</style>
