import axios from "axios";
import Cookies from "js-cookie";

const state = {
  streams: null,
  streamsMeta: null,
  stream: null,
  idStream: null,
  streamFilterActive: "mount",
  streamFilterValue: "",
  song_logs: [],
  song_logs_meta: {},
  showInfo: false,
  checkStreamList: [],
  isUpdate: false,
  streamsFilters: {
    mount: "Mount",
    listeners_limit: "Лимит слушателей",
    bitrate: "Битрейт",
    id: "ID",
  },

  select_stream: null,
  select_streams: null,
  select_streams_meta: null,
  select_stream_filter_active: "mount",
  select_stream_filter_value: "",
};

const getters = {
  streams: (s) => s.streams,
  streamsMeta: (s) => s.streamsMeta,
  stream: (s) => s.stream,
  idStream: (s) => s.idStream,
  streamFilterActive: (s) => s.streamFilterActive,
  streamFilterValue: (s) => s.streamFilterValue,
  streamsFilters: (s) => s.streamsFilters,
  showInfo: (s) => s.showInfo,
  checkStreamList: (s) => s.checkStreamList,
  song_logs: (s) => s.song_logs,
  song_logs_meta: (s) => s.song_logs_meta,

  select_stream: (s) => s.select_stream,
  select_streams: (s) => s.select_streams,
  select_streams_meta: (s) => s.select_streams_meta,
  select_stream_filter_active: (s) => s.select_stream_filter_active,
  select_stream_filter_value: (s) => s.select_stream_filter_value,
};

const actions = {
  async streamSongLong({ commit }, id) {
    const response = await axios.patch(`/streams/${id}/song-logs`);
    commit("songLogs", response.data);
    return response.data.data;
  },
  async streamUnblock(_, id) {
    const response = await axios.patch(`/streams/${id}/unblock`);
    return response.data.data;
  },
  async streamsStation({ commit }, id) {
    const response = await axios.get(`/streams?filter[station_id]=${id}`);
    commit("select_streams", response.data.data);
    commit("select_streams_meta", response.data.meta);
    return response;
  },
  async streams({ commit, getters, state }, parameters) {
    let page = 1;
    let per_page = Cookies.get("perPage") || "25";
    let organization_id = getters.userSelectOrganization?.id || "";

    if (parameters?.page) {
      page = parameters.page;
    }

    const response = await axios.get(
      `/streams?page=${page}&per_page=${per_page}&filter[organization_id]=${organization_id}&filter[${state.streamFilterActive}]=${state.streamFilterValue}`
    );
    commit("streams", response.data.data);
    commit("streamsMeta", response.data.meta);
    state.isUpdate = false;
    return response;
  },
  async streamStatus(_, id) {
    const response = await axios.get(`/streams/${id}/status`);
    return response?.data;
  },
  async streamsSearch({ commit, state, getters }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    const organization_id = getters.userSelectOrganization?.id || "";
    state.streamFilterValue = value;
    const response = await axios.get(
      `/streams?filter[${state.streamFilterActive}]=${value}&filter[organization_id]=${organization_id}&per_page=${per_page}`
    );
    commit("streams", response.data.data);
    commit("streamsMeta", response.data.meta);
    return response.data.data;
  },
  async select_streams({ commit, getters, state }, parameters) {
    let page = 1;
    let concat = false;
    let filters = "";
    let per_page = Cookies.get("perPage") || "25";
    let organization_id = getters.userSelectOrganization?.id || "";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.filters) {
      filters = parameters.filters;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }

    const response = await axios.get(
      `/streams?page=${page}&per_page=${per_page}&filter[organization_id]=${organization_id}&filter[${
        state.streamFilterActive
      }]=${state.streamFilterValue}${filters ? "&" + filters : ""}`
    );
    commit(concat ? "select_streams_next" : "select_streams", response.data.data);
    commit("select_streams_meta", response.data.meta);
    return response;
  },
  async select_streams_search({ commit, state, getters }, value) {
    let per_page = Cookies.get("perPage") || "25";
    const organization_id = getters.userSelectOrganization?.id || "";
    state.select_stream_filter_value = value;
    const response = await axios.get(
      `/streams?filter[${state.select_stream_filter_active}]=${value}&filter[organization_id]=${organization_id}&per_page=${per_page}`
    );
    commit("select_streams", response.data.data);
    commit("select_streams_meta", response.data.meta);
    return response.data.data;
  },
  async select_stream({ commit }, id) {
    const response = await axios.get(`/streams/${id}`);
    commit("select_stream", response.data.data);
    return response.data.data;
  },
  async stream({ commit }, id) {
    const response = await axios.get(`/streams/${id}`);
    commit("stream", response.data.data);
    return response.data.data;
  },
  async addStream({ state }, data) {
    const response = await axios.post("/streams", data);
    state.idStream = response.data.data.id;
    state.idOrganization = response.data.data.station.organization_id;
    return response.data.data;
  },
  async editStream(_, { id, data }) {
    const response = await axios.patch(`/streams/${id}`, data);
    return response.data.data;
  },
  async delStream(_, id) {
    const response = await axios.delete(`/streams/${id}`);
    return response.data.data;
  },
};

const mutations = {
  addStream(state, data) {
    if (data.status === "publishing") {
      state.checkStreamList.push(data.id);
    }
    state.streams = [data].concat(state.streams);
  },
  failedCheckId(state, id) {
    state.checkStreamList = state.checkStreamList.filter((_id) => _id !== id);
  },
  updateStream(state, data) {
    state.checkStreamList.push(data.id);
    let temp = state.streams.map((stream) => {
      if (data.id === stream.id) {
        return data;
      }
      return stream;
    });
    state.streams = temp;
  },
  streamRemoveCheckId(state, data) {
    state.checkStreamList = state.checkStreamList.filter((_id) => _id !== data.id);
    switch (data.status) {
      case "deleted":
        state.streams = state.streams.filter((stream) => stream.id !== data.id);
        break;
      default:
        state.streams = state.streams.map((stream) => {
          if (stream.id == data.id) {
            stream.status = data.status;
            return stream;
          } else {
            return stream;
          }
        });
    }
  },
  streamFilterActive(state, filter) {
    state.streamFilterActive = filter;
  },
  streams(state, data) {
    const statuses = ["publishing", "updating", "deleting", "failed"];
    data.map((value) => {
      if (value.status && statuses.includes(value.status)) {
        state.checkStreamList.push(value.id);
      }
    });
    state.streams = data;
  },
  streamsMeta(state, data) {
    state.streamsMeta = data;
  },
  select_streams(state, data) {
    state.select_streams = data;
  },
  select_streams_meta(state, data) {
    state.select_streams_meta = data;
  },
  select_streams_next(state, data) {
    state.select_streams = state.select_streams.concat(data);
  },
  select_stream(state, data) {
    state.select_stream = data;
  },
  stream(state, data) {
    state.stream = data;
  },
  idStream(state, data) {
    state.idStream = data;
  },
  showInfo(state, data) {
    state.showInfo = data;
  },
  songLogs(state, data) {
    state.song_logs = data.data;
    state.song_logs_meta = data.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
