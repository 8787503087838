<template>
  <div>
    <div class="x-flex-end flex header-table">
      <vs-button
        v-if="
          $access.members.create.includes(access_level) ||
          $role.members.create.includes(member_role)
        "
        flat
        :active="true"
        icon
        @click="addMember"
        style="min-width: max-content; margin-left: 20px"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить сотрудника</span>
      </vs-button>
    </div>
    <Table :columns="columns" :rows="data" :headerRadius="false" :isLoading="loadingData">
      <template v-slot:table-row="{ props }">
        <div v-if="props.column.field === 'actions' && props.row.member_role !== 4">
          <span @click="edit(props.row)" class="editing" style="margin-right: 20px"
            >Редактировать</span
          >
        </div>
        <span v-else-if="props.column.field === 'member_role'">{{
          roles[props.row.member_role] || "Не назначена"
        }}</span>
        <span v-else-if="props.column.field === 'access_level'">{{
          access[props.row.access_level] || "Не назначен"
        }}</span>
        <span v-else-if="props.column.field === 'created_at'">{{
          props.row.created_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <span v-else-if="props.column.field === 'updated_at'">{{
          props.row.updated_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
      </template>
    </Table>
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" :data="row" :organizationId="parseInt($route.params.id)" @back="back" />
    </vs-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import MembersTableColumns from "@/data/Members/MembersTableColumns.json";
import MemberEdit from "@/components/Organizations/MemberEdit";
import MemberAdd from "@/components/Organizations/MemberAdd";

export default {
  name: "Members",
  components: {
    Table,
    MemberEdit,
    MemberAdd,
  },
  data() {
    return {
      view: MemberAdd,
      // action: false,
      columns: MembersTableColumns,
      loadingData: true,
      row: {},
      roles: {
        1: "Просмотр",
        2: "Управление",
        3: "Администрирование",
        4: "Владелец",
      },
      access: {
        1: "Пользователь",
        50: "Агент поддержки (1)",
        70: "Агент поддержки (2)",
        90: "Администратор",
        100: "Разработчик",
      },
    };
  },
  beforeCreate() {
    this.$store.commit("members", []);
    this.$store.dispatch("members", this.$route.params.id).finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
  },
  computed: {
    action: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit('showModal', value);
      }
    },
    access_organizations() {
      return this.$store.getters.userAccessOrganizations;
    },
    member_role() {
      return this.$store.getters.userMemberRole;
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    data() {
      if (this.$store.getters.members) {
        return this.$store.getters.members;
      }
      return [];
    },
  },
  methods: {
    memberRoleInOrganization(id) {
      const temp = this.access_organizations.filter((organization) => organization.id === id);
      if (temp.length) {
        return temp[0].member_role;
      } else {
        return 0;
      }
    },
    back(update = false) {
      if (update) {
        this.$store.dispatch("members", this.$route.params.id);
      }
      this.action = false;
    },
    addMember() {
      this.view = MemberAdd;
      this.action = true;
    },
    edit(row) {
      this.row = row;
      this.view = MemberEdit;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 10px 20px;
  border-radius: 16px 16px 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform-origin: 0 50%;
  transform-origin: 0 0;

  transform: translateY(-20px);
  opacity: 0;
}
.not-access {
  color: #909ba4;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 400px;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.3em;
  width: 90%;
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
