import axios from "axios";
import Cookies from "js-cookie";

const state = {
  clusters: null,
  clustersMeta: null,
  cluster: null,
  idCluster: null,
  clusterFilterActive: "name",
  clusterFilterValue: "",
  clustersFilters: {
    name: "Название",
    type: "Тип",
    id: "ID",
  },
};

const getters = {
  clusters: (s) => s.clusters,
  clustersMeta: (s) => s.clustersMeta,
  cluster: (s) => s.cluster,
  idCluster: (s) => s.idCluster,
  clustersFilters: (s) => s.clustersFilters,
  clusterFilterActive: (s) => s.clusterFilterActive,
  clusterFilterValue: (s) => s.clusterFilterValue,
};

const actions = {
  async clusters({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }

    const response = await axios.get(
      `/clusters?page=${page}&per_page=${per_page}&filter[${state.clusterFilterActive}]=${state.clusterFilterValue}`
    );
    commit(concat ? "clustersNext" : "clusters", response.data.data);
    commit("clustersMeta", response.data.meta);
    return response.data.data;
  },
  async clustersSearch({ commit, state }, { value, parameters }) {
    let per_page = Cookies.get("perPage") || "25";
    let notStore = false;
    let additionFilter = "";

    state.clusterFilterValue = value;

    if (parameters?.notStore) {
      notStore = parameters.notStore;
    }

    if (parameters?.additionFilter) {
      additionFilter = parameters.additionFilter;
    }

    const response = await axios.get(
      `/clusters?filter[${state.clusterFilterActive}]=${value}&per_page=${per_page}${additionFilter}`
    );

    if (!notStore) {
      commit("clusters", response.data.data);
      commit("clustersMeta", response.data.meta);
    }
    return response.data.data;
  },
  async clusters1000({ commit }) {
    const response = await axios.get("/clusters?per_page=1000");
    commit("clusters", response.data.data);
    commit("clustersMeta", response.data.meta);
    return response;
  },
  async cluster({ commit }, id) {
    const response = await axios.get(`/clusters/${id}`);
    commit("cluster", response.data.data);
    return response.data.data;
  },
  async addCluster(_, data) {
    const response = await axios.post("/clusters", data);
    return response;
  },
  async editCluster(_, { id, data }) {
    const response = await axios.patch(`/clusters/${id}`, data);
    return response;
  },
  async delCluster(_, id) {
    const response = await axios.delete(`/clusters/${id}`);
    return response;
  },
};

const mutations = {
  clusterFilterActive(state, filter) {
    state.clusterFilterActive = filter;
  },
  clusters(state, data) {
    state.clusters = data;
  },
  clustersNext(state, data) {
    state.clusters = state.clusters.concat(data);
  },
  clustersMeta(state, data) {
    state.clustersMeta = data;
  },
  cluster(state, data) {
    state.cluster = data;
  },
  idCluster(state, id) {
    state.idCluster = id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
