const state = {
  showModal: false,
};

const getters = {
  showModal: (s) => s.showModal,
};

const mutations = {
  showModal(state, data) {
    state.showModal = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
