<template>
  <div class="edit-station">
    <div class="station-header">Редактирование веб-сайта</div>
    <div v-if="!websiteLoading && !loader">
      <help-field :help="'Выберите организацию'">
        <BaseSelect
          :state="isDanger('organization_id')"
          required
          :label="'Организация'"
          v-model="organization"
          :options="organizations"
          :field="'id'"
          :title="'name'"
          :filter="true"
          :method="'organizations'"
          :search="'organizationsSearch'"
          :loading="organizationsLoading"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите шаблон отображения'">
        <BaseSelect
          :state="isDanger('template')"
          required
          :label="'Шаблон отображения'"
          v-model="template"
          :options="templates"
          :field="'field'"
          :title="'label'"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите станции'">
        <BaseSelect
          :state="isDanger('station_ids')"
          required
          :label="'Выберите станции'"
          v-model="station_check"
          :options="stations"
          :field="'id'"
          :title="'name'"
          :loading="stationsLoading"
          multiselect
        />
      </help-field>
      <!-- <a href="" @click.prevent="getHelp" style="font-size: 12px; margin-left: 12px">Нет домена?</a> -->
      <div class="h20 w20"></div>
      <div
        v-if="$access.websites.update.includes(accessLevel) || organization?.blocked === false"
        class="flex x-flex-end mt20"
      >
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="mr20">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="editStation" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
        <Alert :show="showModal" :message="message" @close="alert" />
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Notification/Alert.vue";
import BaseSelect from "@/components/base/Select";
import HelpField from "@/components/base/HelpField";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "StationsEdit",
  components: {
    HelpField,
    Alert,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      id: null,
      loader: false,
      websiteLoading: true,
      domain: "",
      organization: {},
      organizationsLoading: true,
      oldData: {},
      newData: {},
      showModal: false,
      message: "",
      stationsLoading: true,
      station_check: [],
      count: 0,
      template: {
        field: "player",
        label: "Плеер (зелёный)",
      },
      templatesValue: {
        player: "Плеер (зелёный)",
      },
      templates: [
        {
          field: "player",
          label: "Плеер (зелёный)",
        },
      ],
    };
  },
  created() {
    this.count += 1;
    this.organizationsLoading = true;
    this.stationsLoading = true;
    this.$store.dispatch("website", this.$store.getters.idWebsite).then(() => {
      this.id = this.$store.getters.website.id;
      this.organization = this.$store.getters.website.organization;
      this.template = this.templatesValue[this.$store.getters.website.template]
        ? {
            field: this.$store.getters.website.template,
            label: this.templatesValue[this.$store.getters.website.template],
          }
        : this.templates[0];

      this.oldData = {
        organization: this.organization,
        template: this.template,
      };
      this.$store
        .dispatch("stations", {
          organization_id: this.organization.id,
        })
        .then(() => {
          if (this.$store.getters.stations?.length) {
            this.station_check = this.$store.getters.website.stations;
            this.oldData["station_ids"] = this.station_check.map((station) => station.id);
          }
        })
        .finally(() => {
          this.count = 0;
          this.stationsLoading = false;
        });

      this.$store.dispatch("organizations").finally(() => {
        this.organizationsLoading = false;
        this.websiteLoading = false;
      });
    });
  },
  watch: {
    organization(organization) {
      this.$store.dispatch("validations", null);
      if (!this.count) {
        this.stationsLoading = true;
        this.station_check = [];
        this.$store
          .dispatch("stations", {
            organization_id: organization.id,
          })
          .then(() => {
            if (this.$store.getters.stations?.length) {
              this.station_check.push(this.$store.getters.stations[0]);
            }
          })
          .finally(() => {
            this.stationsLoading = false;
          });
      }
    },
  },
  computed: {
    stations() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
    accessLevel() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    organizations() {
      if (this.$store.getters.userAccessLevel === 1) {
        return [this.$store.getters.userSelectOrganization];
      } else if (
        this.$store.getters.userAccessLevel > 1 &&
        this.$store.getters.organizations?.length
      ) {
        return this.$store.getters.organizations;
      } else {
        return [];
      }
    },
  },
  methods: {
    // getHelp() {
    //   this.$store.dispatch("notification", "notification");
    //   this.$store.dispatch("notificationTitle", "Нет домена?");
    //   this.$store.dispatch("notificationStyle", "max-width: 450px; min-height: 200px;");
    //   this.$store.dispatch(
    //     "notificationMessage",
    //     `<p>Вы можете приобрести домен в нашей <a href="https://bill.dline-media.com" target="__blank">биллинг-системе</a>.</p>
    //        <br>
    //        <p>Домен в зоне .RU можно приобрести на 1 год за 299 рублей.</p>
    //        <br>
    //        <p>Если у Вас возникают сложности с настройкой или Вы переживаете, что ничего не получится - напишите в нашу службу поддержки через чат, поможем всё настроить.</p>`
    //   );
    //   this.$store.dispatch("notificationButtons", [
    //     {
    //       text: "Купить домен",
    //       active: true,
    //       method: () => {
    //         window.open("https://bill.dline-media.com", "_blank");
    //       },
    //     },
    //     {
    //       text: "Написать в поддержку",
    //       active: false,
    //       method: () => {
    //         const buttonChat = document.querySelector(
    //           ".b24-widget-button-inner-item.b24-widget-button-icon-animation"
    //         );
    //         buttonChat.click();
    //       },
    //     },
    //   ]);
    // },
    del() {
      this.message = "Вы действительно хотите удалить данный веб-сайт?";
      this.showModal = true;
    },
    editStation() {
      this.$store.commit("validations", null);
      // this.newData = {
      //   organization: this.organization,
      //   domain: this.domain,
      // };
      this.newData = {
        organization: this.organization,
        template: this.template,
        station_ids: this.station_check.map((station) => station.id),
      };

      const temp = {};
      Object.keys(this.newData).forEach((key) => {
        if (key === "station_ids") {
          if (
            JSON.stringify(this.oldData.station_ids) !== JSON.stringify(this.newData.station_ids)
          ) {
            temp[key] = this.newData["station_ids"];
          }
        } else if (this.oldData[key] + "" !== this.newData[key] + "") {
          temp[key] = this.newData[key];
        }
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editWebsite", {
            id: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили веб-сайт");
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    alert(bool) {
      if (bool) {
        this.$store
          .dispatch("delWebsite", this.id)
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили веб-сайт");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось удалить веб-сайт");
              this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            } else {
              // Что-то произошло при настройке запроса, вызвавшего ошибку
              console.log("Error", e.message);
            }
          });
      }
      this.showModal = false;
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-station {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.station-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .width {
    width: calc(20% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
    .width {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .edit-station {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .station-button-box {
    flex-direction: column;
  }
  .station-button-box button {
    margin: 0;
    margin-bottom: 20px;
    margin-right: 0 !important;
    width: 100%;
  }
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .width {
      width: 100%;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
