<template>
  <div class="added-stream">
    <div class="streams-header">Добавление потока</div>
    <div v-if="!stationsLoading && !loading">
      <div class="two-column">
        <div class="column-1">
          <help-field :help="'Выберите станцию'">
            <BaseSelect
              required
              :label="'Станция'"
              v-model="station"
              :options="stations"
              :field="'id'"
              :title="'name'"
              :filter="true"
              :method="'stations'"
              :search="'stationsSearch'"
              :loading="stationsLoading"
            />
          </help-field>
          <div class="h20"></div>
          <help-field
            :help="'Выберите режим:<br>1. <strong>Обычный</strong> - для вещания со своего компьютера<br>2. <strong>Ретранслятор</strong> - для копирования потока с другого сервера'"
          >
            <BaseSelect
              required
              :state="isDanger('mode')"
              :label="'Режим'"
              v-model="mode"
              :options="modeOptions"
            />
          </help-field>
          <div class="h20"></div>
          <help-field
            :help="'Укажите название потока на латинице без символа «/». Это название будет использовано в ссылке для прослушивания.'"
          >
            <BaseInput
              :state="isDanger('mount')"
              label-placeholder="Mount"
              required
              v-model="mount"
            />
          </help-field>
          <div class="h20"></div>
          <div v-if="mode.field === 'live'">
            <help-field :help="'Задайте пароль для подключения с программы источника'">
              <BaseInput
                type="password"
                :state="isDanger('password')"
                label-placeholder="Пароль"
                required
                v-model="password"
                :autocomplete="'new-password'"
                icon-after
                :visiblePassword="hasVisiblePassword"
                @click-icon="hasVisiblePassword = !hasVisiblePassword"
              >
                <template #icon>
                  <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
                  <i v-else class="bx bx-hide"></i>
                </template>
              </BaseInput>
              <i @click="genPassword" class="generate-password bx bx-dice-5"></i>
            </help-field>
            <div class="h20"></div>
          </div>
          <help-field :help="'Лимит слушателей'">
            <BaseInput
              :state="isDanger('listeners_limit')"
              label-placeholder="Лимит слушателей"
              v-model="listeners_limit"
            />
          </help-field>
          <div v-if="mode.field === 'relay'">
            <div class="h20"></div>
            <help-field :help="'Порт источника'">
              <BaseInput
                :state="isDanger('relay_port')"
                label-placeholder="Порт источника"
                v-model="relay_port"
              />
            </help-field>
            <div class="h20"></div>
            <help-field :help="'Укажите название потока на латинице без символа «/».'">
              <BaseInput
                required
                :state="isDanger('relay_mount')"
                label-placeholder="Mount источника"
                v-model="relay_mount"
              />
            </help-field>
          </div>
        </div>
        <div class="column-2">
          <help-field :help="'Выберите битрейт в kb/s'">
            <BaseSelect
              :state="isDanger('bitrate')"
              :label="'Битрейт'"
              v-model="bitrate"
              :options="bitrateOptions"
            />
          </help-field>
          <div class="h20"></div>
          <help-field v-if="['encoder'].includes(mode.field)" :help="'Выберите выходной формат'">
            <BaseSelect
              required
              :state="isDanger('bitrate')"
              :label="'Выходной формат'"
              v-model="outputFormat"
              :options="outputFormats"
            />
          </help-field>
          <div v-if="['encoder'].includes(mode.field)" class="h20"></div>
          <help-field v-if="['encoder'].includes(mode.field)" help="Выберите источник сигнала">
            <BaseSelect
              v-model="source_stream"
              required
              :label="'Источник сигнала'"
              :options="sourceStreams"
              :field="'id'"
              :title="'mount'"
              :filter="true"
              :method="'select_streams'"
              :search="'select_streams_search'"
              :loading="streamEncoderLoading"
            />
          </help-field>
          <div v-if="['encoder'].includes(mode.field)" class="h20"></div>
          <help-field
            v-if="['live', 'relay'].includes(mode.field)"
            help="Поток, на который будут переправлены слушатели в случае отключения редактируемого потока"
          >
            <BaseSelect
              :label="'Резервный поток'"
              v-model="fallback_stream"
              :options="streams"
              :field="'id'"
              :title="'mount'"
              :filter="true"
              :method="'select_streams'"
              :search="'select_streams_search'"
            />
          </help-field>
          <div v-if="['live', 'relay'].includes(mode.field)" class="h20"></div>
          <help-field
            v-if="['live', 'relay'].includes(mode.field) && fallback_stream.id !== 0"
            help="Возврат слушателей в случае возобновления вещания редактируемого потока"
            style="margin-bottom: 24px"
          >
            <div class="fallback-stream">
              <div class="fallback-stream__label">Возврат с резерва</div>
              <vs-switch
                class="mobile"
                v-model="fallback_override"
                style="margin-left: -10px"
              ></vs-switch>
            </div>
          </help-field>
          <div class="h20" v-if="mode.field === 'live'"></div>
          <div v-if="mode.field === 'relay'">
            <help-field :help="'Протокол источника'">
              <BaseSelect
                required
                :state="isDanger('relay_protocol')"
                :label="'Протокол источника'"
                v-model="relay_protocol"
                :options="protocolOptions"
              />
            </help-field>
            <div class="h20"></div>
            <help-field :help="'Сервер источника'">
              <BaseInput
                required
                :state="isDanger('relay_host')"
                label-placeholder="Сервер источника"
                v-model="relay_host"
              />
            </help-field>
          </div>
        </div>
      </div>
      <div class="h20"></div>
      <div
        v-if="
          $access.streams.update.includes(accessLevel) ||
          (Object.keys(station).length && station.status !== 'blocked')
        "
        class="flex x-flex-end mt20"
      >
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addStream" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Добавить</span>
        </vs-button>
      </div>
      <div
        v-else-if="
          !$access.streams.update.includes(accessLevel) &&
          Object.keys(station).length &&
          station?.status === 'blocked'
        "
        class="station--blocked"
      >
        Ваша организация заблокирована, поэтому это действие совершить нельзя. Проверьте срок
        действия услуги в
        <a href="https://bill.dline-media.com" target="__blank">биллинг-системе</a>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import HelpField from "@/components/base/HelpField";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "StreamsAdd",
  components: {
    HelpField,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      hasVisiblePassword: false,
      timerId: 0,
      station: {},
      fallback_override: true,
      fallback_stream: {},
      source_stream: {},
      stationsLoading: false,
      listeners_limit: "",
      loading: false,
      mode: {
        field: "live",
        label: "Обычный",
      },
      modeOptions: [
        {
          field: "live",
          label: "Обычный",
        },
        {
          field: "relay",
          label: "Ретранслятор",
        },
        {
          field: "encoder",
          label: "Перекодировщик",
        },
      ],
      mount: "",
      password: "",
      outputFormat: {
        label: "MP3",
        field: "mp3",
      },
      outputFormats: [
        {
          label: "MP3",
          field: "mp3",
        },
        {
          label: "AAC+",
          field: "aac+",
        },
      ],
      bitrate: {
        label: 128,
        field: 128,
      },
      bitrateOptions: [
        {
          label: 32,
          field: 32,
        },
        {
          label: 64,
          field: 64,
        },
        {
          label: 128,
          field: 128,
        },
        {
          label: 192,
          field: 192,
        },
        {
          label: 256,
          field: 256,
        },
        {
          label: 320,
          field: 320,
        },
      ],
      relay_protocol: {
        field: "https",
        label: "https",
      },
      protocolOptions: [
        {
          field: "https",
          label: "https",
        },
        {
          field: "http",
          label: "http",
        },
      ],
      relay_host: "",
      relay_port: "443",
      relay_mount: "",
      status: {
        field: "published",
        label: "published",
      },
      statusOptions: [
        {
          field: "published",
          label: "published",
        },
        {
          field: "unpublished",
          label: "unpublished",
        },
      ],
      showModal: false,
      message: "",
      streamEncoderLoading: false,
    };
  },
  created() {
    const bitrateLimit = this.$store.getters.userSelectOrganization?.stream_bitrate_limit || null;
    if (bitrateLimit) {
      this.bitrate = {
        label: bitrateLimit,
        field: bitrateLimit,
      };

      this.bitrateOptions = this.bitrateOptions.map((bitrate) => {
        if (bitrate.field > bitrateLimit) {
          bitrate["blocked"] = true;
          bitrate["message"] = " (Недоступно на Вашем тарифном плане)";
        }
        return bitrate;
      });
    }

    this.stationsLoading = true;
    this.$emit("close", false);
    this.$store.dispatch("stations").finally(() => {
      if (this.stations.length) {
        this.station = this.stations[0];
      }

      this.$store.dispatch("select_streams").finally(() => {
        this.fallback_stream = this.streams[0];
        this.$emit("close", true);
        this.stationsLoading = false;
      });
    });
  },
  watch: {
    relay_protocol(value) {
      if (value.field === "https") {
        this.relay_port = "443";
      } else if (value.field === "http") {
        this.relay_port = "80";
      }
    },
    mode(mode) {
      if (this.station?.id && mode.field === "encoder") {
        this.streamEncoderLoading = true;
        this.$store
          .dispatch("select_streams", {
            filters: `filter[mode]=live,relay${
              this.station?.id ? "&filter[station_id]=" + this.station.id : ""
            }`,
          })
          .finally(() => {
            if (this.sourceStreams?.length) {
              this.source_stream = this.sourceStreams[0];
            }
            this.$emit("close", true);
            this.streamEncoderLoading = false;
          });
      }
    },
    station(station) {
      if (station?.id && this.mode.field === "encoder") {
        this.streamEncoderLoading = true;
        this.$store
          .dispatch("select_streams", {
            filters: `filter[mode]=live,relay${
              station?.id ? "&filter[station_id]=" + station.id : ""
            }`,
          })
          .finally(() => {
            if (this.sourceStreams?.length) {
              this.source_stream = this.sourceStreams[0];
            }
            this.$emit("close", true);
            this.streamEncoderLoading = false;
          });
      }
    },
  },
  computed: {
    accessLevel() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    sourceStreams() {
      if (this.$store.getters.select_streams?.length) {
        return this.$store.getters.select_streams;
      } else {
        return [];
      }
    },
    streams() {
      const default_response = [
        {
          id: 0,
          mount: "Не выбран",
        },
      ];

      if (this.$store.getters.select_streams?.length) {
        const temp = default_response.concat(this.$store.getters.select_streams);
        return temp;
      } else {
        return default_response;
      }
    },
    stations() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
  },
  methods: {
    copyBuffer(text) {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    generatePassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";

      for (let i = 0, n = charset.length; i < 15; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      return password;
    },
    genPassword() {
      const password = this.generatePassword();
      this.copyBuffer(password);
      this.password = password;
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    async addStream() {
      this.$emit("close", false);
      if (this.loading) {
        return;
      }
      this.$store.commit("validations", null);
      const data = {
        station_id: this.station.id,
        mode: this.mode.field,
        listeners_limit: this.listeners_limit,
        mount: this.mount,
        bitrate: this.bitrate.field,
        status: this.status.field,
      };
      if (data.mode === "live") {
        data["password"] = this.password;
      }

      if (data.mode === "encoder") {
        data["source_stream_id"] = this.source_stream.id;
        data["output_format"] = this.outputFormat.field;
      }

      if (data.mode === "relay") {
        data["relay_protocol"] = this.relay_protocol.field;
        data["relay_host"] = this.relay_host;
        data["relay_port"] = this.relay_port;
        data["relay_mount"] = this.relay_mount;
      }
      if (this.fallback_stream.id !== 0) {
        data["fallback_override"] = this.fallback_override;
        data["fallback_stream_id"] = this.fallback_stream.id;
      }

      this.loading = true;
      this.$store
        .dispatch("addStream", data)
        .then((response) => {
          this.$store.commit("addStream", response);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно создали поток");
          this.back(true);
          this.$emit("close", true);
        })
        .catch(() => {
          this.loading = false;
          this.$emit("close", true);
        });
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
  },
};
</script>

<style lang="scss" scoped>
.added-stream {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  max-width: 540px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.streams-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.generate-password {
  position: absolute;
  top: 12px;
  right: 40px;
  transition: all 0.4s ease;
  &:hover {
    cursor: pointer;
    transform: rotate(35deg);
  }
  &:active {
    transform: scale(0.9);
  }
}
.two-column {
  display: flex;
}
.column-1 {
  width: 250px;
  margin-right: 35px;
}
.column-2 {
  width: 250px;
}
.loading {
  width: 100%;
  height: 390px;
  z-index: 9999999;
  top: 80px;
  left: 0;
  background: #fff;
}

.station--blocked {
  color: #666;
}

@media (max-width: 600px) {
  .added-stream {
    max-height: 100%;
  }
  .two-column {
    flex-direction: column;
  }
  .column-1 {
    width: 100%;
    margin-right: 0;
  }
  .column-2 {
    margin-top: 20px;
    width: 100%;
  }
  .loading {
    width: 200px;
    height: 200px;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.fallback-stream {
  display: flex;
  width: 100%;
  height: 38px;
  align-items: center;
  &__label {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
  }
}
</style>
