import axios from "axios";

const state = {
  analytics: [],
  analytics_meta: {},
};

const getters = {
  analytics: (s) => s.analytics,
  analytics_meta: (s) => s.analytics_meta,
};

const actions = {
  async analytics({ commit }, parameters) {
    let entity = "stations";
    let id = "";
    let date_from = "";
    let date_to = "";

    if (parameters?.entity) {
      entity = parameters.entity;
    }
    if (parameters?.id) {
      id = parameters.id;
    }
    if (parameters?.date_from) {
      date_from = parameters.date_from;
    }
    if (parameters?.date_to) {
      date_to = parameters.date_to;
    }

    const response = await axios.get(`/${entity}/${id}/analytics?date_from=${date_from}&date_to=${date_to}`);
    commit("analytics", response.data.data);
    commit("analytics_meta", response.data.meta);
    return response.data.data;
  },
};

const mutations = {
  analytics(state, data) {
    state.analytics = data;
  },
  analytics_meta(state, data) {
    state.analytics_meta = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
