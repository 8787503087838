<template>
  <div class="server-notification">
    <label>Произошла ошибка</label>
    <div v-if="!loading">
      <div>Во время обновления конфигурации сервера произошла ошибка.</div>
      <div>
        Код операции - <strong>{{ $store.getters.server_batch_id || "не указано" }}</strong>
      </div>
      <div v-if="$access.jobs.read.includes(access_level)" class="server-notification__action">
        <vs-button transparent @click="viewLogs">Посмотреть логи</vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/base/Loader";

export default {
  name: "Info",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
  },
  methods: {
    viewLogs() {
      if (this.$store.getters.server_batch_id) {
        this.$store.commit("jobFilterActive", "batch_id");
        this.$store.dispatch("jobsSearch", { value: this.$store.getters.server_batch_id });
        this.$router.push("/jobs");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.server-notification {
  width: 350px;
  padding: 35px;
  &__text {
    margin-bottom: 5px;
  }
  &__action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    display: block;
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
