<template>
  <div>
    <div class="x-flex-end flex header-table">
      <BaseSearch v-model="search" :filters="filters" />
      <vs-button
        v-if="
          $role.streams.create.includes(member_role) ||
          $access.websites.create.includes(access_level)
        "
        flat
        :active="true"
        icon
        @click="create"
        style="min-width: max-content; margin-left: 20px"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить веб-сайт</span>
      </vs-button>
    </div>
    <WebsitesView @back="back" :pending="loadingData" @block="block" @remove="remove" />
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" @back="back" @block="block" />
    </vs-dialog>
    <Alert :show="showModal" :isLoading="isLoading" :message="message" @close="alert" />
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import BaseSearch from "@/components/base/Search";
import Alert from "@/components/Notification/Alert.vue";
import WebsitesView from "@/components/Websites/View.vue";
import WebsitesEdit from "@/components/Websites/Edit.vue";
import WebsitesAdd from "@/components/Websites/Add.vue";

export default {
  name: "Websites",
  components: {
    Alert,
    WebsitesView,
    WebsitesAdd,
    BaseSearch,
  },
  data() {
    return {
      isLoading: false,
      view: WebsitesEdit,
      id: null,
      loadingData: false,
      message: "",
      action: false,
      showModal: false,
      search: "",
    };
  },
  computed: {
    filters() {
      return this.$store.getters.websitesFilters;
    },
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      }
      return 0;
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
  },
  watch: {
    search(value) {
      this.loadingData = true;
      this.$store.dispatch("websitesSearch", { value }).finally(() => {
        this.loadingData = false;
      });
    },
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
  },
  methods: {
    block() {
      this.view = WebsitesEdit;
      this.action = true;
    },
    back(update = false) {
      if (update) {
        this.$store.dispatch("websites");
      }
      this.action = false;
    },
    alert(bool) {
      if (bool) {
        this.isLoading = true;
        this.$store
          .dispatch("delWebsite", this.id)
          .then(() => {
            this.loadingData = true;
            this.$store.dispatch("websites").finally(() => {
              this.loadingData = false;
            });
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили веб-сайт.");
          })
          .finally(() => {
            this.isLoading = false;
            this.showModal = false;
          });
      } else {
        this.showModal = false;
      }
    },
    remove(id) {
      this.id = id;
      this.message = "Вы действительно хотите удалить данный веб-сайт?";
      this.showModal = true;
    },
    create() {
      this.view = WebsitesAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
