var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__header",class:[_vm.headerRadius ? 'table--radius' : '']}),_c('div',{staticClass:"table__content"},[(!_vm.isLoading && _vm.rows.length)?_c('table',{key:"view"},[(_vm.rows.length)?_c('thead',[_c('tr',_vm._l((_vm.columns),function(th,i){return _c('th',{key:i,style:(th.style),attrs:{"data":th,"sort":"sort"},domProps:{"innerHTML":_vm._s(th.label)}})}),0)]):_vm._e(),_c('tbody',[_vm._l((_vm.rows),function(tr,i){return [_c('tr',{key:tr + '-' + i,class:[
              _vm.expandable ? 'table__row' : '',
              tr.blocked ? 'in-progress' : '',
              tr.status === 'blocked' ? 'in-progress' : '',
              tr.status ? (_vm.progressStatus.includes(tr.status) ? 'in-progress' : '') : '',
            ],on:{"click":function($event){return _vm.rowClick(tr, i)}}},_vm._l((_vm.columns),function(item,index){return _c('td',{key:index,style:(item.style),attrs:{"data-field":item.field}},[_c('div',{staticClass:"table__label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"table__value"},[_vm._t("table-row",function(){return [_vm._v(" "+_vm._s(tr[item.field] || item.default)+" ")]},{"props":{
                    column: {
                      field: item.field,
                      label: item.label,
                    },
                    row: tr,
                  }})],2)])}),0),(_vm.expandable)?_c('tr',{key:i + 'expandable',class:i === _vm.activeRow ? 'table__row-expandable--active' : 'table__row-expandable'},[_c('td',{attrs:{"colspan":Object.keys(_vm.columns).length}},[_vm._t("expandable",null,{"props":{
                  column: _vm.columns,
                  row: tr,
                }})],2)]):_vm._e()]})],2)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"table__loading"},[_c('loader')],1):(!_vm.isLoading && !Object.keys(_vm.rows).length)?_c('div',{staticClass:"table__not-found"},[_vm._t("not-data",function(){return [_c('div',{staticStyle:{"font-size":"14px","margin":"70px 0","opacity":"0.5"}},[_vm._v("Нет данных")])]})],2):_vm._e()]),_c('div',{staticClass:"table__footer"},[(!_vm.isLoading && _vm.meta && _vm.meta.total > _vm.meta.per_page)?_c('BasePagination',{attrs:{"name":_vm.name,"pages":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }