<template>
  <div class="edit-server">
    <div class="server-header">Редактирование сервера</div>
    <div v-if="!clustersLoading && !loader">
      <div class="two-column">
        <div class="column-1">
          <help-field :help="'Выберите кластер'">
            <BaseSelect
              :label="'Кластер'"
              required
              v-model="cluster"
              :options="clusters"
              :field="'id'"
              :title="'name'"
              :filter="true"
              :method="'clusters'"
              :search="'clustersSearch'"
              :loading="clustersLoading"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field
            :help="'Выберите роль: <br>1. <strong>Одиночный</strong> - принимает потоки и отдает их<br>2. <strong>Мастер</strong> - только принимает потоки<br>3. <strong>Релей</strong> - раздает потоки<br>4. <strong>Балансировщик</strong> - распределяет запросы между серверами<br>5. <strong>Перекодировщик</strong> - перекодировка в другие форматы'"
          >
            <BaseSelect :label="'Роль'" required v-model="role" :options="roleOptions" readonly />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Введите IP'">
            <BaseInput :state="isDanger('ip')" label-placeholder="IP" required v-model="ip" />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Введите доменное имя'">
            <BaseInput :state="isDanger('fqdn')" label-placeholder="FQDN" required v-model="fqdn" />
          </help-field>
          <div v-if="role.field === 'recorder'" class="h20 w20"></div>
          <help-field v-if="role.field === 'recorder'" :help="'Введите кол-во места на диске'">
            <BaseInput
              :state="isDanger('disk_space')"
              label-placeholder="Место на диске (GB)"
              required
              v-model="disk_space"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Максимальное количество слушателей'"
          >
            <BaseInput
              :state="isDanger('max_listeners')"
              label-placeholder="Мак. кол-во слушателей"
              required
              v-model="max_listeners"
            />
          </help-field>
          <div
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            class="h20 w20"
          ></div>
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Лимит сетевого порта в kb/s'"
          >
            <BaseInput
              :state="isDanger('max_bandwidth')"
              label-placeholder="Лимит сетевого порта"
              required
              v-model="max_bandwidth"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Публичный IP-адрес'">
            <BaseInput
              :state="isDanger('public_ip')"
              label-placeholder="Публичный IP-адрес"
              required
              v-model="public_ip"
            />
          </help-field>
        </div>
        <div class="column-2">
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Максимальное количество CPU'"
          >
            <BaseInput
              :state="isDanger('max_workers')"
              label-placeholder="Кол-во CPU"
              required
              v-model="max_workers"
            />
          </help-field>
          <div
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            class="h20 w20"
          ></div>
          <help-field
            :help="'У сервера на тех. обслуживании не обновляется конфигурация и не направляются слушатели'"
          >
            <BaseSelect :label="'Статус'" required v-model="status" :options="statusOptions" />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'IP-адрес для подключения по SSH'">
            <BaseInput
              :state="isDanger('connection_data.ip')"
              label-placeholder="IP-адрес подключения"
              required
              v-model="connection_data.ip"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Порт подключения по SSH'">
            <BaseInput
              :state="isDanger('connection_data.port')"
              label-placeholder="Порт подключения"
              required
              v-model="connection_data.port"
            />
          </help-field>
          <div class="h20 w20"></div>

          <help-field
            :help="'Выберите тип подключения по SSH:<br>1. <strong>По паролю</strong> от пользователя root<br>2. <strong>По ключу</strong> (публичный ключ уточнить у DevOps)'"
          >
            <BaseSelect
              :label="'Тип подключения'"
              required
              v-model="connection_data.type"
              :options="typeOptions"
            />
          </help-field>
          <div v-if="connection_data.type.field === 'password'">
            <div class="h20 w20"></div>
            <help-field :help="'Пароль подключения по SSH'">
              <BaseInput
                type="password"
                v-model="connection_data.password"
                :state="isDanger('connection_data.password')"
                label-placeholder="Пароль подключения"
                required
                :visiblePassword="hasVisiblePassword"
                @click-icon="hasVisiblePassword = !hasVisiblePassword"
              >
                <template #icon>
                  <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
                  <i v-else class="bx bx-hide"></i>
                </template>
              </BaseInput>
              <i @click="genPassword" class="generate-password bx bx-dice-5"></i>
            </help-field>
          </div>
        </div>
      </div>
      <div
        v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
        class="h20 w20"
      ></div>
      <BindingsOptions
        v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
        v-model="binding_options"
      />
      <div class="flex x-flex-end mt20 server-actions">
        <vs-button @click="back" class="server-button" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="server-button">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="serverRegenerateConfig" class="server-button" flat="flat" :active="false"
          >Перегенирировать конфиг</vs-button
        >
        <vs-button class="server-button" @click="editServer" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import Alert from "@/components/Notification/Alert.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import BindingsOptions from "@/components/BindingsOptions";
import Loader from "@/components/base/Loader";

export default {
  name: "ServersEdit",
  components: {
    Alert,
    BaseSelect,
    BaseInput,
    HelpField,
    BindingsOptions,
    Loader,
  },
  data() {
    return {
      binding_options: [],
      disk_space: 1,
      loader: false,
      hasVisiblePassword: false,
      clustersLoading: false,
      public_ip: "",
      ip: "",
      fqdn: "",
      cluster: {},
      role: {
        field: "standalone",
        label: "Одиночный",
      },
      max_listeners: "",
      max_bandwidth: "",
      max_workers: "",
      provision: "static",
      status: {
        field: "operational",
        label: "В работе",
      },
      typeOptions: [
        {
          field: "password",
          label: "По паролю",
        },
        {
          field: "key",
          label: "По ключу",
        },
      ],
      statusOptions: [
        {
          field: "operational",
          label: "В работе",
        },
        {
          field: "maintenance",
          label: "Тех. обслуживание",
        },
      ],
      connection_data: {
        ip: "",
        port: "",
        type: {
          field: "",
          label: "",
        },
        password: "",
      },
      showModal: false,
      message: "",
      oldData: null,
      newData: null,
    };
  },
  created() {
    this.clustersLoading = true;

    this.$store.dispatch("server", this.$store.getters.idServer).then(() => {
      this.cluster = this.$store.getters.server.cluster;
      this.public_ip = this.$store.getters.server.public_ip;
      this.ip = this.$store.getters.server.ip;
      this.fqdn = this.$store.getters.server.fqdn;
      this.binding_options = this.$store.getters.server.binding_options;
      this.disk_space = this.$store.getters.server.disk_space || 1;
      this.role = {
        field: this.$store.getters.server.role,
        label: this.convertRole(this.$store.getters.server.role),
      };
      this.max_listeners = this.$store.getters.server.max_listeners;
      this.max_bandwidth = this.$store.getters.server.max_bandwidth;
      this.max_workers = this.$store.getters.server.max_workers;
      this.provision = this.$store.getters.server.provision;
      this.status = {
        field: this.$store.getters.server.status,
        label: this.convertStatus(this.$store.getters.server.status),
      };
      this.connection_data = {
        ip: this.$store.getters.server.connection_data.ip,
        port: this.$store.getters.server.connection_data.port,
        type: {
          field: this.$store.getters.server.connection_data.type,
          label: this.convertType(this.$store.getters.server.connection_data.type),
        },
        password: this.$store.getters.server.connection_data.password,
      };

      var clone = require("clone");

      // if (this.connection_data.type.field === "password") {
      //   this.connection_data["password"] = this.$store.getters.server.connection_data.password;
      // }

      this.oldData = {
        public_ip: this.public_ip,
        ip: this.ip,
        fqdn: this.fqdn,
        role: this.role.field,
        max_listeners: this.max_listeners,
        max_bandwidth: this.max_bandwidth,
        max_workers: this.max_workers,
        provision: this.provision,
        status: this.status.field,
        cluster_id: this.cluster.id,
        disk_space: this.disk_space ? parseInt(this.disk_space) : null,
        connection_data: {
          ip: this.connection_data.ip,
          port: this.connection_data.port,
          type: this.connection_data.type.field,
          // password: this.connection_data.password,
        },
        binding_options: clone(this.binding_options),
      };

      if (this.oldData.connection_data.type === "password") {
        this.oldData.connection_data["password"] = this.connection_data.password;
      }
      this.$store.dispatch("clusters").finally(() => {
        this.clustersLoading = false;
      });
    });
  },
  computed: {
    roleOptions() {
      if (this.cluster?.type === "ha") {
        return [
          {
            field: "standalone",
            label: "Одиночный",
          },
          {
            field: "master",
            label: "Мастер",
          },
          {
            field: "relay",
            label: "Релей",
          },
          {
            field: "load-balancer",
            label: "Балансировщик",
          },
          {
            field: "encoder",
            label: "Перекодировщик",
          },
          {
            field: "recorder",
            label: "Рекордер",
          },
        ];
      } else {
        return [
          {
            field: "standalone",
            label: "Одиночный",
          },
          {
            field: "master",
            label: "Мастер",
          },
          {
            field: "relay",
            label: "Релей",
          },
        ];
      }
    },
    clusters() {
      if (this.$store.getters.clusters?.length) {
        return this.$store.getters.clusters;
      } else {
        return [];
      }
    },
  },
  methods: {
    serverRegenerateConfig() {
      const id = this.$store.getters.idServer;
      this.loader = true;
      this.$store
        .dispatch("serverRegenerateConfig", id)
        .then(() => {
          this.back(true);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно перегенерировали конфиг сервера");
        })
        .catch(() => {
          this.$store.dispatch("notification", "failure");
          this.$store.dispatch("notificationMessage", "Не удалось перегенерировать конфиг сервера");
        })
        .finally(() => {
          this.loader = false;
        });
    },
    convertRole(role) {
      switch (role) {
        case "master":
          return "Мастер";
        case "relay":
          return "Релей";
        case "standalone":
          return "Одиночный";
        case "encoder":
          return "Перекодировщик";
        case "load-balancer":
          return "Балансировщик";
        case "recorder":
          return "Рекордер";
        default:
          return role;
      }
    },
    convertStatus(status) {
      switch (status) {
        case "operational":
          return "В работе";
        case "maintenance":
          return "Тех. обслуживание";
        default:
          return status;
      }
    },
    convertType(type) {
      switch (type) {
        case "password":
          return "По паролю";
        case "key":
          return "По ключу";
        default:
          return type;
      }
    },
    copyBuffer(text) {
      clearTimeout(this.timerId);
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.text = "Пароль скопирован";
      this.timerId = setTimeout(() => {
        this.isCopied = false;
        this.text = "Сгенерировать пароль";
      }, 1000);
    },
    generatePassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";

      for (let i = 0, n = charset.length; i < 15; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      return password;
    },
    genPassword() {
      const password = this.generatePassword();
      this.copyBuffer(password);
      this.connection_data.password = password;
    },
    alert(bool) {
      if (bool) {
        this.$store.dispatch("delServer", this.$store.getters.idServer).then(() => {
          this.back(true);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно удалили сервер");
        });
      }
      this.showModal = false;
    },
    del() {
      this.message = "Вы действительно хотите удалить данный сервер?";
      this.showModal = true;
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    convertStringToBool(value) {
      if (value === "true") return true;
      return false;
    },
    async editServer() {
      this.errorsField = [];

      this.newData = {
        public_ip: this.public_ip,
        ip: this.ip,
        fqdn: this.fqdn,
        role: this.role.field,
        max_listeners: this.max_listeners,
        max_bandwidth: this.max_bandwidth,
        max_workers: this.max_workers,
        provision: this.provision,
        status: this.status.field,
        disk_space: this.disk_space ? parseInt(this.disk_space) : null,
        connection_data: {
          ip: this.connection_data.ip,
          port: this.connection_data.port,
          type: this.connection_data.type.field,
          // password: this.connection_data.password,
        },
        binding_options: this.binding_options,
        cluster_id: this.cluster.id,
      };
      if (this.newData.connection_data.type === "password") {
        this.newData.connection_data["password"] = this.connection_data.password;
      }

      const temp = {};
      Object.keys(this.oldData).map((key) => {
        if (key === "connection_data") {
          Object.keys(this.oldData[key]).map((keyj) => {
            if ("" + this.oldData[key][keyj] !== "" + this.newData[key][keyj]) {
              if (this.newData.connection_data.type === "key" && keyj === "password") {
              } else {
                temp[key] = this.newData[key];
              }
            }
          });
        } else if (key === "binding_options") {
          if (this.oldData[key].length !== this.newData[key].length) {
            temp[key] = this.newData[key];
          } else {
            this.oldData[key].map((option, index) => {
              if ("" + option.address !== "" + this.newData[key][index].address) {
                temp[key] = this.newData[key];
              }
              if ("" + option.port !== "" + this.newData[key][index].port) {
                temp[key] = this.newData[key];
              }
              if ("" + option.ssl !== "" + this.newData[key][index].ssl) {
                temp[key] = this.newData[key];
              }
            });
          }
        } else if ("" + this.oldData[key] !== "" + this.newData[key]) {
          if (this.newData["mode"] === "relay" && key === "password") {
          } else {
            temp[key] = this.newData[key];
          }
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editServer", {
            id: this.$store.getters.idServer,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили сервер");
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-server {
  padding: 35px;
  overflow: auto;
  max-height: 630px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-height: 600px) {
    max-height: 100%;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.server-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.generate-password {
  position: absolute;
  top: 12px;
  right: 40px;
  transition: all 0.4s ease;
  &:hover {
    cursor: pointer;
    transform: rotate(35deg);
  }
  &:active {
    transform: scale(0.9);
  }
}
.two-column {
  display: flex;
}
.column-1 {
  width: 250px;
  margin-right: 35px;
}
.column-2 {
  width: 250px;
}
@media (max-width: 600px) {
  .edit-server {
    max-height: 100%;
  }
  .two-column {
    flex-direction: column;
  }
  .column-1 {
    width: 100%;
    margin-right: 0;
  }
  .column-2 {
    margin-top: 20px;
    width: 100%;
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
.server-actions {
  @media (max-width: 585px) {
    flex-direction: column-reverse;
  }
}
.server-button {
  margin: 0 10px;
  @media (max-width: 585px) {
    margin: 10px 0 0 0;
  }
}
</style>
