<template>
  <div class="base-input" :class="state ? 'base-input--' + state : 'base-input--default'">
    <input
      :value="isValue ? value : ''"
      :name="autocomplete"
      @input="change"
      :type="visiblePassword ? 'text' : type"
      :id="id"
      class="base-input__value"
      :autocomplete="autocomplete"
    />
    <label
      :for="id"
      class="base-input__label"
      :class="isValue || label ? 'base-input__label--active' : ''"
      v-html="required ? (labelPlaceholder || label) + requiredHTML : labelPlaceholder || label"
    ></label>
    <span v-if="hasIconSlot" class="base-input__icon" @click="icon">
      <slot name="icon"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    autocomplete: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    state: {
      type: String,
      default: () => "default",
    },
    labelPlaceholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    visiblePassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
      requiredHTML: "<span style='margin-left: 5px; color: red; font-size: 15px;'>*</span>",
    };
  },
  methods: {
    icon() {
      this.$emit("click-icon");
    },
    change(event) {
      this.$emit("input", event.target.value);
    },
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
    isValue() {
      return (
        // this.value + "" !== "null" && this.value + "" !== "undefined" && this.value + "" !== ""
        this.value && this.value + "" !== ""
      );
    },
  },
  mounted() {
    this.id = "uuid-" + this._uid;
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  width: 100%;
  font-size: 16px;
  display: flex;
  position: relative;
  &--default {
    .base-input {
      &__value {
        background: rgba(244, 247, 248, 1);
        color: rgba(44, 62, 80, 1);
      }
      &__label {
      }
      &__icon {
      }
    }
  }
  &--danger {
    .base-input {
      &__value {
        background: rgba(255, 71, 87, 0.1) !important;
        color: rgba(255, 71, 87, 1);
      }
      &__label {
        color: rgba(255, 71, 87, 1);
      }
      &__icon {
        color: rgba(255, 71, 87, 1);
        background: rgba(255, 71, 87, 0.1);
        &:hover {
          background: rgba(255, 71, 87, 0.2);
        }
      }
    }
  }
  &__required {
    margin-left: 5px;
    color: red;
  }
  &__value {
    font-size: inherit;
    width: 100%;
    padding-left: 7px;
    padding-right: 38px;
    border: 2px solid transparent;
    /* background: rgba(244, 247, 248, 1); */
    /* color: rgba(44, 62, 80, 1); */
    padding: 7px 0px 7px 13px;
    border-radius: 10px;
    transition: all 0.25s ease;
    &:focus {
      & ~ .base-input__label {
        top: -25px;
        font-size: 0.75rem;
        opacity: 1;
      }
    }
  }
  &__label {
    font-size: 0.8rem;
    cursor: text;
    opacity: 0.4;
    transition: all 0.25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &--active {
      top: -25px;
      font-size: 0.75rem;
      opacity: 1;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.25s ease;
    background: #f4f7f8;
    &:hover {
      cursor: pointer;
      top: -5px;
      right: -5px;
      background: #fafeff;
    }
  }
}

input {
  &:-webkit-autofill {
    outline: none;
    -webkit-text-fill-color: rgba(var(--vs-text), 1) !important;
    -webkit-box-shadow: 0 0 0px 1000px rgba(var(--vs-gray-2), 1) inset;
    border: 1px solid rgba(var(--vs-gray-2), 1);
  }
}
.base-input--danger {
  .base-input {
    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(var(--vs-danger), 1) !important;
      -webkit-box-shadow: 0 0 0px 1000px #fdecee inset;
      border: 1px solid #fdecee;
    }
  }
}
</style>
