<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'analytics'"
    :headerRadius="false"
    :isLoading="loadingData"
  >
    <template v-slot:table-row="{ props }">
      <span v-if="props.column.field === 'from'">{{
        convertDate(new Date(props.row.from), true)
      }}</span>
      <span v-else-if="props.column.field === 'to'">{{
        convertDate(new Date(props.row.to), true)
      }}</span>
      <span v-else-if="props.column.field === 'max_listeners'">{{
        props.row.max_listeners ? props.row.max_listeners : "-"
      }}</span>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import AnalyticsTableColumns from "@/data/Analytics/AnalyticsTableColumns.json";

export default {
  name: "analytics_table",
  components: {
    Table,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: AnalyticsTableColumns,
    };
  },
  computed: {
    data() {
      if (this.$store.getters.analytics) {
        return this.$store.getters.analytics;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.analytics_meta) {
        return this.$store.getters.analytics_meta;
      }
      return {};
    },
  },
  methods: {
    convertDate(date, view = false) {
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const year = date.getFullYear();
      if (view) {
        const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      } else {
        return `${day}-${month}-${year}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
