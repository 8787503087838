<template>
  <div class="edit-cluster">
    <div class="cluster-header">Редактирование кластера</div>
    <div v-if="!clustersLoading && !loader">
      <help-field :help="'Придумайте название для Вашего кластера'">
        <BaseInput :state="isDanger('name')" label-placeholder="Название" required v-model="name" />
      </help-field>
      <div class="h20 w20"></div>
      <help-field
        :help="'Выберите тип кластера:<br>1. <strong>standalone</strong> - 1 сервер<br>2. <strong>ha</strong> - высокодоступный кластер с несколькими серверами'"
      >
        <BaseSelect v-model="type" :label="'Тип кластера'" :options="typeOptions" />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Сервер прослушивания и просмотра статистики'">
        <BaseInput
          :state="isDanger('listen_host_fqdn')"
          label-placeholder="Сервер статистики"
          required
          v-model="listen_host_fqdn"
        />
      </help-field>
      <div class="h20 w20"></div>
      <div class="flex x-flex-end mt20 flex-wrap cluster-btn-box">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="mr20">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button flat="flat" :active="true" icon="icon" @click="editCluster">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import Alert from "@/components/Notification/Alert.vue";
import AsNumber from "@/components/Fields/AsNumber.vue";
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import Loader from "@/components/base/Loader";

export default {
  name: "ClustersEdit",
  components: {
    Alert,
    BaseSelect,
    BaseInput,
    AsNumber,
    HelpField,
    Loader,
  },
  data() {
    return {
      loader: false,
      id: null,
      name: "",
      listen_host_fqdn: "",
      clustersLoading: true,
      type: {
        field: "standalone",
        label: "Standalone",
      },
      typeOptions: [
        {
          field: "standalone",
          label: "Standalone",
        },
        {
          field: "ha",
          label: "High-Availability",
        },
      ],
      showModal: false,
      message: "",
      oldData: null,
      newData: null,
    };
  },
  created() {
    this.$store
      .dispatch("cluster", this.$store.getters.idCluster)
      .then(() => {
        this.id = this.$store.getters.cluster.id;
        this.name = this.$store.getters.cluster.name;
        this.listen_host_fqdn = this.$store.getters.cluster.listen_host_fqdn;
        this.type = {
          field: this.$store.getters.cluster.type,
          label: this.convertType(this.$store.getters.cluster.type),
        };

        this.oldData = {
          name: this.name,
          type: this.type.field,
          listen_host_fqdn: this.listen_host_fqdn,
        };
      })
      .finally(() => {
        this.clustersLoading = false;
      });
  },
  methods: {
    convertType(type) {
      switch (type) {
        case "standalone":
          return "Standalone";
        case "ha":
          return "High-Availability";
        default:
          return type;
      }
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    alert(bool) {
      if (bool) {
        this.$store
          .dispatch("delCluster", this.id)
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили кластер");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            } else {
              // Что-то произошло при настройке запроса, вызвавшего ошибку
              console.log("Error", e.message);
            }
          });
      }
      this.showModal = false;
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    del() {
      this.message = "Вы действительно хотите удалить данный кластер?";
      this.showModal = true;
    },
    convertToGB(value) {
      return (parseInt(value, 10) * 1024).toString();
    },
    editCluster() {
      this.errorsField = [];

      this.newData = {
        name: this.name,
        type: this.type.field,
        listen_host_fqdn: this.listen_host_fqdn,
      };

      const temp = {};
      Object.keys(this.oldData).map((key) => {
        if ("" + this.oldData[key] !== "" + this.newData[key]) {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editCluster", {
            id: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили кластер");
          }).finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-cluster {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.cluster-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.box-form {
  .vs-input-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .edit-cluser {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
  .cluster-btn-box {
    .vs-button {
      margin: 0;
      margin-bottom: 20px;
      margin-right: 0 !important;
      width: 100%;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
