<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'streams'"
    :expandable="false"
    :isLoading="loadingData"
    :headerRadius="false"
    @pagination="pagination"
    @row-click="rowClick"
  >
    <template v-slot:table-row="{ props }">
      <div v-if="props.column.field === 'actions'">
        <span
          v-if="isActionUpdate(props.row.status)"
          @click="edit(props.row)"
          class="editing"
          style="margin-right: 10px"
          >Редактировать</span
        >
        <span
          v-if="props.row.status === 'blocked' && $access.streams.unblock.includes(access_level)"
          @click="unblock(props.row)"
          class="editing"
          style="margin-right: 10px"
          >Разблокировать</span
        >
        <span v-if="isActionUpdate(props.row.status)" @click="info(props.row)" class="editing"
          >Информация</span
        >
      </div>
      <span v-else-if="props.column.field === 'station'">{{
        props.row.station?.name || "Не указана"
      }}</span>
      <span v-else-if="props.column.field === 'organization'">{{
        props.row.organization?.name || "Не указана"
      }}</span>
      <span v-else-if="props.column.field === 'mount'">/{{ props.row.mount }}</span>
      <span v-else-if="props.column.field === 'mode'">{{ convertMode(props.row.mode) }}</span>
      <span v-else-if="props.column.field === 'status'">{{
        convertStatus(props.row.id, props.row.status)
      }}</span>
      <div
        v-else-if="props.column.field === 'mounted_at'"
        v-html="convertMountedAt(props.row.mounted_at)"
      ></div>
      <span v-else-if="props.column.field === 'traffic_limit'">{{
        props.row.traffic_limit || "Без лимита"
      }}</span>
      <span v-else-if="props.column.field === 'listeners_limit'">{{
        props.row.listeners_limit
          ? props.row.listeners_count + " / " + props.row.listeners_limit
          : props.row.listeners_count + " / &#8734;"
      }}</span>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import StreamsTableColumns from "@/data/Streams/StreamsTableColumns.json";

export default {
  components: {
    Table,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: StreamsTableColumns,
      progress: ["updating", "updated", "deleting", "publishing"],
      page: 1,
    };
  },
  created() {
    if (
      this.$store.getters.userAccessLevel >= 50 &&
      !(this.columns.findIndex((column) => column.field === "station") + 1)
    ) {
      this.columns.splice(2, 0, {
        label: "Станция",
        field: "station",
      });
      this.columns.splice(3, 0, {
        label: "Организация",
        field: "organization",
      });
    }
  },
  watch: {
    "$store.getters.userSelectOrganization": function () {
      this.$store.dispatch("streams");
    },
  },
  computed: {
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      }
      return 0;
    },
    access_level() {
      return this.$store.getters.userAccessLevel;
    },
    data() {
      if (this.$store.getters.streams) {
        return this.$store.getters.streams;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.streamsMeta) {
        return this.$store.getters.streamsMeta;
      }
      return {};
    },
  },
  methods: {
    unblock(row) {
      this.$store.dispatch("streamUnblock", row.id).then(() => {
        this.$store.dispatch("streams", {
          page: this.page,
        });
      });
    },
    pagination(page) {
      this.page = page;
      this.$emit("pagination", page);
    },
    isActionUpdate(status) {
      if (status === "blocked") {
        if (this.$access.organizations.update.includes(this.access_level)) {
          return true;
        }
        return false;
      } else {
        return (
          (!this.progress.includes(status) &&
            this.$access.streams.update.includes(this.access_level)) ||
          (!this.progress.includes(status) && this.$role.streams.update.includes(this.member_role))
        );
      }
    },
    rowClick(row) {
      if (row.status === "failed") {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationTitle", "Произошла ошибка?");
        this.$store.dispatch(
          "notificationMessage",
          "<span>Сообщите нашим администраторам в чате, мы во всём разберемся. Чтобы быстрее решить вопрос передайте в службу поддержки уникальный код ошибки - <strong>" +
            row.batch_id +
            "</strong></span>"
        );
      }
    },
    convertMode(mode) {
      switch (mode) {
        case "live":
          return "Обычный";
        case "relay":
          return "Ретранслятор";
        case "encoder":
          return "Перекодировщик";
        default:
          return mode;
      }
    },
    convertStatus(id, status) {
      switch (status) {
        case "published":
          return "Опубликован";
        case "unpublished":
          return "Скрыт";
        case "blocked":
          return "Заблокирован";
        case "publishing":
          return "Создаётся";
        case "updating":
          return "Обновление";
        case "updated":
          return "Обновлен";
        case "deleting":
          return "Удаление";
        case "deleted":
          return "Удалён";
        case "failed":
          return "Произошла ошибка";
        default:
          return status;
      }
    },
    convertMountedAt(mounted_at) {
      if (mounted_at) {
        return '<span class="indicator--green"></span>';
      } else {
        return '<span class="indicator--red"></span>';
      }
    },
    edit(row) {
      this.$store.commit("idStream", row.id);
      this.$emit("block", "editing");
    },
    info(row) {
      if (row.station) {
        this.$store.commit("idOrganization", row.station.organization_id);
        this.$store.commit("idStream", row.id);
        this.$store.commit("showInfo", true);
        this.$emit("block", "info");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 10px 20px;
  border-radius: 16px 16px 0 0;
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
