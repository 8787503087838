<template>
  <div class="tooltip" :dataLabel="label">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ComponentTooltip",
  props: {
    label: {
      type: String,
      default: "Tooltip",
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  &:hover {
    &:before {
      content: "";
      padding: 3px;
      background: #6c7784;
      position: absolute;
      bottom: calc(100% + 2px);
      left: 50%;
      z-index: 1000;
      transform: rotate(45deg);
    }
    &:after {
      content: attr(dataLabel);
      padding: 3px 6px;
      background: #6c7784;
      color: white;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      border-radius: 5px;
      font-size: 12px;
    }
  }
}
</style>
