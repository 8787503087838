import Vue from "vue";
import VueRouter from "vue-router";
import Clusters from "@/views/Clusters.vue";
import Servers from "@/views/Servers.vue";
import Stations from "@/views/Stations.vue";
import Streams from "@/views/Streams.vue";
import Analytics from "@/views/Analytics.vue";
import Violations from "@/views/Violations.vue";
import Instructions from "@/views/Instructions.vue";
import Organizations from "@/views/Organizations.vue";
import Jobs from "@/views/Jobs.vue";
import Websites from "@/views/Websites.vue";
import Security from "@/views/Security.vue";
import SecurityNew from "@/views/SecurityNew.vue";
import Members from "@/views/Members.vue";
import Users from "@/views/Users.vue";
import PlaylistHistory from "@/views/PlaylistHistory.vue";
import Cookies from "js-cookie";
import store from "../store";

Vue.use(VueRouter);

const isOrganizations = (to) => {
  const includes = ["stations", "streams"];

  if (includes.includes(to.name)) {
    return true;
  } else if ("playlist_history" === to.name) {
    let member_role = 1;
    if (store.getters.userAccessOrganizations?.length) {
      store.getters.userAccessOrganizations.forEach((organization) => {
        if (organization.member_role > member_role) {
          member_role = organization.member_role;
        }
      });
    }

    const future_playlist_history =
      process.env.VUE_APP_FEATURE_PLAYLIST_HISTORY === "true" ? true : false;

    if (
      future_playlist_history &&
      Vue.prototype.$role.playlist_history.read.includes(member_role)
    ) {
      return true;
    } else {
      return false;
    }
  } else if ("analytics" === to.name) {
    let member_role = 1;
    if (store.getters.userAccessOrganizations?.length) {
      store.getters.userAccessOrganizations.forEach((organization) => {
        if (organization.member_role > member_role) {
          member_role = organization.member_role;
        }
      });
    }
    const future_analytics = process.env.VUE_APP_FEATURE_ANALYTICS === "true" ? true : false;

    if (future_analytics && Vue.prototype.$role.analytics.read.includes(member_role)) {
      return true;
    } else {
      return false;
    }
  } else if ("members" === to.name) {
    const temp = store.getters.userAccessOrganizations.filter(
      (organization) => "" + organization.id === to.params.id
    );

    if (temp.length) {
      if (Vue.prototype.$role.organizations.update.includes(temp[0].member_role)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else if ("websites" === to.name) {
    let member_role = 1;
    if (store.getters.userAccessOrganizations?.length) {
      store.getters.userAccessOrganizations.forEach((organization) => {
        if (organization.member_role > member_role) {
          member_role = organization.member_role;
        }
      });
    }
    const futureWebsites = process.env.VUE_APP_FEATURE_WEBSITES === "true" ? true : false;

    if (futureWebsites && Vue.prototype.$role.websites.read.includes(member_role)) {
      return true;
    } else {
      return false;
    }
  } else if (["security", "security-update"].includes(to.name)) {
    let member_role = 1;
    if (store.getters.userAccessOrganizations?.length) {
      store.getters.userAccessOrganizations.forEach((organization) => {
        if (organization.member_role > member_role) {
          member_role = organization.member_role;
        }
      });
    }

    if (Vue.prototype.$role.security.read.includes(member_role)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const checkAccessLevel = (to) => {
  return Vue.prototype.$access[to.name].read.includes(store.getters.userAccessLevel);
};
const checkRoleLevel = (to) => {
  return Vue.prototype.$role[to.name].read.includes(store.getters.userMemberRole);
};

const ifNotAuth = (to, from, next) => {
  if (!Cookies.get("token")) {
    return next("/auth?page=login");
  }
  if (!store.getters.userEmail) {
    store.dispatch("getUser").finally(() => {
      if (checkAccessLevel(to) || (checkRoleLevel(to) && isOrganizations(to))) {
        return next();
      } else {
        return next("/organizations");
      }
    });
  } else {
    if (checkAccessLevel(to) || (checkRoleLevel(to) && isOrganizations(to))) {
      return next();
    } else {
      return next("/organizations");
    }
  }
};

const ifAuth = (to, from, next) => {
  if (Cookies.get("token")) {
    next("/organizations");
  } else {
    next();
  }
};

const routes = [
  {
    path: "*",
    redirect: "/organizations",
  },
  {
    path: "/organizations",
    name: "organizations",
    meta: {
      title: "Организации",
    },
    component: Organizations,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/security",
    name: "security",
    meta: {
      title: "Безопасность",
    },
    component: SecurityNew,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/websites",
    name: "websites",
    meta: {
      title: "Веб-сайты",
    },
    component: Websites,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/playlist-history",
    name: "playlist_history",
    meta: {
      title: "История эфира",
    },
    component: PlaylistHistory,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/jobs",
    name: "jobs",
    meta: {
      title: "Очередь задач",
    },
    component: Jobs,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/organizations/:id/members",
    name: "members",
    meta: {
      title: "Сотрудники организации",
    },
    component: Members,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/clusters",
    name: "clusters",
    meta: {
      title: "Кластеры",
    },
    component: Clusters,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/servers",
    name: "servers",
    meta: {
      title: "Серверы",
    },
    component: Servers,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/stations",
    name: "stations",
    meta: {
      title: "Станции",
    },
    component: Stations,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/streams",
    name: "streams",
    meta: {
      title: "Потоки",
    },
    component: Streams,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/station/:stationId/security",
    name: "security-update",
    meta: {
      title: "Безопасность станции",
    },
    component: Security,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Пользователи",
    },
    component: Users,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/analytics",
    name: "analytics",
    meta: {
      title: "Аналитика",
    },
    component: Analytics,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/violations",
    name: "violations",
    meta: {
      title: "Нарушения",
    },
    component: Violations,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/instructions",
    name: "instructions",
    meta: {
      title: "Инструкции",
    },
    component: Instructions,
    beforeEnter: ifNotAuth,
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      title: "Авторизация",
    },
    component: () => import("../views/Auth.vue"),
    beforeEnter: ifAuth,
  },
  {
    path: "/reset-password",
    name: "reset",
    meta: {
      title: "Сброс пароля",
    },
    beforeEnter: (to, _, next) => {
      Cookies.set("temp-email", to.query.email);
      Cookies.set("temp-token", to.query.token);
      next("/auth?page=password-reset");
    },
  },
  {
    path: "/auth-with-code",
    name: "AuthWithCode",
    meta: {
      title: "Авторизация",
    },
    beforeEnter: (to, _, next) => {
      if (to.query.code) {
        store
          .dispatch("login_with_code", to.query.code)
          .then(() => {
            return next("/");
          })
          .catch(() => {
            return next("/");
          });
      } else {
        return next("/");
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
