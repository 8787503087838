<template>
  <input
    class="input-network"
    placeholder="0.0.0.0/0"
    title="Укажите IP-адрес или подсеть в формате 0.0.0.0/0"
    ref="input"
    :class="danger ? 'input-network--danger' : ''"
    @input="input"
    @keydown="keydown"
    @click="focus"
    @blur="blur"
  />
</template>

<script>
export default {
  props: {
    name: "input-network",
    value: {
      type: String,
    },
    danger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastKeyPressList: [],
      placeholderChar: "_",
      mask: "_._._._/_",
      isIndexIncrement: true,
      indexIp: 0,
      ip: [null, null, null, null, null],
    };
  },
  watch: {
    value(value) {
      if (value === "") {
        this.$refs.input.value = "";
        this.indexIp = 0;
        this.ip = [null, null, null, null, null];
      }
    },
  },
  methods: {
    input(e) {
      e.preventDefault();
    },
    backspace(mask, selectionRange) {
      const input = e.target;
      if (this.ip[this.indexIp].slice(0, -1) === "") {
        setTimeout(() => {
          input.value = input.value + mask;
          input.setSelectionRange(selectionRange, selectionRange, "none");
          this.indexIp -= 1;
        }, 0);
      } else {
        this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
      }
    },
    keydown(e) {
      const input = e.target;
      const index = input.value.indexOf(this.placeholderChar);
      if ([91, 93].includes(e.keyCode)) {
        e.preventDefault();
      } else if (e.keyCode === 8 && this.ip[this.indexIp] !== null) {
        this.isIndexIncrement = true;
        if (this.indexIp === 4) {
          if (this.ip[this.indexIp].slice(0, -1) === "") {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
            setTimeout(() => {
              input.value = input.value + "_";
              input.setSelectionRange(input.value.length - 2, input.value.length - 2, "none");
              this.indexIp -= 1;
            }, 0);
          } else {
            // this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
          }
        }

        if (this.indexIp === 3) {
          if (this.ip[this.indexIp].slice(0, -1) === "") {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
            setTimeout(() => {
              input.value = input.value.slice(0, -3) + "._/_";
              input.setSelectionRange(input.value.length - 4, input.value.length - 4, "none");
              this.indexIp -= 1;
            }, 0);
          } else {
            // this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
          }
        }

        if (this.indexIp === 2) {
          if (this.ip[this.indexIp].slice(0, -1) === "") {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
            setTimeout(() => {
              input.value = input.value.slice(0, -5) + "._._/_";
              input.setSelectionRange(input.value.length - 6, input.value.length - 6, "none");
              this.indexIp -= 1;
            }, 0);
          } else {
            // this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
          }
        }

        if (this.indexIp === 1) {
          if (this.ip[this.indexIp].slice(0, -1) === "") {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
            setTimeout(() => {
              input.value = input.value.slice(0, -7) + "._._._/_";
              input.setSelectionRange(input.value.length - 8, input.value.length - 8, "none");
              this.indexIp -= 1;
            }, 0);
          } else {
            // this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
          }
        }

        if (this.indexIp === 0) {
          if (this.ip[this.indexIp].slice(0, -1) === "") {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
            setTimeout(() => {
              input.value = input.value.slice(0, -9) + "_._._._/_";
              input.setSelectionRange(input.value.length - 9, input.value.length - 9, "none");
            }, 0);
          } else {
            // this.ip[this.indexIp] = this.ip[this.indexIp].slice(0, -1);
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp].slice(0, -1));
          }
        }
      } else if ([null, ""].includes(this.ip[this.indexIp])) {
        this.isIndexIncrement = true;
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        } else {
          if (this.indexIp === this.ip.length - 1) {
            if (/[0-9]/.test(e.key)) {
              input.setSelectionRange(index, index + 1, "none");
              this.ip.splice(this.indexIp, 1, e.key);
            } else {
              e.preventDefault();
            }
          } else {
            input.setSelectionRange(
              // input.value.indexOf(this.placeholderChar),
              // input.value.indexOf(this.placeholderChar) + 1,
              index,
              index + 1,
              "none"
            );
            this.ip.splice(this.indexIp, 1, e.key);
          }
        }
      } else if (/^[0-2]$/.test(this.ip[this.indexIp])) {
        this.isIndexIncrement = true;
        if (this.ip[this.indexIp] === "0") {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          } else {
            if (this.indexIp + 1 <= this.ip.length - 1) {
              input.setSelectionRange(
                // input.value.indexOf(this.placeholderChar),
                // input.value.indexOf(this.placeholderChar) + 1,
                index,
                index + 1,
                "none"
              );
              this.indexIp += 1;
              this.ip.splice(this.indexIp, 1, e.key);
            } else {
              e.preventDefault();
            }
          }
        } else if (this.ip[this.indexIp] === "1") {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          } else {
            // this.ip[this.indexIp] += e.key;
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
          }
        } else if (this.ip[this.indexIp] === "2") {
          if (!/[0-5]/.test(e.key)) {
            e.preventDefault();
          } else {
            // this.ip[this.indexIp] += e.key;
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
          }
        } else {
          e.preventDefault();
        }
      } else if (/^[3-9]$/.test(this.ip[this.indexIp])) {
        this.isIndexIncrement = true;
        if (this.indexIp === this.ip.length - 1) {
          if (this.ip[this.indexIp] > 3) {
            e.preventDefault();
          } else if (/[0-2]/.test(e.key)) {
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
          } else {
            e.preventDefault();
          }
        } else if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        } else {
          // this.ip[this.indexIp] += e.key;
          this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
          if (this.indexIp + 1 <= this.ip.length - 1) {
            this.indexIp += 1;
          }
        }
      } else if (/^[12][0-9]$/.test(this.ip[this.indexIp])) {
        this.isIndexIncrement = true;
        if (this.indexIp === this.ip.length - 1) {
          e.preventDefault();
        } else if (this.ip[this.indexIp] < 19) {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          } else {
            // this.ip[this.indexIp] += e.key;
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
            if (this.indexIp + 1 <= this.ip.length - 1) {
              this.indexIp += 1;
            }
          }
        } else if (this.ip[this.indexIp] < 25) {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          } else {
            // this.ip[this.indexIp] += e.key;
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
            if (this.indexIp + 1 <= this.ip.length - 1) {
              this.indexIp += 1;
            }
          }
        } else if (this.ip[this.indexIp] === "25") {
          if (!/[0-5]/.test(e.key)) {
            e.preventDefault();
          } else {
            // this.ip[this.indexIp] += e.key;
            this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
            if (this.indexIp + 1 <= this.ip.length - 1) {
              this.indexIp += 1;
            }
          }
        } else {
          e.preventDefault();
        }
      } else if (/^([3-9][0-9]|[12][0-9][0-9])$/.test(this.ip[this.indexIp])) {
        this.isIndexIncrement = true;
        if (this.indexIp + 1 <= this.ip.length - 1) {
          this.indexIp += 1;
          this.ip.splice(this.indexIp, 1, this.ip[this.indexIp] + e.key);
          input.setSelectionRange(
            // input.value.indexOf(this.placeholderChar),
            // input.value.indexOf(this.placeholderChar) + 1,
            index,
            index + 1,
            "none"
          );
        } else {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    },
    focus(e) {
      const input = e.target;
      const index = input.value.indexOf(this.placeholderChar);
      if (input.value) {
        if (this.isIndexIncrement && this.indexIp + 1 <= this.ip.length - 1) {
          this.indexIp += 1;
          this.isIndexIncrement = false;
        }
        input.value = input.value;
      } else {
        input.value = this.mask;
      }
      setTimeout(() => {
        if (input.value.indexOf(this.placeholderChar) === 0) {
          input.setSelectionRange(0, 0, "none");
        } else {
          input.setSelectionRange(
            // input.value.indexOf(this.placeholderChar) - 1,
            // input.value.indexOf(this.placeholderChar) - 1,
            index - 1,
            index - 1,
            "none"
          );
        }
        // }
      }, 0);
    },
    blur(e) {
      let isRemove = false;
      const input = e.target;
      for (let i = 0; i < this.ip.length - 1; i++) {
        if ([null, ""].includes(this.ip[i])) {
          isRemove = true;
          break;
        }
      }

      if (isRemove) {
        input.value = "";
        this.ip = [null, null, null, null, null];
        this.indexIp = 0;
        this.$emit("input", "");
      } else if ([null, ""].includes(this.ip[this.ip.length - 1])) {
        this.ip.splice(this.ip.length - 1, 1, "32");
        input.value = input.value.slice(0, -1) + "32";
        this.indexIp = this.ip.length - 1;
        this.$emit("input", input.value);
      } else {
        this.$emit("input", input.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-network {
  max-width: 250px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  outline: none;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  background: none;
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  &--danger {
    border-bottom: 1px solid #ff0000;
    color: #ff0000;
    &:focus {
      border-bottom: 1px solid #ff0000 !important;
    }
  }
  &:focus {
    border-bottom: 1px solid #999999;
  }
}
</style>
