<template>
  <div class="ui-chart__box">
    <div
      class="ui-chart__wrapper"
      style="display: flex"
      :style="
        data?.length && !loadingData
          ? `
    border: 1px solid #e5e5e5;
    border-radius: 5px;
              `
          : ''
      "
      @mousemove="aim"
      @mouseleave="isViewTooltip = false"
    >
      <svg v-show="data.length && !loadingData && !drawing" class="ui-chart" ref="chart"></svg>
      <div
        v-if="isViewTooltip && !loadingData && !scale"
        class="ui-chart__aim-x"
        :style="`left: ${aimX}px;`"
      ></div>
      <div
        v-if="isViewTooltip && !loadingData && !scale"
        class="ui-chart__aim-y"
        :style="`top: ${aimY}px;margin-left: ${offsetX}px; width: calc(100% - ${offsetX}px);`"
      ></div>
      <div
        v-if="isViewTooltip && !loadingData && !scale"
        class="ui-chart__point"
        :style="`top: ${pointY - 3}px; left: ${pointX - 3}px;`"
      ></div>
      <div
        class="ui-chart__tooltip"
        :style="`opacity: ${!loadingData && data.length && !scale && isViewTooltip ? 1 : 0}; top: ${
          aimY + tooltipOffsetY
        }px;left: ${aimX + tooltipOffsetX}px;`"
        ref="tooltip"
      >
        <div style="font-size: 14px; opacity: 0.5; margin-bottom: 5px">
          {{ data[aimI] ? convertDate(data[aimI]["from"]) : "???" }}
        </div>
        <div>
          Слушателей:
          <strong>{{ data[aimI] ? data[aimI]["max_listeners"] : "???" }}</strong>
        </div>
      </div>
    </div>
    <div v-if="loadingData || drawing" class="ui-chart__loading">
      <Loader />
    </div>
    <div v-if="!data.length && !loadingData && !drawing" class="ui-chart__not-data">Нет данных</div>
  </div>
</template>

<script>
import Loader from "@/components/base/Loader";

export default {
  name: "chart",
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      pending: false,
      drawing: false,
      scale: false,
      timerId: 0,
      tooltipOffsetY: 20,
      tooltipOffsetX: 20,
      isViewTooltip: false,
      aimX: 0,
      aimY: 0,
      aimI: 0,
      pointX: 0,
      pointY: 0,
      offsetX: 0,
      maxValue: 0,
      distance: 0,
      diffDays: 1,
      chart: null,
    };
  },
  mounted() {
    if (!this.pending && this.$store.getters.analytics?.length) {
      this.pending = true;
      this.update();
      this.pending = false;
    }
    window.addEventListener("resize", this.update);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.update);
  },
  watch: {
    "$store.getters.analytics"() {
      if (!this.pending && this.$store.getters.analytics?.length) {
        this.pending = true;
        this.update();
        this.pending = false;
      }
    },
  },
  computed: {
    data() {
      if (this.$store.getters.analytics?.length) {
        return this.$store.getters.analytics;
      } else {
        return [];
      }
    },
  },
  methods: {
    init() {
      const chart = this.$refs.chart;
      const date1 = new Date(this.data[0].from.replace(/-/g, "/"));
      const date2 = new Date(this.data[this.data.length - 1].to.replace(/-/g, "/"));
      const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      this.diffDays = diffDays;
      this.findMaxValue();
      this.offsetX = 6.85 * (("" + this.maxValue).length + 2) + 15;
      this.pointX = this.offsetX;
      this.distance = (chart.clientWidth - this.offsetX) / this.data.length;

      // распределяем пустое пространство между точками
      this.distance = this.distance + this.distance / (this.data.length - 1);
      return chart;
    },
    draw(chart) {
      this.drawing = true;
      this.gridY(chart);
      this.gridX(chart);
      this.drawChart(chart);
      this.drawing = false;
    },
    gridX(chart) {
      let rr = this.convertDate(this.data[0].from, true);
      let symbols = 6.85 * rr.length;
      let space = symbols + 20;
      let xx = Math.ceil(space / this.distance);
      let yy = Math.ceil(xx / 2);
      for (let i = yy; i < this.data.length; i += xx) {
        let x = this.distance * i + this.offsetX;
        let date = this.convertDate(this.data[i].from, true);
        if (
          i !== this.data.length - 1 &&
          x < (this.data.length - 1) * this.distance + this.offsetX - space / 2
        ) {
          let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
          g.innerHTML = `
          <line stroke="#e5e5e5" x1="${x}" x2="${x}" y1="0" y2="390"></line>
          <text fill="#1c1c1c" x="${x + 1 - symbols / 2}" y="408">${date}</text>
          `;
          chart.append(g);
        }
      }
    },
    gridY(chart) {
      let gridY = [];

      if (this.maxValue < 5) {
        gridY = [5, 4, 3, 2, 1, 0];
      } else {
        gridY = [
          this.maxValue,
          Math.round(this.maxValue / 1.25),
          Math.round(this.maxValue / 1.66666666667),
          Math.round(this.maxValue / 2.5),
          Math.round(this.maxValue / 5),
          0,
        ];
      }
      gridY.forEach((line) => {
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        const y = 380 - this.scaleY(line);
        const textSpace = 6.85 * (("" + this.maxValue).length + 2 - ("" + line).length);

        g.innerHTML = `
          <line stroke="#e5e5e5" x1="${this.offsetX}" x2="${
          chart.clientWidth
        }" y1="${y}" y2="${y}"></line>
          <text fill="#1c1c1c" x="${textSpace}" y="${y + 4}">${line}</text>
        `;
        chart.append(g);
      });
    },
    convertDate(date, min = false) {
      date = new Date(date.replace(/-/g, "/"));
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

      if (min) {
        // меньше 1 дня
        if (this.diffDays < 2) {
          return `${hours}:${minutes}`;
        }
        if (this.diffDays >= 2) {
          return `${day}/${month} ${hours}:${minutes}`;
        }
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      }
    },
    aim(e) {
      if (e.layerX > this.offsetX - 1 && e.layerY < 381) {
        this.aimX = e.layerX;
        this.aimY = e.layerY;

        const x = Math.round((e.layerX - this.offsetX) / this.distance);
        if (x < 0) {
          this.aimI = 0;
        } else if (x > this.data.length - 1) {
          this.aimI = this.data.length - 1;
        } else {
          this.aimI = x;
        }

        this.pointX = this.aimI * this.distance + this.offsetX;
        this.pointY = 380 - this.scaleY(this.data[this.aimI]["max_listeners"]);

        if (e.x + this.$refs.tooltip.offsetWidth + 50 > document.body.clientWidth) {
          this.tooltipOffsetX = -1 * (this.$refs.tooltip.offsetWidth + 20);
        } else {
          this.tooltipOffsetX = 20;
        }
        if (e.layerY + 55 + 20 > 400) {
          this.tooltipOffsetY = -1 * (55 + 20);
        } else {
          this.tooltipOffsetY = 20;
        }
        this.isViewTooltip = true;
      } else {
        this.isViewTooltip = false;
      }
    },
    drawChart(chart) {
      this.gridXCoordinate = [];
      let d = "M" + this.offsetX + " " + (380 - this.scaleY(this.data[0]["max_listeners"]));
      this.data.forEach((value, index) => {
        if (index) {
          d +=
            " L" +
            (this.distance * index + this.offsetX) +
            " " +
            (380 - this.scaleY(value["max_listeners"]));
        }
      });
      let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      path.setAttribute("stroke-width", "3px");
      path.setAttribute("fill", "none");
      path.setAttribute("stroke", "#195BFF");
      path.setAttribute("d", d);
      chart.append(path);
    },
    findMaxValue() {
      this.maxValue = 0;
      this.data.forEach((value) => {
        if (value["max_listeners"] > this.maxValue) {
          this.maxValue = value["max_listeners"];
        }
      });
    },
    scaleY(y) {
      const max_value = this.maxValue < 5 ? 5 : this.maxValue;
      const percent = (y / max_value) * 100;
      return Math.floor(360 * (percent / 100));
    },
    update() {
      if (this.data.length) {
        this.scale = true;
        this.isViewTooltip = false;
        clearTimeout(this.timerId);
        const chart = this.$refs.chart;
        if (chart) {
          chart.replaceChildren();
          this.timerId = setTimeout(() => {
            const chart = this.init();
            this.draw(chart);
            this.chart = this.$refs.chart;
            this.scale = false;
          }, 300);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-chart {
  width: 100%;
  height: 420px;
  font-size: 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__box {
    background: #fff;
    padding: 0 20px 20px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &__wrapper {
    position: relative;
    &:hover {
      .ui-chart__aim-x {
        display: block;
        position: absolute;
        width: 0px;
        border-left: 1px dashed #909090;
        height: 380px;
        pointer-events: none;
      }
      .ui-chart__aim-y {
        display: block;
        position: absolute;
        width: 100%;
        height: 0px;
        border-top: 1px dashed #909090;
        pointer-events: none;
      }
      .ui-chart__tooltip {
        display: block;
      }
    }
  }
  &__aim-x {
    display: none;
  }
  &__aim-y {
    display: none;
  }
  &__point {
    position: absolute;
    pointer-events: none;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: red;
  }
  &__tooltip {
    display: none;
    position: absolute;
    background: #e0e7eb;
    color: #324455;
    padding: 10px;
    min-width: max-content;
    border-radius: 5px;
    pointer-events: none;
  }
  &__loading {
    display: flex;
    justify-content: center;
    padding: 185px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
  &__not-data {
    height: 420px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 14px;
  }
}
</style>
