<template>
  <section>
    <div class="table__header">
      <vs-button
        v-if="data?.length"
        flat
        style="min-width: max-content; margin-left: auto"
        :active="true"
        @click="setupSecurity"
        icon
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить станцию</span>
      </vs-button>
    </div>
    <Table
      name="security"
      :columns="columns"
      :rows="data"
      :meta="meta"
      :expandable="false"
      :headerRadius="false"
      :isLoading="loadingData || pending"
      @row-click="rowClick"
    >
      <template v-slot:table-row="{ props }">
        <div
          v-if="
            (props.column.field === 'actions' && $access.security.update.includes(access_level)) ||
            (props.column.field === 'actions' && checkRoleLevel())
          "
        >
          <span class="editing" @click="update(props.row)" style="margin-right: 10px"
            >Редактировать</span
          >
          <span @click="remove(props.row.id)" class="editing">Удалить</span>
        </div>
        <span v-else-if="props.column.field === 'listeners'"
          >{{ props.row.listeners_count }} /
          {{ props.row.listeners_limit ? props.row.listeners_limit : "∞" }}</span
        >
        <span v-else-if="props.column.field === 'status'">
          {{ convertStatus[props.row.status] }}
        </span>
      </template>
      <template #not-data>
        <div class="not-access">
          В этом разделе Вы можете настроить правила защиты Ваших радиостанций - к примеру,
          ограничить доступ к трансляции из определенных стран / подсетей, либо установить логин и
          пароль для подключения к трансляции.
          <vs-button
            flat
            style="max-width: max-content; margin-top: 20px"
            :active="true"
            icon
            @click="setupSecurity"
          >
            <i class="bx bx-plus" style="margin-right: 5px"></i>
            <span>Настроить безопасность</span>
          </vs-button>
        </div>
      </template>
      <template v-slot:expandable="{ props }">
        <section v-if="props.row.security_type === 'network'">
          <ul>
            <li v-for="network in props.row.security_network" :key="network.id">
              {{ network.sidr }}
            </li>
          </ul>
        </section>
        <section v-else-if="props.row.security_type === 'auth'">
          <ul>
            <li v-for="user in props.row.security_users" :key="user.id">
              Логин: <strong>{{ user.login }}</strong>
            </li>
          </ul>
        </section>
        <section v-else-if="props.row.security_type === 'country'">
          <ul>
            <li v-for="country in props.row.security_countries" :key="country.id">
              Страна: <strong>{{ country.country.name }}</strong>
            </li>
          </ul>
        </section>
      </template>
    </Table>
  </section>
</template>

<script>
import Table from "@/components/Table.vue";
import BaseLoader from "@/components/base/Loader";
import StationsStatus from "@/data/Stations/StationsStatus.json";

export default {
  name: "page-security",
  components: {
    Table,
    BaseLoader,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      convertStatus: null,
      columns: [
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Слушатели",
          field: "listeners",
        },
        {
          label: "Кол-во потоков",
          field: "streams_count",
        },
        {
          label: "Статус",
          field: "status",
        },
      ],
      loadingData: false,
    };
  },
  created() {
    this.convertStatus = {};
    StationsStatus.forEach((item) => {
      this.convertStatus[item.key] = item.label;
    });

    this.loadingData = true;
    this.$store
      .dispatch("stations", {
        additionFilter: "&filter[has_securities]=1",
      })
      .finally(() => {
        this.loadingData = false;
      });
  },
  computed: {
    data() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
    meta() {
      if (this.$store.getters.stationsMeta) {
        return this.$store.getters.stationsMeta;
      } else {
        return null;
      }
    },
  },
  methods: {
    rowClick(row) {
      this.$router.push({
        name: "security-update",
        params: {
          stationId: row.id,
        },
      });
    },
    setupSecurity() {
      this.$emit("setupSecurity");
    },
  },
};
</script>

<style lang="scss" scoped>
.not-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #909ba4;
  max-width: 600px;
  text-align: center;
  line-height: 1.3em;
}
.table__header {
  background: #fff;
  border-radius: 20px 20px 0 0;
  padding: 10px;
}
</style>
