<template>
  <div>
    <div :class="classes">
      <BaseSearch
        v-if="organizationsCount > 1"
        v-model="search"
        :filterAcive="$store.getters.organizationFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
      <vs-button
        v-if="$access.organizations.create.includes(access_level)"
        style="min-width: max-content; margin-left: 20px"
        flat
        :active="true"
        icon
        @click="addOrganization"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить организацию</span>
      </vs-button>
    </div>
    <OrganizationsView :loading-data="loadingData" @back="back" @block="block" />
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" @back="back" @block="block" />
    </vs-dialog>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import OrganizationsAdd from "@/components/Organizations/Add.vue";
import OrganizationsEdit from "@/components/Organizations/Edit.vue";
import OrganizationsView from "@/components/Organizations/View.vue";
import BaseSearch from "@/components/base/Search";
import { setup } from "@/features";

export default {
  name: "Organizations",
  components: {
    OrganizationsAdd,
    OrganizationsEdit,
    OrganizationsView,
    BaseSearch,
  },
  data() {
    return {
      loadingData: true,
      view: OrganizationsView,
      search: this.$store.getters.organizationFilterValue,
    };
  },
  created() {
    this.$store.commit("organizations", []);
    this.$store.commit("organizationsMeta", {});
    this.$store.dispatch("organizations").finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
    search() {
      this.loadingData = true;
      this.$store.commit("organizations", []);
      this.$store.commit("organizationsMeta", {});
      this.$store.dispatch("organizationsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
  },
  computed: {
    organizationsCount() {
      if (this.$access.organizations.support.includes(this.$store.getters.userAccessLevel)) {
        return this.$store.getters.organizationCount;
      } else if (this.$store.getters.userAccessOrganizations?.length) {
        return this.$store.getters.userAccessOrganizations.length;
      } else {
        return 0;
      }
    },
    action: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      },
    },
    filters() {
      return this.$store.getters.organizationsFilters;
    },
    access_organizations() {
      if (this.$access.organizations.update.includes(this.$store.getters.userAccessLevel)) {
        return this.$store.getters.organizationsAdmin;
      } else if (this.$store.getters.userAccessOrganizations?.length) {
        return this.$store.getters.userAccessOrganizations;
      } else {
        return [];
      }
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    isOrganizationsAdd() {
      return this.view.name === "OrganizationAdd";
    },
    isOrganizationsEdit() {
      return this.view.name === "OrganizationEdit";
    },
    classes() {
      return this.getClasses(this.view.name);
    },
  },
  beforeCreate() {
    this.$store.dispatch("validations", null);
  },
  methods: {
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("organizationFilterActive", value);
      this.$store.dispatch("organizationsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    setAction(action) {
      switch (action) {
        case "viewing":
          this.view = OrganizationsView;
          break;
        case "editing":
          this.view = OrganizationsEdit;
          break;
        case "addition":
          this.view = OrganizationsAdd;
          break;
        case "notification":
          this.view = setup.notification.simple;
          break;
        default:
          this.view = OrganizationsView;
      }
    },
    block(view) {
      this.setAction(view);
      // this.view = OrganizationsEdit;
      this.action = true;
    },
    getClasses(viewName) {
      if (viewName === "OrganizationsAdd" || viewName === "OrganizationsEdit") {
        return "x-flex-start flex header-table";
      }
      return "x-flex-end flex header-table";
    },
    back(update = false) {
      if (update) {
        this.loadingData = true;
        this.$store.commit("organizations", []);
        this.$store.commit("organizationsMeta", {});
        this.$store.dispatch("organizations").finally(() => {
          this.loadingData = false;
        });
      }
      this.action = false;
    },
    addOrganization() {
      this.view = OrganizationsAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform-origin: 0 50%;
  transform-origin: 0 0;

  transform: translateY(-20px);
  opacity: 0;
}
.not-access {
  color: #909ba4;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 400px;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.3em;
  width: 90%;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
  body {
    .vs-button {
      margin-left: auto !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
