<template>
  <div>
    <div class="flex header-table">
      <BaseSelect
        v-if="stations?.length && station"
        class="header-select"
        style="width: 200px; margin-top: 20px; margin-right: 20px"
        v-model="station"
        label="Станция"
        field="id"
        title="name"
        :options="stations"
        method="stations"
        :filter="true"
        search="stationsSearch"
        @change="changeStation"
      />
      <date-range-picker
        v-if="stations?.length && station"
        class="header-select"
        style="width: 285px; margin-top: 20px; margin-right: 20px"
        ref="picker"
        v-model="date_range"
        opens="right"
        :ranges="false"
        :min-date="min_date"
        :max-date="max_date"
        :time-picker="true"
        @update="updateRangePicker"
        :locale-data="{
          direction: 'ltr',
          format: 'dd.mm.yyyy hh:MM',
          separator: ' - ',
          applyLabel: 'Сохранить',
          cancelLabel: 'Отмена',
          weekLabel: 'Н',
          customRangeLabel: '',
          daysOfWeek: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
          monthNames: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'Май',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Ноя',
            'Дек',
          ],
          firstDay: 1,
        }"
      >
        <template v-slot:input="picker">
          {{ picker.startDate | moment("DD.MM.YYYY HH:mm") }} -
          {{ picker.endDate | moment("DD.MM.YYYY HH:mm") }}
        </template>
      </date-range-picker>
    </div>
    <Table
      :columns="columns"
      :rows="data"
      :meta="meta"
      :name="'stationSongLog'"
      :headerRadius="false"
      :isLoading="loadingData"
    >
      <template v-slot:table-row="{ props }">
        <span v-if="props.column.field === 'created_at'">{{
          props.row.created_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <div v-else-if="props.column.field === 'search_vk'" class="search_song" @click="searchMusicVk(props.row.title)">
          <search-music-icon />
          Найти
        </div>
        <div
          v-else-if="props.column.field === 'listeners_count'"
          style="display: flex; align-items: center"
        >
          <div
            v-html="props.row.icon"
            style="width: 11px; margin-right: 5px; display: flex; margin-top: -3px"
          ></div>
          <div>{{ props.row.listeners_count }}</div>
        </div>
      </template>
    </Table>
    <Alert :show="showModal" :message="message" @close="alert" />
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import SearchMusicIcon from "@/assets/icons/SearchMusic.vue";
import BaseSearch from "@/components/base/Search";
import BaseSelect from "@/components/base/Select";
import Table from "@/components/Table.vue";
import PlayListTableColumns from "@/data/PlayList/PlayListTableColumns.json";
import Alert from "@/components/Notification/Alert.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Jobs",
  components: {
    Table,
    Alert,
    BaseSearch,
    BaseSelect,
    DateRangePicker,
    SearchMusicIcon,
  },
  data() {
    return {
      date_range: {
        startDate: null,
        endDate: null,
      },
      max_date: new Date(),
      min_date: null,
      columns: PlayListTableColumns,
      loadingData: true,
      station: {},
      message: "",
      search: this.$store.getters.jobFilterValue,
      showModal: false,
      oldListenerCount: 0,
      id: null,
    };
  },
  created() {
    this.date_range = {
      startDate: new Date(this.max_date.getTime() - 86400000), // отнимаем день
      endDate: this.max_date,
    };
    this.$store.commit("stationSongLogs", { data: [], meta: {} });
    this.$store.dispatch("stations").finally(() => {
      if (this.stations?.length) {
        this.station = this.stations[0];
        this.$store.commit("stationSongLogId", this.station.id);
        this.$store.commit("stationSongLogDateFrom", this.date_range.startDate);
        this.$store.commit("stationSongLogDateTo", this.date_range.endDate);
        this.$store.dispatch("stationSongLog").finally(() => (this.loadingData = false));
      } else {
        this.loadingData = false;
      }
    });
  },
  watch: {
    "$store.getters.userSelectOrganization": function () {
      this.loadingData = true;
      this.$store.commit("stationSongLogs", { data: [], meta: {} });
      this.$store.dispatch("stations").finally(() => {
        if (this.stations?.length) {
          this.station = this.stations[0];
          this.$store.commit("stationSongLogId", this.station.id);
          this.$store.commit("stationSongLogDateFrom", this.date_range.startDate);
          this.$store.commit("stationSongLogDateTo", this.date_range.endDate);
          this.$store.dispatch("stationSongLog").finally(() => (this.loadingData = false));
        } else {
          this.loadingData = false;
        }
      });
    },
  },
  computed: {
    stations() {
      if (this.$store.getters.stations?.length) {
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    filters() {
      return this.$store.getters.jobsFilters;
    },
    data() {
      if (this.$store.getters.stationSongLogs?.length) {
        let oldListenerCount = 0;
        const newPlaylistHistory = [];
        const playlistHistory = this.$store.getters.stationSongLogs;

        for (let i = playlistHistory.length - 1; i >= 0; i--) {
          if (playlistHistory[i]["listeners_count"] > oldListenerCount) {
            playlistHistory[i][
              "icon"
            ] = `<svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.71967 0.219668C5.01255 -0.0732225 5.48745 -0.0732225 5.78032 0.219668L10.2803 4.71968C10.5732 5.01255 10.5732 5.48745 10.2803 5.78032C9.98745 6.0732 9.51255 6.0732 9.21967 5.78032L6 2.56066V11.25C6 11.6642 5.66422 12 5.25 12C4.83577 12 4.5 11.6642 4.5 11.25V2.56066L1.28033 5.78032C0.987435 6.0732 0.512565 6.0732 0.219667 5.78032C-0.0732225 5.48745 -0.0732225 5.01255 0.219667 4.71968L4.71967 0.219668Z" fill="#008000"/>
</svg>`;
          } else if (playlistHistory[i]["listeners_count"] === oldListenerCount) {
            playlistHistory[i]["icon"] = ``;
          } else if (playlistHistory[i]["listeners_count"] < oldListenerCount) {
            playlistHistory[i][
              "icon"
            ] = `<svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.71967 11.7803C5.01255 12.0732 5.48745 12.0732 5.78032 11.7803L10.2803 7.28032C10.5732 6.98745 10.5732 6.51255 10.2803 6.21968C9.98745 5.9268 9.51255 5.9268 9.21967 6.21968L6 9.43934V0.75C6 0.335775 5.66422 0 5.25 0C4.83577 0 4.5 0.335775 4.5 0.75V9.43934L1.28033 6.21968C0.987435 5.9268 0.512565 5.9268 0.219667 6.21968C-0.0732225 6.51255 -0.0732225 6.98745 0.219667 7.28032L4.71967 11.7803Z" fill="#FF0000"/>
</svg>`;
          }
          oldListenerCount = playlistHistory[i]["listeners_count"];
          newPlaylistHistory.unshift(playlistHistory[i]);
        }

        return newPlaylistHistory;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.stationSongLogsMeta) {
        return this.$store.getters.stationSongLogsMeta;
      }
      return {};
    },
  },
  methods: {
    searchMusicVk(song) {
      const url = `https://vk.com/search?c%5Bq%5D=${song.replace(' ', '%20')}&c%5Bsection%5D=audio`
      window.open(url, '_blank').focus();
    },
    changeStation(option) {
      this.station = option;
      this.loadingData = true;
      this.$store.commit("stationSongLogs", { data: [], meta: {} });
      this.$store.commit("stationSongLogId", this.station.id);
      this.$store.commit("stationSongLogDateFrom", this.date_range.startDate);
      this.$store.commit("stationSongLogDateTo", this.date_range.endDate);
      this.$store.dispatch("stationSongLog").finally(() => (this.loadingData = false));
    },
    updateRangePicker(value) {
      this.$store.commit("stationSongLogs", { data: [], meta: {} });
      this.date_range.endDate = value.endDate;
      this.date_range.startDate = value.startDate;
      this.loadingData = true;
      this.$store.commit("stationSongLogId", this.station.id);
      this.$store.commit("stationSongLogDateFrom", this.date_range.startDate);
      this.$store.commit("stationSongLogDateTo", this.date_range.endDate);
      this.$store.dispatch("stationSongLog").finally(() => (this.loadingData = false));
    },
    convertStatus(status) {
      switch (status) {
        case "failed":
          return "Ошибка";
        case "finished":
          return "Завершена";
        case "queued":
          return "В очереди";
        case "retrying":
          return "Перезапущенна";
        case "skipped":
          return "Пропущена";
        default:
          return status;
      }
    },
    alert(bool) {
      if (bool) {
        this.$store
          .dispatch("jobRestart", this.id)
          .then(() => {
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно перезапустили задачу.");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось перезапустить задачу.");
              // this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            }
          });
      }
      this.showModal = false;
    },
    restart(row) {
      this.id = row.id;
      this.message = "Вы действительно хотите перезапустить данную задачу?";
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 10px 20px 22px 20px;
  border-radius: 16px 16px 0 0;
}
@media (max-width: 600px) {
  .header-select {
    width: 100% !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform-origin: 0 50%;
  // transform: translate3d();
  transform-origin: 0 0;

  transform: translateY(-20px);
  opacity: 0;
}
.code {
  overflow: auto;
  max-height: 300px;
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  &__wrapper {
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-left: 4px solid #d5d5d5;
    background: #f1f1f1;
  }
}
.search_song {
  width: 12px;
  height: 12px;
  cursor: pointer;
  color: gray;
  &:hover {
    color: #000;
  }
  svg {
    width: 100%;
    height: auto;
  }
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
