<template>
  <div
    class="base-select"
    :class="[
      state ? `base-select--${state}` : '',
      optionsTop ? 'base-select--options-top' : '',
      loading ? 'base-select--not-click' : '',
      readonly ? 'base-select--not-click' : '',
    ]"
    tabIndex="1"
    ref="select"
    @blur="blur"
  >
    <div class="base-select__label">
      {{ label }}
      <span v-if="required" class="base-select__label--required"> *</span>
    </div>
    <input
      class="base-select__value"
      :class="filter ? 'base-select__value--filter' : ''"
      type="text"
      :readonly="!filter"
      v-model="input"
      @input="change"
      @blur="blurInput"
    />
    <i
      class="base-select__icon"
      :class="loading ? 'bx bx-loader-alt base-select--loading' : 'bx bxs-chevron-down'"
    ></i>
    <div class="base-select__options" ref="options" @scroll="scroll">
      <div
        v-if="isFirstItem && typeof firstItem === 'object' && Object.keys(firstItem).length"
        class="base-select__option"
        :class="firstItem[field] === value[field] ? 'base-select__option--active' : ''"
        @click="select(firstItem)"
      >
        {{ firstItem[firstTitle] }}
      </div>
      <div
        v-for="(option, index) in options"
        :key="'option-' + option[field] + '-' + index"
        class="base-select__option"
        :class="{
          'base-select__option--active':
            (multiselect && value.filter((v) => v[field] === option[field])?.length) ||
            (!multiselect && option[field] === value[field]),
          'base-select__option--blocked': option?.blocked,
        }"
        :data-message="option?.message"
        @click="select(option)"
      >
        <i
          v-if="multiselect && value.filter((v) => v[field] === option[field])?.length"
          class="bx bx-check"
        ></i>
        <span>{{ convertTitle(title, option) }}</span>
      </div>
      <div v-if="optionsLoading" class="base-select__option--loading">Загрузка...</div>
      <div v-if="optionsNotData" class="base-select__option--not-data">Нет данных</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: "default",
    },
    label: {
      type: String,
      default: "",
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "label",
    },
    field: {
      type: String,
      default: "field",
    },
    filter: {
      type: Boolean,
      default: false,
    },
    method: {
      type: String,
      default: "",
    },
    search: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFirstItem: {
      type: Boolean,
      default: false,
    },
    firstTitle: {
      type: String,
    },
    firstItem: {
      type: Object,
      default: () => {},
    },
    optionsTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parameters: {
        page: 1,
        concat: false,
      },
      optionsLoading: false,
      optionsNotData: false,
      isNext: true,
      timerId: 0,
      input: "",
      valueSearch: "",
    };
  },
  created() {
    if (this.multiselect) {
      let temp = "";
      this.value.forEach((value, index) => {
        if (!index) {
          temp += this.convertTitle(this.title, value);
        } else {
          temp += ", " + this.convertTitle(this.title, value);
        }
      });
      this.input = temp;
    } else {
      this.input = this.convertTitle(this.title, this.value);
    }
  },
  watch: {
    loading(new_value, old_value) {
      if (!old_value && new_value) {
        this.input = "Загрузка ...";
      } else {
        if (this.multiselect) {
          if (this.value.length) {
            let temp = "";
            this.value.forEach((value, index) => {
              if (!index) {
                temp += this.convertTitle(this.title, value);
              } else {
                temp += ", " + this.convertTitle(this.title, value);
              }
            });
            this.input = temp;
          } else {
            this.input = "Нет данных";
          }
        } else {
          this.input = this.convertTitle(this.title, this.value);
        }
      }
    },
    value() {
      if (this.multiselect) {
        let temp = "";
        if (this.value?.length) {
          this.value.forEach((v, index) => {
            temp +=
              index !== this.value.length - 1
                ? this.convertTitle(this.title, v) + ", "
                : this.convertTitle(this.title, v);
          });
          this.input = temp;
        }
      } else {
        this.input = this.convertTitle(this.title, this.value);
      }
    },
    valueSearch() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.$store.dispatch(this.search, { value: this.valueSearch }).then((response) => {
          if (response.length) {
            this.optionsNotData = false;
          } else {
            this.optionsNotData = true;
          }
        });
      }, 600);
    },
  },
  methods: {
    convertTitle(title, option) {
      let t_option = option;
      let t_title = title;

      if (this.isFirstItem && t_option[this.field] === this.firstItem[this.field]) {
        t_option = this.firstItem;
        t_title = this.firstTitle;
      }
      if (this.loading) {
        return "Загрузка ...";
      } else if (!this.options?.length) {
        return "Нет данных";
      } else if (option?.length) {
        let temp = "";
        option.forEach((obj, index) => {
          if (/{*}/.test(t_title)) {
            temp +=
              title.replace(/{(.*?)}/gm, function (value) {
                return obj[value.replace(/{|}/g, "")];
              }) || "";
          } else {
            temp += obj[t_title];
          }
          if (index + 1 !== option.length) {
            temp += ", ";
          }
        });
        return temp;
      } else if (/{*}/.test(t_title)) {
        return (
          title.replace(/{(.*?)}/gm, function (value) {
            return t_option[value.replace(/{|}/g, "")];
          }) || ""
        );
      } else {
        return t_option[t_title];
      }
    },
    blurInput() {
      this.input = this.convertTitle(this.title, this.value);
      this.valueSearch = "";
    },
    blur() {
      if (!this.filter) {
        return null;
      }
      this.parameters.page = 1;
      this.parameters.concat = false;
      const options = this.$refs.options;
      options.scrollTop = 0;
      this.$store.dispatch(this.method, this.parameters).finally(() => {
        this.isNext = true;
      });
    },
    change() {
      this.valueSearch = this.input;
    },
    scroll() {
      if (!this.filter) {
        return null;
      }
      const options = this.$refs.options;
      if (this.isNext && options.scrollTop + 200 >= options.scrollHeight) {
        this.optionsLoading = true;
        this.parameters.page += 1;
        this.parameters.concat = true;
        this.isNext = false;
        this.$store
          .dispatch(this.method, this.parameters)
          .then((response) => {
            if (response.length) {
              this.isNext = true;
            } else {
              this.isNext = false;
            }
          })
          .finally(() => {
            this.optionsLoading = false;
          });
      }
    },
    select(option) {
      if (this.multiselect) {
        if (this.value.filter((e) => e[this.field] === option[this.field])?.length) {
          const temp = this.value.filter((e) => e[this.field] !== option[this.field]);
          this.$emit("change", temp);
          this.$emit("input", temp);
          if (!temp?.length) {
            this.input = "";
          }
        } else {
          const temp = this.value;
          temp.push(option);
          this.$emit("change", temp);
          this.$emit("input", temp);
        }
      } else {
        if (!option?.blocked) {
          this.$emit("input", option);
          this.$emit("change", option);
          this.$refs.select.blur();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.base-select {
  font-size: 13px;
  position: relative;
  border-radius: 12px;
  background: #f4f7f8;
  min-height: 38px;
  max-height: 38px;
  height: 38px;
  width: 100%;
  transition: all 0.3s ease;
  color: #324455;
  &--danger {
    background: #fdecee;
    .base-select__label {
      color: #ff4757;
    }
    .base-select__value {
      color: #ff4757;
    }
  }
  &--options-top {
    .base-select__options {
      bottom: 38px;
    }
    &:focus {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .base-select__options {
        bottom: 38px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .base-select__value {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .base-select__value {
      &:focus {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        & ~ .base-select__options {
          bottom: 38px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }
  &:focus {
    background: #e0e7eb;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .base-select__value {
      color: #324455;
    }
    .base-select__options {
      z-index: 1001;
      opacity: 1;
      margin-top: 0;
      max-height: 140px;
    }
    .base-select__icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__icon {
    position: absolute;
    z-index: 1000;
    top: 50%;
    right: 10px;
    color: #88939d;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  &__label {
    position: absolute;
    top: -17px;
    left: 10px;
    &--required {
      color: red;
    }
  }
  &__value {
    color: #324455;
    padding: 0 30px 0 15px;
    display: flex;
    height: 100%;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    width: calc(100% - 45px);
    border-radius: 12px;
    font-size: 16px;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    &--filter {
      cursor: text;
    }
    &:focus {
      background: #e0e7eb;
      color: #324455;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      & ~ .base-select__options {
        z-index: 1001;
        opacity: 1;
        margin-top: 0;
        max-height: 140px;
      }
      & ~ .base-select__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &--not-click {
    pointer-events: none;
  }
  &--loading {
    animation: 1s loader infinite linear;
  }
  &__options {
    opacity: 0;
    max-height: 0;
    overflow: auto;
    position: absolute;
    background: #e0e7eb;
    margin-top: -20px;
    z-index: 1000;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.4s ease;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__option {
    padding: 6px 10px;
    margin: 5px;
    font-size: 16px;
    border-radius: 6px;
    transition: margin 0.3s ease;
    &:hover {
      cursor: pointer;
      color: rgba(25, 91, 255, 1);
      margin-left: 10px;
    }
    &--active {
      background: rgba(25, 91, 255, 0.05);
      color: rgba(25, 91, 255, 1);
      &:hover {
        margin-left: 5px;
      }
    }
    &--blocked {
      color: #a5a5a5 !important;
      cursor: not-allowed !important;
      &:hover {
        margin-left: 5px;
      }
      &:after {
        content: attr(data-message);
        font-size: 9px;
        padding: 0 5px;
      }
    }
    &--loading {
      display: flex;
      justify-content: center;
      padding: 6px 10px;
    }
    &--not-data {
      display: flex;
      justify-content: center;
      padding: 6px 10px;
    }
  }
}
@keyframes loader {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
