<template>
  <div class="edit-organization">
    <div class="organization-header">Редактирование пользователя</div>
    <div v-if="!loader">
    <BaseInput :state="isDanger('name')" label-placeholder="Имя" v-model="name" />
    <div class="h20 w20"></div>
    <BaseInput :state="isDanger('email')" label-placeholder="E-mail" v-model="email" />
    <div class="h20 w20"></div>
    <BaseSelect
      :label="'Уровень доступа'"
      v-model="access_level"
      :options="access_levels"
      options-top
      :field="'field'"
      :title="'label'"
    />
    <div class="flex x-flex-end mt20">
      <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
      <vs-button @click="editUser" flat="flat" :active="true" icon="icon">
        <i class="bx bx-pencil" style="margin-right: 5px"></i>
        <span>Изменить</span>
      </vs-button>
    </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "UserEdit",
  components: {
    BaseSelect,
    BaseInput,
    Loader,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      loader: false,
      id: null,
      name: null,
      email: null,

      access_level: {
        label: "Пользователь",
        field: 1,
      },
      access_levels: [
        {
          label: "Разработчик",
          field: 100,
        },
        {
          label: "Администратор",
          field: 90,
        },
        {
          label: "Агент поддержки (2)",
          field: 70,
        },
        {
          label: "Агент поддержки (1)",
          field: 50,
        },
        {
          label: "Пользователь",
          field: 1,
        },
      ],
      access: {
        1: "Пользователь",
        50: "Агент поддержки (1)",
        70: "Агент поддержки (2)",
        90: "Администратор",
        100: "Разработчик",
      },
      oldData: {},
    };
  },
  created() {
    this.id = this.data.id;
    this.name = this.data.name;
    this.email = this.data.email;
    if (this.access[this.data.access_level]) {
      this.access_level = {
        label: this.access[this.data.access_level],
        field: this.data.access_level,
      };
    }
    this.oldData = {
      name: this.name,
      email: this.email,
      access_level: this.access_level.field,
    };
  },
  methods: {
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      const page = this.$store.getters.usersMeta?.current_page || 1;
      this.$emit("close", {
        update: update,
        page: page,
      });
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    editUser() {
      this.$store.commit("validations", null);

      this.newData = {
        name: this.name,
        email: this.email,
        access_level: this.access_level.field,
      };

      const temp = {};
      Object.keys(this.oldData).map((key) => {
        if ("" + this.oldData[key] !== "" + this.newData[key]) {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editUser", {
            id: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили пользователя");
          }).finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-organization {
  padding: 20px;
}
.organization-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.box-form {
  .BaseInput-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .BaseInput-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 450px) {
  .box-form {
    .BaseInput-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
  .organization-btn-box {
    .vs-button {
      margin: 0;
      margin-bottom: 20px;
      margin-right: 0 !important;
      width: 100%;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
