<template>
  <div class="bindings-option">
    <div class="group">
      <div style="opacity: 0.8;">Сетевые интерфейсы</div>
    </div>
    <table>
      <thead>
        <tr>
          <td>IP</td>
          <td style="max-width: 100px">Порт</td>
          <td>SSL</td>
          <td>Действия</td>
        </tr>
      </thead>
      <tbody>
        <BindingsOption v-model="bindings_options[index]" :index="index" @update="update" @remove="remove(index)" v-for="(option, index) in bindings_options" :key="'opt-' + index" />
        <BindingsOption :creating="true" @create="create" />
      </tbody>
    </table>
  </div>
</template>

<script>
import BindingsOption from "@/components/BindingsOption";

export default {
  components: {
    BindingsOption,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    create(data) {
      const temp = this.value;
      temp.push(data);
      this.$emit("input", temp);
    },
    update(data) {
      const temp = this.value;
      temp.push(data);
      this.$emit("input", temp);
    },
    remove(index) {
      const temp = this.value;
      temp.splice(index, 1);
      this.$emit("input", temp);
    }
  },
  computed: {
    bindings_options: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bindings-option {
  width: 100%;
  .group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid #e1e1e1; */
    width: 100%;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  table {
    width: 100%;
    thead {
      td {
        border-bottom: 2px solid #f0f3f4;
      }
    }
    td {
      padding: 10px;
    }
  }
}
@media (max-width: 450px) {
  .bindings-option {
    thead {
      display: none;
    }
    tbody {
      display: block;
    }
    tr {
      display: block;
    }
    td {
      display: flex;
    }
  }
}
</style>
