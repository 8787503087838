<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'stations'"
    :headerRadius="false"
    :isLoading="loadingData"
  >
    <template v-slot:table-row="{ props }">
      <div v-if="props.column.field === 'actions'">
        <IconLupa
          v-if="$access.organizations.support.includes(access_level)"
          @click.native="lupa(props.row)"
          style="margin-right: 20px"
        />
        <span
          style="margin-right: 20px"
          @click="edit(props.row)"
          v-if="isActionUpdate(props.row.status)"
          class="editing"
          >Редактировать</span
        >
        <span v-if="isActionUpdate(props.row.status)" @click="security(props.row)" class="editing"
          >Безопасность</span
        >
      </div>
      <span v-else-if="props.column.field === 'status'">{{ convertStatus[props.row.status] }}</span>
      <span v-else-if="props.column.field === 'websites_limit'">{{
        props.row.websites_limit
      }}</span>
      <span v-else-if="props.column.field === 'listeners_limit'">{{
        props.row.listeners_limit
          ? props.row.listeners_count + " / " + props.row.listeners_limit
          : props.row.listeners_count + " / &#8734;"
      }}</span>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import StationsTableColumns from "@/data/Stations/StationsTableColumns.json";
import StationsStatus from "@/data/Stations/StationsStatus.json";
import IconLupa from "@/components/base/IconLupa";

export default {
  name: "StationsView",
  components: {
    Table,
    IconLupa,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      convertStatus: null,
      columns: StationsTableColumns,
    };
  },
  created() {
    this.convertStatus = {};
    StationsStatus.map((item) => {
      this.convertStatus[item.key] = item.label;
      return "cababanga";
    });
  },
  watch: {
    "$store.getters.userSelectOrganization": function () {
      this.$store.dispatch("stations");
    },
  },
  computed: {
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      } else {
        return 0;
      }
    },
    access_level() {
      return this.$store.getters.userAccessLevel;
    },
    data() {
      if (this.$store.getters.stations) {
        return this.$store.getters.stations;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.stationsMeta) {
        return this.$store.getters.stationsMeta;
      }
      return {};
    },
  },
  methods: {
    isActionUpdate(status) {
      if (status === "blocked") {
        if (this.$access.organizations.update.includes(this.access_level)) {
          return true;
        }
        return false;
      } else {
        return (
          this.$access.stations.update.includes(this.access_level) ||
          this.$role.stations.update.includes(this.member_role)
        );
      }
    },
    security(row) {
      this.$router.push(`/station/${row.id}/security`);
    },
    edit(row) {
      this.$store.commit("idStation", row.id);
      this.$emit("block", "editing");
    },
    lupa(row) {
      this.$store.dispatch("organization", row.organization_id).then((organization) => {
        this.$store.commit("userSelectOrganization", organization);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
