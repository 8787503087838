<template>
  <div class="search__group">
    <BaseInput label-placeholder="Поиск" v-model="search" />
    <div v-if="filters" class="filters">
      <span style="margin-right: 10px;">Фильтры:</span>
      <span
        v-for="key in Object.keys(filters)"
        class="filter"
        :class="[activeFilter === key ? 'filter--active' : '']"
        :key="key"
        @click="changeFilter(key)"
        >{{ filters[key] }}</span
      >
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/base/Input";

export default {
  name: "BaseSearch",
  components: { BaseInput },
  props: {
    value: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      required: true
    },
    filterAcive: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      timerId: 0,
      activeFilter: Object.keys(this.filters)[0],
    };
  },
  created() {
    if (this.filterAcive) {
      this.activeFilter = this.filterAcive;
    }
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(value) {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.$emit("input", value);
        }, 600);
      },
    },
  },
  methods: {
    changeFilter(value) {
      this.activeFilter = value;
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search__group {
  width: 100%;
}
.filters {
  margin-top: 10px;
  font-size: 14px;
}
.filter {
  opacity: 0.5;
  margin-right: 15px;
  transition: all 0.5s ease;
  white-space: nowrap;
  display: inline-block;
  &:first-child {
    margin-left: 10px;
  }
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &--active {
    font-weight: bold;
    opacity: 1;
    pointer-events: none;
  }
}
</style>
