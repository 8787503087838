import axios from "axios";
import Cookies from "js-cookie";

const state = {
  securities: null,
  securitiesMeta: null,
  security: null,
  idSecurity: null,
  securityFilterActive: "security_type",
  securityFilterValue: "",
  securityCheck: null,
  securityFilters: {
    domain: "Домен",
  },
};

const getters = {
  securityCheck: (s) => s.securityCheck,
  securities: (s) => s.securities,
  securitiesMeta: (s) => s.securitiesMeta,
  security: (s) => s.security,
  idSecurity: (s) => s.idSecurity,
  securityFilters: (s) => s.securityFilters,
  securityFilterActive: (s) => s.securityFilterActive,
};

const actions = {
  async tempSecurities() {},
  async securities({ commit, state }, parameters) {
    let id = null;
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";
    let additionFilter = "";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }
    if (parameters?.id) {
      id = parameters.id;
    }
    if (parameters?.additionFilter) {
      additionFilter = "&" + parameters.additionFilter;
    }

    const response = await axios.get(
      `/station/${id}/station-securities?page=${page}&per_page=${per_page}${additionFilter}`
    );
    commit(concat ? "securitiesNext" : "securities", response.data.data);
    commit("securitiesMeta", response.data.meta);
    return response.data.data;
  },
  async securitiesSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.securityFilterValue = value;
    const response = await axios.get(
      `/web-sites?filter[${state.securityFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("securities", response.data.data);
    commit("securitiesMeta", response.data.meta);
    return response.data.data;
  },
  async security({ commit }, id) {
    const response = await axios.get(`/station-securities/${id}`);
    commit("security", response.data.data);
    return response.data.data;
  },
  async createSecurity(_, data) {
    const response = await axios.post("/station-securities", data);
    return response;
  },
  async updateSecurity(_, { id, data }) {
    const response = await axios.patch(`/station-securities/${id}`, data);
    return response;
  },
  async deleteSecurity(_, id) {
    const response = await axios.delete(`/station-securities/${id}`);
    return response;
  },
};

const mutations = {
  securityCheck(state, id) {
    state.securityCheck = id;
  },
  securityFilterActive(state, filter) {
    state.securityFilterActive = filter;
  },
  securities(state, data) {
    state.securities = data;
  },
  securitiesNext(state, data) {
    state.securities = state.securities.concat(data);
  },
  securitiesMeta(state, data) {
    state.securitiesMeta = data;
  },
  security(state, data) {
    state.security = data;
  },
  idSecurity(state, id) {
    state.idSecurity = id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
