<template>
  <div>
    <Table
      v-if="true"
      :columns="columns"
      :rows="data"
      :meta="meta"
      :name="'organizations'"
      :headerRadius="false"
      :isLoading="loadingData"
    >
      <template v-slot:table-row="{ props }">
        <div v-if="props.column.field === 'actions'">
          <IconLupa
            v-if="$access.organizations.support.includes(access_level)"
            @click.native="lupa(props.row)"
            style="margin-right: 20px"
          />
          <span @click="notification(props.row)" class="editing" style="margin-right: 20px"
            >Уведомления</span
          >
          <span
            v-if="isActionUpdate(props.row.blocked, props.row.id)"
            @click="edit(props.row)"
            class="editing"
            style="margin-right: 20px"
            >Редактировать</span
          >
          <span
            v-if="isActionMember(props.row.blocked, props.row.id)"
            @click="members(props.row)"
            class="editing"
            style="margin-right: 20px"
            >Сотрудники</span
          >

          <span
            v-if="$access.organizations.blocked.includes(access_level)"
            class="editing"
            @click="switchBlocked(props.row.blocked, props.row.id)"
            >{{ props.row.blocked ? "Разблокировать" : "Заблокировать" }}</span
          >
        </div>
        <span v-else-if="props.column.field === 'listeners_limit'"
          >{{ props.row.listeners_count }} / {{ props.row.listeners_limit }}</span
        >
        <span v-else-if="props.column.field === 'stations_limit'"
          >{{ props.row.stations_count }} / {{ props.row.stations_limit }}</span
        >
        <span v-else-if="props.column.field === 'streams_limit'"
          >{{ props.row.streams_count }} / {{ props.row.streams_limit }}</span
        >
        <span v-else-if="props.column.field === 'created_at'">{{
          props.row.created_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <span v-else-if="props.column.field === 'updated_at'">{{
          props.row.updated_at | moment("DD.MM.YYYY HH:mm")
        }}</span>
        <span v-else-if="props.column.field === 'websites'"
          >{{ props.row.web_sites_count }} / {{ props.row.web_sites_limit }}</span
        >
        <span v-else-if="props.column.field === 'blocked'">{{
          convertStatus(props.row.blocked)
        }}</span>
      </template>
      <template #not-data>
        <div
          v-if="access_level === 1 && !$store.getters.userAccessOrganizations.length"
          class="not-access"
        >
          К сожалению, вы не являетесь членом ни одной из организаций. Пожалуйста, обратитесь к
          администраторам организаций, чтобы получить доступ к управлению, либо закажите любой из
          тарифов радио хостинга на нашем сайте<br /><a
            href="https://www.dline-media.com"
            target="__blank"
            >www.dline-media.com</a
          >
        </div>
        <div v-else style="font-size: 14px; margin: 70px 0; opacity: 0.5">Нет данных</div>
      </template>
    </Table>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import OrganizationsTableColumns from "@/data/Organizations/OrganizationsTableColumns.json";
import Alert from "@/components/Notification/Alert.vue";
import IconLupa from "@/components/base/IconLupa";

export default {
  name: "OrganizationsView",
  components: {
    Table,
    Alert,
    IconLupa,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: OrganizationsTableColumns,
      dataNone: [],
      message: "",
      blocked: false,
      showModal: false,
      id: null,
    };
  },
  computed: {
    access_organizations() {
      return this.$store.getters.userAccessOrganizations;
    },
    member_role() {
      return this.$store.getters.userMemberRole;
    },
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    isViewOrganizations() {
      if (
        this.$store.getters.organizations?.length ||
        this.$access.organizations.create.includes(this.access_level)
      ) {
        return true;
      } else {
        return false;
      }
    },
    data() {
      if (this.$store.getters.organizations) {
        return this.$store.getters.organizations;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.organizationsMeta) {
        return this.$store.getters.organizationsMeta;
      }
      return {};
    },
  },
  methods: {
    isActionUpdate(blocked, id) {
      if (blocked) {
        if (this.$access.organizations.update.includes(this.access_level)) {
          return true;
        }
        return false;
      } else {
        return (
          this.$access.organizations.update.includes(this.access_level) ||
          this.$role.organizations.update.includes(this.memberRoleInOrganization(id))
        );
      }
    },
    isActionMember(blocked, id) {
      if (blocked) {
        if (this.$access.organizations.update.includes(this.access_level)) {
          return true;
        }
        return false;
      } else {
        return (
          this.$access.organizations.update.includes(this.access_level) ||
          this.$role.organizations.update.includes(this.memberRoleInOrganization(id))
        );
      }
    },
    memberRoleInOrganization(id) {
      const temp = this.access_organizations.filter((organization) => organization.id === id);
      if (temp.length) {
        return temp[0].member_role;
      } else {
        return 0;
      }
    },
    switchBlocked(blocked, id) {
      if (blocked) {
        this.id = id;
        this.blocked = false;
        this.message = "Вы действительно хотите разблокировать данную организацию?";
        this.showModal = true;
      } else {
        this.id = id;
        this.blocked = true;
        this.message = "Вы действительно хотите заблокировать данную организацию?";
        this.showModal = true;
      }
    },
    alert(bool) {
      if (bool) {
        if (this.blocked) {
          this.$store.dispatch("blockOrganization", this.id).then(() => {
            this.$store.dispatch("organizations");
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно заблокировали организацию");
          });
        } else {
          this.$store.dispatch("unblockOrganization", this.id).then(() => {
            this.$store.dispatch("organizations");
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно разблокировали организацию");
          });
        }
      }
      this.showModal = false;
    },
    convertStatus(status) {
      if (status) {
        return "Заблокирована";
      } else {
        return "Активна";
      }
    },
    edit(row) {
      this.$store.commit("idOrganization", row.id);
      this.$emit("block", "editing");
    },
    notification(row) {
      this.$store.commit("idOrganization", row.id);
      this.$emit("block", "notification");
    },
    lupa(row) {
      this.$store.commit("userSelectOrganization", row);
    },
    members(row) {
      if (row.id) {
        this.$router.push(`/organizations/${row.id}/members`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
.not-access {
  color: #909ba4;
  max-width: 400px;
  text-align: center;
  line-height: 1.3em;
  padding: 80px 0;
}
</style>
