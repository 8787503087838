<template>
  <section class="station-box">
    <section v-if="!pending">
      <label>Выберите станцию</label>
      <base-input label-placeholder="Поиск" v-model="filterValue" style="margin-bottom: 20px" />
      <ul class="station-list">
        <template v-if="stations?.length && !loading">
          <li
            v-for="station in stations"
            class="station-item"
            :key="station.id"
            @click="openSecurity(station.id)"
          >
            {{ station.name }}
          </li>
        </template>
        <li v-else-if="loading" class="station--loading">
          <Loader :width="50" :height="50" />
        </li>
        <li v-else class="station--notfound">Ничего не найдено</li>
      </ul>
      <vs-button
        @click="back"
        style="margin-top: 20px; margin-left: auto"
        flat="flat"
        :active="false"
      >
        Назад
      </vs-button>
    </section>
    <aside v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </aside>
  </section>
</template>

<script>
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "station-list",
  components: { BaseInput, Loader },
  data() {
    return {
      stations: [],
      filterValue: "",
      pending: true,
      loading: false,
      searchTimer: 0,
    };
  },
  created() {
    this.$store
      .dispatch("stations", {
        notStore: true,
        additionFilter: "&filter[has_securities]=0",
      })
      .then((response) => {
        this.stations = response;
      })
      .finally(() => {
        this.pending = false;
      });
  },
  watch: {
    filterValue(value) {
      this.loading = true;
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.$store
          .dispatch("stationsSearch", {
            value,
            parameters: { notStore: true, additionFilter: "&filter[has_securities]=0" },
          })
          .then((response) => {
            this.stations = response;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 700);
    },
  },
  computed: {
    stations() {
      if (this.$store.getters.stations) {
        return this.$store.getters.stations;
      }
      return [];
    },
  },
  methods: {
    openSecurity(id) {
      this.$router.push({
        name: "security-update",
        params: {
          stationId: id,
        },
      });
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.station-box {
  width: 100%;
  min-height: 300px;
  max-height: 500px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.station-list {
  height: 192px;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}
.station-item {
  list-style-type: none;
  margin: 0;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    color: #195bff;
    background: rgb(249, 252, 253);
  }
}
.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
}
.station--notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 13px;
  list-style-type: none;
  height: 192px;
}
.station--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 192px;
}
</style>
