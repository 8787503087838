<template>
  <div>
    <Table
      :columns="columns"
      :rows="data"
      :meta="meta"
      :name="'servers'"
      :expandable="true"
      :headerRadius="false"
      :isLoading="loadingData"
      @row-click="rowClick"
    >
      <template v-slot:table-row="{ props }">
        <div
          v-if="props.column.field === 'actions' && $access.servers.update.includes(access_level)"
        >
          <span @click="edit(props.row)" class="editing">Редактировать</span>
          <span
            v-if="['master', 'relay'].includes(props.row.role)"
            @click="openIcecat(props.row)"
            style="margin-left: 10px"
            class="editing"
            >Открыть</span
          >
        </div>
        <span v-else-if="props.column.field === 'cluster_id'">{{ props.row.cluster.name }}</span>
        <span v-else-if="props.column.field === 'role'">{{ convertRole(props.row.role) }}</span>
        <span v-else-if="props.column.field === 'status'">{{
          convertStatus(props.row.status)
        }}</span>
        <span v-else-if="props.column.field === 'max_listeners'">{{
          ["load-balancer", "encoder", "recorder"].includes(props.row.role)
            ? ""
            : `${props.row.listeners_count} / ${props.row.max_listeners}`
        }}</span>
      </template>
      <template v-slot:expandable="{ props }">
        <div>
          <strong>Кластер:</strong> <span>{{ props.row.cluster.name }}</span>
        </div>
        <div>
          <strong>Публичный IP:</strong> <span>{{ props.row.public_ip || "нет" }}</span>
        </div>
        <div>
          <strong>IP:</strong> <span>{{ props.row.connection_data.ip }}</span>
        </div>
        <div>
          <strong>Тип:</strong> <span>{{ convertType(props.row.connection_data.type) }}</span>
        </div>
        <div v-if="props.row.connection_data.type === 'password'">
          <strong>Пароль:</strong> <span>{{ props.row.connection_data.password }}</span>
        </div>
        <div>
          <strong>Порт:</strong> <span>{{ props.row.connection_data.port }}</span>
        </div>
        <div>
          <strong>FQDN:</strong> <span>{{ props.row.fqdn }}</span>
        </div>
        <div v-if="!['recorder', 'encoder', 'load-balancer'].includes(props.row.role)">
          <strong>Лимит сетевого порта:</strong> <span>{{ props.row.max_bandwidth }}</span>
        </div>
        <div v-if="!['recorder', 'encoder', 'load-balancer'].includes(props.row.role)">
          <strong>Мак. кол-во слушателей:</strong> <span>{{ props.row.max_listeners }}</span>
        </div>
        <div v-if="!['recorder', 'encoder', 'load-balancer'].includes(props.row.role)">
          <strong>Кол-во CPU:</strong> <span>{{ props.row.max_workers }}</span>
        </div>
        <div>
          <strong>Роль:</strong> <span>{{ convertRole(props.row.role) }}</span>
        </div>
        <div>
          <strong>Статус:</strong> <span>{{ convertStatus(props.row.status) }}</span>
        </div>
      </template>
    </Table>
    <vs-dialog prevent-close not-padding v-model="isIcecast">
      <div class="dialog-icecast">
        <div class="dialog-icecast__username">
          Логин:
          <strong class="dialog-icecast--copy" :data-id="0" :data-copy="username" @click="copyBuff"
            >скопировать</strong
          >
        </div>
        <div class="dialog-icecast__password">
          Пароль:
          <strong class="dialog-icecast--copy" :data-id="1" :data-copy="password" @click="copyBuff"
            >скопировать</strong
          >
        </div>
        <vs-button
          flat
          :active="true"
          @click="toIcecast"
          style="min-width: max-content; margin-top: 20px"
        >
          <span>Перейти</span>
        </vs-button>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import ServersTableColumns from "@/data/Servers/ServersTableColumns.json";

export default {
  components: {
    Table,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: ServersTableColumns,
      isIcecast: false,
      username: null,
      password: null,
      link: null,
      server: null,
    };
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    data() {
      if (this.$store.getters.servers) {
        return this.$store.getters.servers;
      }
      return [];
    },
    meta() {
      if (this.$store.getters.serversMeta) {
        return this.$store.getters.serversMeta;
      }
      return {};
    },
  },
  methods: {
    rowClick(row) {
      if (row.status === "failed") {
        this.$store.commit("serverBatchId", row.batch_id);
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationTitle", "Произошла ошибка");
        this.$store.dispatch(
          "notificationMessage",
          "<div>" +
            "<div>Во время обновления конфигурации сервера произошла ошибка.</div>" +
            `<div>Код операции - <strong>${row.batch_id || "не указано"}</strong></div>` +
            "</div>"
        );
        const isActive = this.$access.jobs.read.includes(this.access_level);
        this.$store.dispatch("notificationButton", {
          active: isActive,
          value: "Посмотреть логи",
          method: () => {
            if (this.$store.getters.server_batch_id) {
              this.$store.commit("jobFilterActive", "batch_id");
              this.$store.dispatch("jobsSearch", { value: this.$store.getters.server_batch_id });
              this.$router.push("/jobs");
            }
          },
        });
      }
    },

    copyBuff(event) {
      clearTimeout(event.target.dataset.id);
      const el = document.createElement("textarea");
      el.value = event.target.dataset.copy;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      event.target.innerHTML = "скопировано";
      event.target.classList.add("dialog-icecast--copied");
      event.target.dataset.id = setTimeout(() => {
        event.target.classList.remove("dialog-icecast--copied");
        event.target.innerHTML = "скопировать";
      }, 3000);
    },
    toIcecast() {
      if (this.link) {
        window.open(this.link);
      }
    },
    openIcecat(server) {
      (this.isIcecast = true),
        (this.username = server["icecast-admin"]["admin_config"]["username"] || "-");
      this.password = server["icecast-admin"]["admin_config"]["password"] || "-";
      this.link = server["icecast-admin"]["link"] || "-";
    },
    convertRole(role) {
      switch (role) {
        case "load-balancer":
          return "Балансировщик";
        case "encoder":
          return "Перекодировщик";
        case "recorder":
          return "Рекордер";
        case "master":
          return "Мастер";
        case "relay":
          return "Релей";
        case "standalone":
          return "Одиночный";
        default:
          return role;
      }
    },
    convertStatus(status) {
      switch (status) {
        case "operational":
          return "В работе";
        case "maintenance":
          return "Тех. обслуживание";
        case "failed":
          return "Произошла ошибка";
        default:
          return status;
      }
    },
    convertType(type) {
      switch (type) {
        case "password":
          return "По паролю";
        case "key":
          return "По ключу";
        default:
          return type;
      }
    },
    edit(row) {
      this.$store.commit("idServer", row.id);
      this.$emit("block", "editing");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-icecast {
  padding: 15px;
  &--copy {
    cursor: pointer;
  }
  &--copied {
    color: green;
    cursor: default;
  }
}
.header-table {
  background: #fff;
  padding: 10px 20px;
  border-radius: 16px 16px 0 0;
}
.editing {
  opacity: 0.5;
}
.editing:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
