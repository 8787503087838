import axios from "axios";
import Cookies from "js-cookie";

const state = {
  servers: null,
  serversMeta: null,
  server: null,
  idServer: null,
  server_batch_id: null,
  serverFilterActive: "fqdn",
  serverFilterValue: "",
  serversFilters: {
    fqdn: "FQDN",
    role: "Роль",
    ip: "IP",
    status: "Статус",
    id: "ID",
  },
};

const getters = {
  servers: (s) => s.servers,
  serversMeta: (s) => s.serversMeta,
  server: (s) => s.server,
  server_batch_id: (s) => s.server_batch_id,
  idServer: (s) => s.idServer,
  serverFilterActive: (s) => s.serverFilterActive,
  serverFilterValue: (s) => s.serverFilterValue,
  serversFilters: (s) => s.serversFilters,
};

const actions = {
  async servers({ commit, state }, parameters) {
    let page = 1;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }

    const response = await axios.get(
      `/servers?page=${page}&per_page=${per_page}&filter[${state.serverFilterActive}]=${state.serverFilterValue}`
    );
    commit("servers", response.data.data);
    commit("serversMeta", response.data.meta);
    return response;
  },
  async serversRegenerateConfig() {
    const response = await axios.post("/servers/regenerate-config");
    return response;
  },
  async serverRegenerateConfig({}, id) {
    const response = await axios.post(`/servers/${id}/regenerate-config`);
    return response;
  },
  async serversSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.serverFilterValue = value;
    const response = await axios.get(
      `/servers?filter[${state.serverFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("servers", response.data.data);
    commit("serversMeta", response.data.meta);
    return response.data.data;
  },
  async server({ commit }, id) {
    const response = await axios.get(`/servers/${id}`);
    commit("server", response.data.data);
  },
  async addServer(_, data) {
    const response = await axios.post("/servers", data);
    return response;
  },
  async editServer(_, { id, data }) {
    const response = await axios.patch(`/servers/${id}`, data);
    return response;
  },
  async delServer(_, id) {
    const response = await axios.delete(`/servers/${id}`);
    return response;
  },
};

const mutations = {
  serverBatchId(state, value) {
    state.server_batch_id = value;
  },
  serverFilterActive(state, filter) {
    state.serverFilterActive = filter;
  },
  servers(state, data) {
    state.servers = data;
  },
  serversMeta(state, data) {
    state.serversMeta = data;
  },
  server(state, data) {
    state.server = data;
  },
  idServer(state, id) {
    state.idServer = id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
