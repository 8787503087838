<template>
  <tr>
    <td>
      <BaseInput
        :state="isDanger(`binding_options.${index}.address`)"
        v-if="creating || updating"
        v-model="ip"
        class="mobile"
        label-placeholder="IP"
        style="margin-left: -10px"
      />
      <BaseInput
        :state="isDanger(`binding_options.${index}.address`)"
        v-if="creating || updating"
        v-model="ip"
        style="margin-left: -10px"
        class="pc"
      />
      <span :class="isDanger(`binding_options.${index}.address`)" v-else>
        <strong class="label">IP: </strong>{{ ip }}</span
      >
    </td>
    <td style="max-width: 100px">
      <BaseInput
        :state="isDanger(`binding_options.${index}.port`)"
        v-if="creating || updating"
        v-model="port"
        class="mobile"
        label-placeholder="Порт"
        style="margin-left: -10px"
      />
      <BaseInput
        :state="isDanger(`binding_options.${index}.port`)"
        v-if="creating || updating"
        v-model="port"
        class="pc"
        style="margin-left: -10px"
      />
      <span :class="isDanger(`binding_options.${index}.port`)" v-else
        ><strong class="label">Порт: </strong>{{ port }}</span
      >
    </td>
    <td>
      <vs-switch
        class="mobile"
        :state="isDanger(`binding_options.${index}.ssl`)"
        v-if="creating || updating"
        v-model="ssl"
        style="margin-left: -10px"
        >SSL</vs-switch
      >
      <vs-switch
        class="pc"
        :state="isDanger(`binding_options.${index}.ssl`)"
        v-if="creating || updating"
        v-model="ssl"
      />
      <span :class="isDanger(`binding_options.${index}.ssl`)" v-else
        ><strong class="label">SSL: </strong>{{ ssl ? "Да" : "Нет" }}</span
      >
    </td>
    <td>
      <div v-if="!creating && !updating" style="display: flex">
        <vs-button
          @click="remove"
          style="margin-right: 10px"
          icon
          color="danger"
          flat="flat"
          :active="false"
        >
          <i class="bx bx-x-circle"></i>
        </vs-button>
        <vs-button @click="edit" icon flat="flat" :active="false">
          <i class="bx bxs-edit-alt"></i>
        </vs-button>
      </div>
      <vs-button v-if="!creating && updating" flat="flat" @click="update" :active="false"
        >Сохранить</vs-button
      >
      <vs-button v-if="creating" flat="flat" :active="false" @click="create">Добавить</vs-button>
    </td>
  </tr>
</template>

<script>
import BaseInput from "@/components/base/Input";

export default {
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
      default: -1,
    },
    creating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADDRESS: "",
      PORT: "",
      SSL: false,
      updating: false,
    };
  },
  methods: {
    create() {
      if (this.ADDRESS && this.PORT) {
        this.$emit("create", {
          address: this.ADDRESS,
          port: this.PORT,
          ssl: this.SSL,
        });
        this.ADDRESS = "";
        this.PORT = "";
        this.SSL = false;
      }
    },
    edit() {
      this.ADDRESS = this.value.address;
      this.PORT = this.value.port;
      this.SSL = this.value.ssl;
      this.updating = true;
    },
    update() {
      if (this.ADDRESS && this.PORT) {
        this.$emit("input", {
          address: this.ADDRESS,
          port: this.PORT,
          ssl: this.SSL,
        });
        this.updating = false;
      }
    },
    remove() {
      this.$emit("remove");
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
  },
  computed: {
    ip: {
      get() {
        if (this.creating || this.updating) {
          return this.ADDRESS;
        } else {
          return this.value?.address || "";
        }
      },
      set(value) {
        if (this.creating || this.updating) {
          this.ADDRESS = value;
        } else {
          this.$emit("input", value);
        }
      },
    },
    port: {
      get() {
        if (this.creating || this.updating) {
          return this.PORT;
        } else {
          return this.value?.port || "";
        }
      },
      set(value) {
        if (this.creating || this.updating) {
          this.PORT = value;
        } else {
          this.$emit("input", value);
        }
      },
    },
    ssl: {
      get() {
        if (this.creating || this.updating) {
          return this.SSL;
        } else {
          return this.value?.ssl || "";
        }
      },
      set(value) {
        if (this.creating || this.updating) {
          this.SSL = value;
        } else {
          this.$emit("input", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  padding: 10px;
  .danger {
    color: #ff4757;
  }
}
.label {
  display: none;
}
.pc {
  display: flex;
}
.mobile {
  display: none;
}
@media (max-width: 450px) {
  tr {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  td {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
  }
  .label {
    display: inline;
  }
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
  }
  button {
    margin-left: -10px !important;
    margin-right: 20px !important;
  }
}
</style>
