<template>
  <div>
    <div :class="classes">
      <BaseSearch
        v-model="search"
        :filterAcive="$store.getters.serverFilterActive"
        :filters="filters"
        @change="changeFilter"
      />
      <vs-button
        v-if="$access.servers.create.includes(access_level)"
        class="server-button"
        flat
        :loading="isRegenerate"
        :active="true"
        @click="serversRegenerateConfig"
      >
        <span>Перегенерация конфига</span>
      </vs-button>
      <vs-button
        class="server-button"
        v-if="$access.servers.create.includes(access_level)"
        flat
        :active="true"
        icon
        @click="addServer"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить сервер</span>
      </vs-button>
    </div>
    <ServersView @back="back" :loadingData="loadingData" @block="block" />
    <vs-dialog prevent-close not-padding v-model="action">
      <component :is="view" @back="back" @block="block" />
    </vs-dialog>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import ServersAdd from "@/components/Servers/Add.vue";
import ServersEdit from "@/components/Servers/Edit.vue";
import ServersView from "@/components/Servers/View.vue";
import NotificationFailure from "@/components/Servers/NotificationFailure.vue";
import BaseSearch from "@/components/base/Search";

export default {
  name: "Servers",
  components: {
    ServersAdd,
    ServersEdit,
    ServersView,
    BaseSearch,
    NotificationFailure,
  },
  data() {
    return {
      isRegenerate: false,
      view: ServersView,
      loadingData: true,
      search: this.$store.getters.serverFilterValue,
    };
  },
  created() {
    this.$store.commit("servers", []);
    this.$store.commit("serversMeta", {});
    this.$store.dispatch("servers").finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    search() {
      this.loadingData = true;
      this.$store.dispatch("serversSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
  },
  computed: {
    access_level() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      }
      return 1;
    },
    action: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      },
    },
    filters() {
      return this.$store.getters.serversFilters;
    },
    isServersAdd() {
      return this.view.name === "ServersAdd";
    },
    isServersEdit() {
      return this.view.name === "ServersEdit";
    },
    classes() {
      return this.getClasses(this.view.name);
    },
  },
  beforeCreate() {
    this.action = false;
    this.$store.dispatch("validations", null);
  },
  methods: {
    serversRegenerateConfig() {
      if (!this.isRegenerate) {
        this.isRegenerate = true;
        this.$store
          .dispatch("serversRegenerateConfig")
          .then(() => {
            this.$store.dispatch("notification", "success");
            this.$store.dispatch(
              "notificationMessage",
              "Вы успешно перегенерировали конфиги серверов"
            );
          })
          .catch(() => {
            this.$store.dispatch("notification", "failure");
            this.$store.dispatch(
              "notificationMessage",
              "Не удалось перегенерировать конфиги серверов"
            );
          })
          .finally(() => {
            this.isRegenerate = false;
          });
      }
    },
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("serverFilterActive", value);
      this.$store.dispatch("serversSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    block(view) {
      switch (view) {
        case "editing":
          this.view = ServersEdit;
          break;
        case "failure":
          this.view = NotificationFailure;
          break;
      }
      this.action = true;
    },
    getClasses(viewName) {
      if (viewName === "ServersAdd" || viewName === "ServersEdit") {
        return "x-flex-end flex header-table";
      }
      return "x-flex-end flex header-table";
    },
    back(update = false) {
      if (typeof update === "boolean" && update) {
        this.loadingData = false;
        this.$store.commit("servers", []);
        this.$store.commit("serversMeta", {});
        this.$store.dispatch("servers").finally(() => {
          this.loadingData = false;
        });
      }
      this.action = false;
    },
    addServer() {
      this.view = ServersAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
  }
}
.server-button {
  min-width: max-content;
  margin-left: 20px;
  @media (max-width: 980px) {
    margin-left: 0;
    margin-top: 10px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
