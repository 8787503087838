import axios from "axios";
import Cookies from "js-cookie";

const state = {
  stations: null,
  stationsMeta: null,
  station: null,
  idStation: null,
  stationFilterActive: "name",
  stationFilterValue: "",
  stationSongLogs: [],
  stationSongLogsMeta: {},
  stationSongLogId: null,
  stationSongLogDateFrom: null,
  stationSongLogDateTo: null,
  stationsFilters: {
    name: "Название",
    listeners_limit: "Лимит слушателей",
    id: "ID",
  },
};

const getters = {
  stationSongLogs: (s) => s.stationSongLogs,
  stationSongLogsMeta: (s) => s.stationSongLogsMeta,
  stations: (s) => s.stations,
  stationsMeta: (s) => s.stationsMeta,
  station: (s) => s.station,
  idStation: (s) => s.idStation,
  stationFilterActive: (s) => s.stationFilterActive,
  stationFilterValue: (s) => s.stationFilterValue,
  stationsFilters: (s) => s.stationsFilters,
};

const convertDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  day = day < 10 ? "0" + day : day;
  month = month + 1 < 10 ? "0" + (month + 1) : month + 1;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${year}-${month}-${day} ${hours}:${minutes}:00`;
};

const actions = {
  async stationSongLog({ commit, state }, data) {
    let page = 1;
    let per_page = Cookies.get("perPage") || "25";

    const date = new Date();
    const id = state.stationSongLogId;

    const date_to = convertDate(state.stationSongLogDateTo);
    const date_from = convertDate(state.stationSongLogDateFrom);

    if (data?.page) {
      page = data.page;
    }

    const response = await axios.get(
      `/stations/${id}/song-logs?page=${page}&per_page=${per_page}&date_from=${date_from}&date_to=${date_to}`
    );
    commit("stationSongLogs", response.data);
    return response.data.data;
  },
  async stations({ commit, getters, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";
    let organization_id = getters.userSelectOrganization?.id || "";
    let additionFilter = "";
    let notStore = false;

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.organization_id) {
      organization_id = parameters.organization_id;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }
    if (parameters?.additionFilter) {
      additionFilter = parameters.additionFilter;
    }
    if (parameters?.notStore) {
      notStore = parameters.notStore;
    }

    const response = await axios.get(
      `/stations?page=${page}&per_page=${per_page}&filter[organization_id]=${organization_id}&filter[${state.stationFilterActive}]=${state.stationFilterValue}${additionFilter}`
    );

    if (!notStore) {
      commit(concat ? "stationsNext" : "stations", response.data.data);
      commit("stationsMeta", response.data.meta);
    }
    return response.data.data;
  },
  async stationsSearch({ commit, state, getters }, { value, parameters }) {
    let per_page = Cookies.get("perPage") || "25";
    let notStore = false;
    let additionFilter = "";
    const organization_id = getters.userSelectOrganization?.id || "";

    state.stationFilterValue = value;

    if (parameters?.notStore) {
      notStore = parameters.notStore;
    }

    if (parameters?.additionFilter) {
      additionFilter = parameters.additionFilter;
    }

    const response = await axios.get(
      `/stations?filter[${state.stationFilterActive}]=${value}&filter[organization_id]=${organization_id}&per_page=${per_page}${additionFilter}`
    );

    if (!notStore) {
      commit("stations", response.data.data);
      commit("stationsMeta", response.data.meta);
    }
    return response.data.data;
  },
  async station({ commit }, id) {
    const response = await axios.get(`/stations/${id}`);
    commit("station", response.data.data);
    return response.data.data;
  },
  async addStation(_, data) {
    const response = await axios.post("/stations", data);
    return response;
  },
  async editStation(_, { id, data }) {
    const response = await axios.patch(`/stations/${id}`, data);
    return response;
  },
  async delStation(_, id) {
    const response = await axios.delete(`/stations/${id}`);
    return response;
  },
};

const mutations = {
  stationSongLogs(state, data) {
    state.stationSongLogs = data.data;
    state.stationSongLogsMeta = data.meta;
  },
  stationSongLogId(state, id) {
    state.stationSongLogId = id;
  },
  stationSongLogDateFrom(state, dateFrom) {
    state.stationSongLogDateFrom = dateFrom;
  },
  stationSongLogDateTo(state, dateTo) {
    state.stationSongLogDateTo = dateTo;
  },
  stationFilterActive(state, filter) {
    state.stationFilterActive = filter;
  },
  stations(state, data) {
    state.stations = data;
  },
  stationsNext(state, data) {
    state.stations = state.stations.concat(data);
  },
  stationsMeta(state, data) {
    state.stationsMeta = data;
  },
  station(state, data) {
    state.station = data;
  },
  idStation(state, id) {
    state.idStation = id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
