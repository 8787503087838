<template>
  <label class="radio">
    <input type="radio" :name="name" :value="value" @change="onChecked" :checked="name === value" />
    <div class="custom-radio"></div>
    <span><slot>label radio</slot></span>
  </label>
</template>

<script>
export default {
  name: "ComponentRadio",
  model: {
    prop: "name",
  },
  props: {
    name: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
  },
  methods: {
    onChecked(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  display: flex;
  cursor: pointer;
  align-items: center;
  .custom-radio {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    border-radius: 50%;
    transition: all 0.2s ease;
    box-sizing: border-box;
    background: #abb2b9;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background: white;
      border-radius: 50%;
      width: 11.5px;
      height: 11.5px;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease;
    }
  }
  &:hover {
    .custom-radio {
      background: #d6d8dc;
    }
  }
  input {
    display: none;
    &:checked {
      & ~ .custom-radio {
        background: #195cff;
        &:before {
          width: 5px;
          height: 5px;
        }
      }
    }
  }
  span {
    margin-left: 10px;
    font-size: 13px;
  }
}
</style>
