<template>
  <div class="ui-checkbox">
    <input v-model="computedValue" type="checkbox" />
  </div>
</template>

<script>
export default {
  name: "checkbox",
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
