import axios from "axios";
import Cookies from "js-cookie";

const state = {
  jobs: null,
  jobsMeta: null,
  jobFilterActive: "type",
  jobFilterValue: "",
  jobsFilters: {
    type: "Тип",
    status: "Статус",
    batch_id: "Batch ID",
  },
};

const getters = {
  jobs: (s) => s.jobs,
  jobsMeta: (s) => s.jobsMeta,
  jobFilterActive: (s) => s.jobFilterActive,
  jobFilterValue: (s) => s.jobFilterValue,
  jobsFilters: (s) => s.jobsFilters,
};

const actions = {
  async jobs({ commit, state }, parameters) {
    let page = 1;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }

    const response = await axios.get(
      `/jobs?page=${page}&per_page=${per_page}&filter[${state.jobFilterActive}]=${state.jobFilterValue}`
    );
    commit("jobs", response.data.data);
    commit("jobsMeta", response.data.meta);
    return response.data.data;
  },
  async jobsSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.jobFilterValue = value;
    const response = await axios.get(
      `/jobs?filter[${state.jobFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("jobs", response.data.data);
    commit("jobsMeta", response.data.meta);
    return response.data.data;
  },
  async jobRestart({ dispatch }, id) {
    const response = await axios.get(`/jobs/${id}/restart`);
    dispatch("jobs");
    return response;
  },
  async jobCreate({ dispatch }, data) {
    const response = await axios.post(`/jobs`, data);
    dispatch("jobs");
    return response;
  },
};

const mutations = {
  jobFilterActive(state, filter) {
    state.jobFilterActive = filter;
  },
  jobs(state, data) {
    state.jobs = data;
  },
  jobsMeta(state, data) {
    state.jobsMeta = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
