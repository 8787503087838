<template>
  <div class="base-pagination__wrapper">
    <ul class="base-pagination" ref="pagination">
      <li
        :class="
          active - 1 < 1
            ? 'base-pagination__item base-pagination__iteration base-pagination__item--not-active'
            : 'base-pagination__item base-pagination__iteration'
        "
        @click="prevPage"
      >
        ‹
      </li>
      <li
        v-for="(item, index) in items"
        :key="'pagination-' + index"
        :class="active === item ? 'base-pagination__item--active' : 'base-pagination__item'"
        @click="select(item)"
      >
        {{ item }}
      </li>
      <li
        :class="
          active + 1 > pages
            ? 'base-pagination__item base-pagination__iteration base-pagination__item--not-active'
            : 'base-pagination__item base-pagination__iteration'
        "
        @click="nextPage"
      >
        ›
      </li>
    </ul>
    <div class="base-pagination__per-page">
      <div style="margin-right: 10px">Количество на странице</div>
      <div style="width: 80px">
        <BaseSelect v-model="perPage" :options="perPageOption" :field="'field'" :title="'label'" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import Cookies from "js-cookie";

export default {
  name: "BasePagination",
  components: {
    BaseSelect,
  },
  props: {
    pages: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      perPage: {
        field: 25,
        label: 25,
      },
      perPageOption: [
        {
          field: 25,
          label: 25,
        },
        {
          field: 50,
          label: 50,
        },
        {
          field: 75,
          label: 75,
        },
        {
          field: 100,
          label: 100,
        },
      ],
      active: 1,
      oldPerPage: 25,
    };
  },
  created() {
    const perPage = parseInt(Cookies.get("perPage"));
    if (perPage) {
      this.perPage = {
        field: perPage,
        label: perPage,
      };
    }
    this.oldPerPage = this.perPage.field;
  },
  watch: {
    perPage() {
      if (this.oldPerPage !== this.perPage.field) {
        this.oldPerPage = this.perPage.field;
        Cookies.set("perPage", this.perPage.field);
        this.$store.dispatch(this.name);
        this.active = 1;
        this.$emit("input", 1);
      }
    },
  },
  computed: {
    items() {
      var temp = [];
      if (this.pages < 7) {
        for (let i = 1; i < this.pages + 1; i++) {
          temp.push(i);
        }
        return temp;
      } else {
        for (let i = 1; i < this.pages + 1; i++) {
          if (i === 1) {
            temp.push(i);
          } else if (i === this.active - 1) {
            temp.push(i);
          } else if (i === this.active) {
            temp.push(i);
          } else if (i === this.active + 1) {
            temp.push(i);
          } else if (i === this.active + 2 && i < 4) {
            temp.push(i);
          } else if (this.active === this.pages && i === this.pages - 2) {
            temp.push(i);
          } else if (i === this.pages) {
            temp.push(i);
          }
        }

        if (this.active > 3) {
          temp.splice(1, 0, "...");
        }
        if (this.active < this.pages - 2) {
          temp.splice(temp.length - 1, 0, "...");
        }
        return temp;
      }
    },
  },
  methods: {
    select(value) {
      if (typeof value === "number") {
        this.active = value;
        this.$emit("input", value);
      }
    },
    nextPage() {
      if (this.active + 1 <= this.pages) {
        this.select(this.active + 1);
      }
    },
    prevPage() {
      if (this.active - 1 > 0) {
        this.select(this.active - 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  font-family: dubki-regural, sans-serif;
  position: relative;
  background: rgb(240, 243, 244);
  width: max-content;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
  &__iteration {
    font-size: 26px;
    line-height: 0.6;
  }
  &__wrapper {
    width: 100%;
  }
  &__per-page {
    margin: 10px auto;
    width: max-content;
    font-size: 15px;
    color: #afafaf;
    display: flex;
    align-items: center;
  }
  &__item {
    list-style-type: none;
    padding: 9px;
    text-align: center;
    opacity: 0.8;
    min-width: 18px;
    transition: all 0.4s ease-out;
    &:hover {
      cursor: pointer;
      background: rgb(230, 233, 234);
    }
    &--active {
      list-style-type: none;
      padding: 9px;
      text-align: center;
      opacity: 0.8;
      min-width: 18px;
      transition: all 0.4s ease-out;
      color: #fff;
      background: #195bff;
      border-radius: 10px;
      &:hover {
        cursor: unset;
        background: rgb(25, 91, 255);
      }
    }
    &--not-active {
      opacity: 0.3;
    }
  }
}
</style>
