<template>
  <div class="actions">
    <Tooltip label="Заблокировать поток">
      <vs-button class="actions__button" icon color="danger" border @click="onBlocked">
        <i class="bx bx-lock"></i>
      </vs-button>
    </Tooltip>
    <Tooltip label="Отклонить нарушение">
      <vs-button class="actions__button" icon color="success" border @click="onReject">
        <i class="bx bx-check"></i>
      </vs-button>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip";

export default {
  name: "ListActions",
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  components: { Tooltip },
  methods: {
    onBlocked(event) {
      event.stopPropagation();
      if (!this.id) return;
      this.$store.dispatch("bitrateOverlimitBlock", { id: this.id }).then(() => {
        this.$emit("update");
      });
    },
    onReject(event) {
      event.stopPropagation();
      if (!this.id) return;
      this.$store.dispatch("bitrateOverlimitPass", { id: this.id }).then(() => {
        this.$emit("update");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  margin-left: -10px;
  &__button {
    margin-left: 10px;
    i {
      font-size: 1rem !important;
    }
  }
}
</style>
