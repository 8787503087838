<template>
  <vs-dialog
    auto-width
    not-padding
    style="z-index: 999999"
    v-model="activeDialog"
    class="as-notification"
    @close="close"
  >
    <div class="p20 notification-server">
      <lottie :options="defaultOptions" :height="150" :width="150" />
      <div class="flex x-center notification-title">ошибка</div>
      <div class="flex x-center" style="width: 260px; text-align: center">
        <span>Сервер временно недоступен, обратитесь в <a href="#">службу поддержки</a>.</span>
      </div>
      <div class="flex x-center mt10">
        <vs-button transparent @click="close"> ОК </vs-button>
      </div>
    </div>
  </vs-dialog>
</template>

<script>
import Lottie from "../../../node_modules/vue-lottie/src/lottie.vue";
import * as animationData from "../../assets/animate/notification/server.json";

export default {
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
      activeDialog: true,
    };
  },
  methods: {
    close() {
      this.activeDialog = !this.activeDialog;
      this.$store.dispatch("notification", null);
      this.$store.dispatch("notificationMessage", null);
    },
  },
};
</script>

<style lang="scss">
.notification-failure {
  min-width: 260px;
  min-height: 260px;
}
.notification-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 600px) {
  body {
    .as-notification {
      align-items: center;
      .vs-dialog {
        border-radius: 16px;
        max-width: 300px;
        max-height: 300px;
        min-height: 300px;
      }
    }
  }
}
</style>
