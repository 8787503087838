import axios from "axios";
import Cookies from "js-cookie";

const state = {
  organizations: null,
  organizationsMeta: null,
  organization: null,
  members: null,
  idOrganization: null,
  organizationFilterActive: "name",
  organizationFilterValue: "",
  organizationsFilters: {
    name: "Название",
    ownerEmail: "E-mail владельца",
    billing_id: "ID биллинга",
    id: "ID",
  },
  organizationsAdmin: [],
  organizationsMetaAdmin: {},
  organizationAdmin: null,
  organizationFilterActiveAdmin: "name,id",
  connectionSettings: null,
  organizationCount: 0,
};

const getters = {
  organizations: (s) => s.organizations,
  organizationsMeta: (s) => s.organizationsMeta,
  organization: (s) => s.organization,
  members: (s) => s.members,
  idOrganization: (s) => s.idOrganization,
  organizationFilterActive: (s) => s.organizationFilterActive,
  organizationFilterValue: (s) => s.organizationFilterValue,
  organizationsFilters: (s) => s.organizationsFilters,

  organizationsAdmin: (s) => s.organizationsAdmin,
  organizationsMetaAdmin: (s) => s.organizationsMetaAdmin,
  organizationAdmin: (s) => s.organizationAdmin,
  organizationFilterActiveAdmin: (s) => s.organizationFilterActiveAdmin,
  connectionSettings: (s) => s.connectionSettings,
  organizationCount: (s) => s.organizationCount,
};

const actions = {
  async organizations({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";
    let value = state.organizationFilterValue;
    let filter = state.organizationFilterActive;

    if (parameters?.filter) {
      filter = parameters.filter;
    }
    if (parameters?.value) {
      value = parameters.value;
    }
    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }
    if (parameters?.per_page) {
      per_page = parameters.per_page;
    }

    const response = await axios.get(
      `/organizations?page=${page}&per_page=${per_page}&filter[${filter}]=${value}`
    );
    commit(concat ? "organizationsNext" : "organizations", response.data.data);
    commit("organizationsMeta", response.data.meta);
    return response.data.data;
  },
  async organizationsConnectionSettings({ commit }, id) {
    const response = await axios.get(`/organizations/${id}/connection-settings`);
    commit("connectionSettings", response.data.data);
    return response.data.data;
  },
  async organizationsSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.organizationFilterValue = value;
    const response = await axios.get(
      `/organizations?filter[${state.organizationFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("organizations", response.data.data);
    commit("organizationsMeta", response.data.meta);
    return response.data.data;
  },
  async organization({ commit }, id) {
    const response = await axios.get(`/organizations/${id}`);
    commit("organization", response.data.data);
    return response.data.data;
  },
  async members({ commit }, id) {
    const response = await axios.get(`/organizations/${id}`);
    commit("members", response.data.data.members);
    return response.data.data.members;
  },
  async editMember(_, { organizationId, userId, data }) {
    const response = await axios.patch(`/organizations/${organizationId}/users/${userId}`, data);
    return response;
  },
  async removeMember(_, { organizationId, userId }) {
    const response = await axios.delete(`/organizations/${organizationId}/users/${userId}`);
    return response;
  },
  async addMember(_, { organizationId, data }) {
    const response = await axios.post(`/organizations/${organizationId}/users`, data);
    return response;
  },
  async addOrganization(_, data) {
    const response = await axios.post("/organizations", data);
    return response;
  },
  async editOrganization(_, { id, data }) {
    const response = await axios.patch(`/organizations/${id}`, data);
    return response;
  },
  async delOrganization(_, id) {
    const response = await axios.delete(`/organizations/${id}`);
    return response;
  },
  async blockOrganization(_, id) {
    const response = await axios.patch(`/organizations/${id}/block`);
    return response;
  },
  async unblockOrganization(_, id) {
    const response = await axios.patch(`/organizations/${id}/unblock`);
    return response;
  },
  async organizationNotification(_, id) {
    const response = await axios.get(`/organization/${id}/notification-preference`);
    return response;
  },
  async organizationChangeNotification(_, { id, data }) {
    const response = await axios.patch(`/organization/${id}/notification-preference`, {
      types: data,
    });
    return response;
  },

  async organizationsAdmin({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";
    const token = Cookies.get("token");

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }
    if (parameters?.per_page) {
      per_page = parameters.per_page;
    }

    if (token) {
      const response = await axios.get(`/organizations?page=${page}&per_page=${per_page}`);
      commit(concat ? "organizationsNextAdmin" : "organizationsAdmin", response.data.data);
      commit("organizationsMetaAdmin", response.data.meta);
      state.organizationCount = response.data.meta.total;
      return response.data.data;
    }
  },
  async organizationsSearchAdmin({ commit }, value) {
    let per_page = Cookies.get("perPage") || "25";
    const response = await axios.get(
      `/organizations?filter[idOrName]=${value}&per_page=${per_page}`
    );
    commit("organizationsAdmin", response.data.data);
    commit("organizationsMetaAdmin", response.data.meta);
    return response.data.data;
  },
  async organizationAdmin({ commit }, id) {
    const response = await axios.get(`/organizations/${id}`);
    commit("organizationAdmin", response.data.data);
    return response.data.data;
  },
};

const mutations = {
  organizationFilterActive(state, filter) {
    state.organizationFilterActive = filter;
  },
  organizations(state, data) {
    state.organizations = data;
  },
  organizationsNext(state, data) {
    state.organizations = state.organizations.concat(data);
  },
  organizationsMeta(state, data) {
    state.organizationsMeta = data;
  },
  organization(state, data) {
    state.organization = data;
  },
  members(state, data) {
    state.members = data;
  },
  idOrganization(state, id) {
    state.idOrganization = id;
  },

  organizationFilterActiveAdmin(state, filter) {
    state.organizationFilterActiveAdmin = filter;
  },
  organizationsAdmin(state, data) {
    state.organizationsAdmin = data;
  },
  organizationsNextAdmin(state, data) {
    state.organizationsAdmin = state.organizationsAdmin.concat(data);
  },
  organizationsMetaAdmin(state, data) {
    state.organizationsMetaAdmin = data;
  },
  organizationAdmin(state, data) {
    state.organizationAdmin = data;
  },
  connectionSettings(state, data) {
    state.connectionSettings = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
