
import axios from "axios";

const state = {
  value: "",
};

const getters = {
  searchValue: (s) => s.value,
};

const mutations = {
  searchValue(state, value) {
    state.value = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
