import axios from "axios";
import Cookies from 'js-cookie';

const state = {
  token: null,
  name: "",
  email: "",
  password: "",
};

const getters = {
  name: (s) => s.name,
  email: (s) => s.email,
  password: (s) => s.password,
};

const actions = {
  async login({ state, commit }) {
    const response = await axios.post('/login', { email: state.email, password: state.password });
    commit("setToken", response.data.token);
    location.href = "/clusters";
    return response;
  },
  async register({ state, commit }) {
    const response = await axios.post('/register', { name: state.name, email: state.email, password: state.password });
    commit("setToken", response.data.token);
    location.href = "/clusters";
    return response;
  },
  async restore_password({ state }) {
    const response = await axios.post('/reset-password-request', { email: state.email });
    return response;
  },
  async reset_password({}, data) {
    const response = await axios.post('/reset-password-confirm', {
      email: data?.email,
      token: data?.token,
      password: data?.password,
    });
    return response;
  },
  async logout({ commit }) {
    commit("setToken", null);
    location.href = "/auth?page=login";
  },
  async login_with_code({ commit }, code) {
    const response = await axios.post('/login-with-code', { code: code });
    commit("setToken", response.data.token);
    return response.data;
  },
};

const mutations = {
  setToken(state, token) {
    if (token) {
      state.token = token;
      Cookies.set('token', token, { expires: 30 });
    } else {
      state.token = null;
      Cookies.remove('token', token);
    }
  },
  setName(state, name) {
    state.name = name;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setPassword(state, password) {
    state.password = password;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
