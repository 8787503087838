<template>
  <Table
    :columns="columns"
    :rows="data"
    :meta="meta"
    :name="'bitrate-overlimits'"
    :isLoading="pending"
    :expandable="true"
  >
    <template v-slot:table-row="{ props }">
      <span v-if="['allowed_bitrate', 'current_bitrate'].includes(props.column.field)"
        >{{ props.row[props.column.field] }} <span style="opacity: 0.5">kb/s</span></span
      >
      <Actions
        v-else-if="props.column.field === 'actions'"
        :id="props.row.id"
        @update="getBitrateOverlimits"
      />
    </template>
    <template v-slot:expandable="{ props }">
      <div>
        <strong>Дата превышения: </strong>
        <span>{{ props.row.updated_at | moment("DD.MM.YYYY") || "-" }}</span>
      </div>
      <div>
        <strong>Зафиксированный битрейт:</strong> <span>{{ props.row.current_bitrate }} kb/s</span>
      </div>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/Table.vue";
import Actions from "./Actions.vue";

export default {
  components: { Table, Actions },
  data() {
    return {
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Радиостанция",
          field: "name_station",
        },
        {
          label: "Поток",
          field: "mount_stream",
        },
        {
          label: "Разрешенный битрейт",
          field: "allowed_bitrate",
        },
        {
          label: "Превышающий битрейт",
          field: "current_bitrate",
        },
        {
          label: "Действия",
          field: "actions",
        },
      ],
      meta: {},
    };
  },
  created() {
    this.getBitrateOverlimits();
  },
  computed: {
    data() {
      const bitrateOverlimits = this.$store.getters.bitrateOverlimits;
      if (!bitrateOverlimits) return [];
      return bitrateOverlimits;
    },
    pending() {
      const pending = this.$store.getters.bitrateOverlimitsPending;
      return pending;
    },
  },
  methods: {
    getBitrateOverlimits() {
      this.$store.dispatch("bitrateOverlimits");
    },
  },
};
</script>

<style lang="scss" scoped></style>
