<template>
  <div class="instructions" ref="instructions">
    <div class="instruction">
      <div
        class="instruction__video"
        :style="`max-width: ${frame_width}px; max-height: ${frame_height}px; min-width: ${frame_width}px; min-height: ${frame_height}px`"
      >
        <iframe
          :width="frame_width"
          :height="frame_height"
          src="https://www.youtube.com/embed/-bpuDaBqVdA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
        <loader class="instruction__loading" />
      </div>
      <div class="instruction__box">
        <div class="instruction__title">Как бесплатно создать своё радио в Интернете?</div>
        <div class="instruction__description">
          <div>- Что нужно, чтобы создать радио?</div>
          <div>- Регистрация на радио хостинге</div>
          <div>- Настройка ПО</div>
          <div>- Проверка трансляции</div>
        </div>
      </div>
    </div>
    <div class="instruction" style="margin-bottom: 0">
      <div
        class="instruction__video"
        :style="`max-width: ${frame_width}px; max-height: ${frame_height}px; min-width: ${frame_width}px; min-height: ${frame_height}px`"
      >
        <iframe
          :width="frame_width"
          :height="frame_height"
          src="https://www.youtube.com/embed/k-YfpC8eLhY?si=QLZDe-GspxLXyKin"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
        <loader class="instruction__loading" />
      </div>
      <div class="instruction__box">
        <div class="instruction__title">Как настроить AutoDJ для круглосуточной трансляции?</div>
        <div class="instruction__description">
          <div>- Какие инструменты есть для AutoDJ?</div>
          <div>- Настройка виртуальный машины (VM)</div>
          <div>- Загрузка аудио файлов</div>
          <div>- Запуск трансляции c VM</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/base/Loader";

export default {
  name: "instructions",
  components: { Loader },
  data() {
    return {
      frame_width: 360, // 560
      frame_height: 201, // 315
    };
  },
  mounted() {
    this.checkResize();
    window.addEventListener("resize", this.checkResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkResize);
  },
  methods: {
    checkResize() {
      if (this.$refs.instructions.clientWidth < 759) {
        const ratio = this.frame_width / this.frame_height;
        this.frame_width = this.$refs.instructions.clientWidth - 40;
        this.frame_height = this.frame_width / ratio;
      } else {
        this.frame_width = 360;
        this.frame_height = 201;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.instruction {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 1048px) {
    flex-direction: column-reverse;
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
  &__video {
    position: relative;
    background: #f4f4f4;
    border-radius: 15px;
    iframe {
      position: relative;
      z-index: 2;
      border-radius: 15px;
    }
  }
  &__box {
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 1049px) {
      padding-left: 20px;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
  }
  &__description {
    font-size: 14px;
    @media (max-width: 1048px) {
      margin-bottom: 10px;
    }
  }
}
</style>
