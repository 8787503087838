<template>
  <div class="added-server">
    <div class="server-header">Добавление сервера</div>
    <div v-if="!clustersLoading && !loader">
      <div class="two-column">
        <div class="column-1">
          <help-field :help="'Выберите кластер'">
            <BaseSelect
              :label="'Кластер'"
              required
              v-model="cluster"
              :options="clusters"
              :field="'id'"
              :title="'name'"
              :filter="true"
              :method="'clusters'"
              :search="'clustersSearch'"
              :loading="clustersLoading"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field
            :help="'Выберите роль: <br>1. <strong>Одиночный</strong> - принимает потоки и отдает их<br>2. <strong>Мастер</strong> - только принимает потоки<br>3. <strong>Релей</strong> - раздает потоки<br>4. <strong>Балансировщик</strong> - распределяет запросы между серверами<br>5. <strong>Перекодировщик</strong> - перекодировка в другие форматы'"
          >
            <BaseSelect :label="'Роль'" required v-model="role" :options="roleOptions" />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Введите IP'">
            <BaseInput :state="isDanger('ip')" label-placeholder="IP" required v-model="ip" />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Введите доменное имя'">
            <BaseInput :state="isDanger('fqdn')" label-placeholder="FQDN" required v-model="fqdn" />
          </help-field>
          <div v-if="role.field === 'recorder'" class="h20 w20"></div>
          <help-field v-if="role.field === 'recorder'" :help="'Введите кол-во места на диске'">
            <BaseInput
              :state="isDanger('disk_space')"
              label-placeholder="Место на диске (GB)"
              required
              v-model="disk_space"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Максимальное количество слушателей'"
          >
            <BaseInput
              :state="isDanger('max_listeners')"
              label-placeholder="Мак. кол-во слушателей"
              required
              v-model="max_listeners"
            />
          </help-field>
          <div
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            class="h20 w20"
          ></div>
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Лимит сетевого порта в kb/s'"
          >
            <BaseInput
              :state="isDanger('max_bandwidth')"
              label-placeholder="Лимит сетевого порта"
              required
              v-model="max_bandwidth"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Публичный IP-адрес'">
            <BaseInput
              :state="isDanger('public_ip')"
              label-placeholder="Публичный IP-адрес"
              required
              v-model="public_ip"
            />
          </help-field>
        </div>
        <div class="column-2">
          <help-field
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            :help="'Максимальное количество CPU'"
          >
            <BaseInput
              :state="isDanger('max_workers')"
              label-placeholder="Кол-во CPU"
              required
              v-model="max_workers"
            />
          </help-field>
          <div
            v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
            class="h20 w20"
          ></div>
          <help-field
            :help="'У сервера на тех. обслуживании не обновляется конфигурация и не направляются слушатели'"
          >
            <BaseSelect :label="'Статус'" required v-model="status" :options="statusOptions" />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'IP-адрес для подключения по SSH'">
            <BaseInput
              :state="isDanger('connection_data.ip')"
              label-placeholder="IP-адрес подключения"
              required
              v-model="connection_data.ip"
            />
          </help-field>
          <div class="h20 w20"></div>
          <help-field :help="'Порт подключения по SSH'">
            <BaseInput
              :state="isDanger('connection_data.port')"
              label-placeholder="Порт подключения"
              required
              v-model="connection_data.port"
            />
          </help-field>
          <div class="h20 w20"></div>

          <help-field
            :help="'Выберите тип подключения по SSH:<br>1. <strong>По паролю</strong> от пользователя root<br>2. <strong>По ключу</strong> (публичный ключ уточнить у DevOps)'"
          >
            <BaseSelect
              :label="'Тип подключения'"
              required
              v-model="connection_data.type"
              :options="typeOptions"
            />
          </help-field>
          <div v-if="connection_data.type.field === 'password'">
            <div class="h20 w20"></div>
            <help-field :help="'Пароль подключения по SSH'">
              <BaseInput
                type="password"
                v-model="connection_data.password"
                :state="isDanger('connection_data.password')"
                label-placeholder="Пароль подключения"
                required
                :visiblePassword="hasVisiblePassword"
                @click-icon="hasVisiblePassword = !hasVisiblePassword"
              >
                <template #icon>
                  <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
                  <i v-else class="bx bx-hide"></i>
                </template>
              </BaseInput>
            </help-field>
          </div>
        </div>
      </div>
      <div
        v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
        class="h20 w20"
      ></div>
      <BindingsOptions
        v-if="!['load-balancer', 'encoder', 'recorder'].includes(role.field)"
        v-model="binding_options"
      />
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addServer" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Создать сервер</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import HelpField from "@/components/base/HelpField";
import BindingsOptions from "@/components/BindingsOptions";
import Loader from "@/components/base/Loader";

export default {
  name: "ServersAdd",
  components: {
    BaseSelect,
    BaseInput,
    HelpField,
    BindingsOptions,
    Loader,
  },
  data() {
    return {
      loader: false,
      disk_space: 1,
      binding_options: [],
      hasVisiblePassword: false,
      clustersLoading: false,
      ip: "",
      public_ip: "",
      fqdn: "",
      cluster: {},
      max_listeners: "",
      max_bandwidth: "",
      max_workers: "",
      provision: "static",
      status: {
        field: "operational",
        label: "В работе",
      },
      // in_production: false,
      role: {
        field: "standalone",
        label: "Одиночный",
      },
      typeOptions: [
        {
          field: "password",
          label: "По паролю",
        },
        {
          field: "key",
          label: "По ключу",
        },
      ],
      statusOptions: [
        {
          field: "operational",
          label: "В работе",
        },
        {
          field: "maintenance",
          label: "Тех. обслуживание",
        },
      ],
      connection_data: {
        ip: "",
        port: "",
        type: {
          field: "password",
          label: "По паролю",
        },
        password: "",
      },
    };
  },
  created() {
    this.clustersLoading = true;
    this.$store.dispatch("clusters").finally(() => {
      if (this.clusters.length) {
        this.cluster = this.clusters[0];
      }
      this.clustersLoading = false;
    });
  },
  computed: {
    roleOptions() {
      if (this.cluster?.type === "ha") {
        this.role = {
          field: "standalone",
          label: "Одиночный",
        };
        return [
          {
            field: "standalone",
            label: "Одиночный",
          },
          {
            field: "master",
            label: "Мастер",
          },
          {
            field: "relay",
            label: "Релей",
          },
          {
            field: "load-balancer",
            label: "Балансировщик",
          },
          {
            field: "encoder",
            label: "Перекодировщик",
          },
          {
            field: "recorder",
            label: "Рекордер",
          },
        ];
      } else {
        this.role = {
          field: "standalone",
          label: "Одиночный",
        };
        return [
          {
            field: "standalone",
            label: "Одиночный",
          },
          {
            field: "master",
            label: "Мастер",
          },
          {
            field: "relay",
            label: "Релей",
          },
        ];
      }
    },
    clusters() {
      if (this.$store.getters.clusters?.length) {
        return this.$store.getters.clusters;
      } else {
        return [];
      }
    },
  },
  methods: {
    copyBuffer(text) {
      clearTimeout(this.timerId);
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.text = "Пароль скопирован";
      this.timerId = setTimeout(() => {
        this.isCopied = false;
        this.text = "Сгенерировать пароль";
      }, 1000);
    },
    generatePassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";

      for (let i = 0, n = charset.length; i < 15; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      return password;
    },
    genPassword() {
      const password = this.generatePassword();
      this.copyBuffer(password);
      this.connection_data.password = password;
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    convertStringToBool(value) {
      if (value === "true") return true;
      return false;
    },
    async addServer() {
      this.loader = true;
      this.$store.commit("validations", null);
      const data = {
        public_ip: this.public_ip,
        ip: this.ip,
        fqdn: this.fqdn,
        cluster_id: this.cluster.id,
        role: this.role.field,
        provision: this.provision,
        status: this.status.field,
        connection_data: {
          ip: this.connection_data.ip,
          port: this.connection_data.port,
          type: this.connection_data.type.field,
        },
      };

      if (!["load-balancer", "encoder", "recorder"].includes(this.role.field)) {
        data.max_listeners = this.max_listeners;
        data.max_bandwidth = this.max_bandwidth;
        data.max_workers = this.max_workers;
        data.binding_options = this.binding_options;
      }
      if ("recorder" === this.role.field) {
        data["disk_space"] = this.disk_space;
      }

      if (data.connection_data.type === "password") {
        data.connection_data["password"] = this.connection_data.password;
      }

      this.$store
        .dispatch("addServer", data)
        .then(() => {
          this.back(true);
          this.$store.dispatch("notification", "success");
          this.$store.dispatch("notificationMessage", "Вы успешно создали сервер");
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-server {
  padding: 35px;
  overflow: auto;
  max-height: 630px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.server-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .BaseInput-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
.generate-password {
  position: absolute;
  top: 12px;
  right: 40px;
  transition: all 0.4s ease;
  &:hover {
    cursor: pointer;
    transform: rotate(35deg);
    &:before {
      content: "\ecd2";
    }
  }
  &:active {
    transform: scale(0.9);
  }
}
.two-column {
  display: flex;
}
.column-1 {
  width: 250px;
  margin-right: 35px;
}
.column-2 {
  width: 250px;
}
@media (max-width: 800px) {
  .box-form {
    .BaseInput-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .added-server {
    max-height: 100%;
  }
  .two-column {
    flex-direction: column;
  }
  .column-1 {
    width: 100%;
    margin-right: 0;
  }
  .column-2 {
    margin-top: 20px;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .BaseInput-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
