<template>
  <div class="edit-organization">
    <div class="organization-header">Редактирование сотрудника</div>
    <div v-if="!loader">
      <div class="required">
        <BaseSelect
          :label="'Роль'"
          v-model="member_role"
          required
          :options="member_roles"
          :field="'field'"
          :title="'label'"
        />
      </div>
      <div style="height: 56px; width: 20px"></div>
      <div class="flex x-flex-end mt20">
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="del" flat="flat" :active="true" icon="icon" danger="danger" class="mr20">
          <i class="bx bx-trash" style="margin-right: 5px"></i>
          <span>Удалить</span>
        </vs-button>
        <vs-button @click="editMember" flat="flat" :active="true" icon="icon">
          <i class="bx bx-pencil" style="margin-right: 5px"></i>
          <span>Изменить</span>
        </vs-button>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
    <Alert :show="showModal" :message="message" @close="alert" />
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import Alert from "@/components/Notification/Alert.vue";
import Loader from "@/components/base/Loader";

export default {
  name: "OrganizationEdit",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Alert,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loader: false,
      showModal: false,
      message: "",
      id: null,
      name: "",
      email: "",
      access_level: {
        label: "Пользователь",
        field: 1,
      },
      access_levels: [
        {
          label: "Разработчик",
          field: 100,
        },
        {
          label: "Администратор",
          field: 90,
        },
        {
          label: "Агент поддержки (2)",
          field: 70,
        },
        {
          label: "Агент поддержки (1)",
          field: 50,
        },
        {
          label: "Пользователь",
          field: 1,
        },
      ],
      access: {
        1: "Пользователь",
        50: "Агент поддержки (1)",
        70: "Агент поддержки (2)",
        90: "Администратор",
        100: "Разработчик",
      },
      member_role: {
        label: "Просмотр",
        field: 1,
      },
      oldData: null,
      newData: null,
      roles: {
        1: "Просмотр",
        2: "Управление",
        3: "Администрирование",
        4: "Владелец",
      },
      member_roles: [
        {
          label: "Просмотр",
          field: 1,
        },
        {
          label: "Управление",
          field: 2,
        },
        {
          label: "Администрирование",
          field: 3,
        },
      ],
    };
  },
  created() {
    this.id = this.data.id;
    // this.name = this.data.name;
    // this.email = this.data.email;
    // if (this.access[this.data.access_level]) {
    //   this.access_level = {
    //     label: this.access[this.data.access_level],
    //     field: this.data.access_level,
    //   };
    // }
    if (this.roles[this.data.member_role]) {
      this.member_role = {
        label: this.roles[this.data.member_role],
        field: this.data.member_role,
      };
    }
    this.oldData = {
      // name: this.name,
      // email: this.email,
      // access_level: this.access_level,
      role: this.member_role.field,
    };
  },
  methods: {
    alert(bool) {
      if (bool) {
        this.loader = true;
        this.$store
          .dispatch("removeMember", {
            organizationId: this.organizationId,
            userId: this.id,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно удалили сотрудника");
          })
          .catch((e) => {
            if (e.response) {
              // Проверка на валидацию
              this.$store.dispatch("notification", "failure");
              this.$store.dispatch("notificationMessage", "Не удалось удалить сотрудника");
              this.back();
            } else if (e.request) {
              // Запрос был отправлен, но ответа не было
              this.$store.dispatch("notification", "server");
            } else {
              // Что-то произошло при настройке запроса, вызвавшего ошибку
              console.log("Error", e.message);
            }
          }).finally(() => {
            this.loader = false;
          });
      }
      this.showModal = false;
    },
    del() {
      this.message = "Вы действительно хотите удалить сотрудника?";
      this.showModal = true;
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    editMember() {
      this.$store.commit("validations", null);

      this.newData = {
        role: this.member_role.field,
      };

      const temp = {};
      Object.keys(this.oldData).map((key) => {
        if ("" + this.oldData[key] !== "" + this.newData[key]) {
          temp[key] = this.newData[key];
        }
        return true;
      });

      if (Object.keys(temp).length > 0) {
        this.loader = true;
        this.$store
          .dispatch("editMember", {
            organizationId: this.organizationId,
            userId: this.id,
            data: temp,
          })
          .then(() => {
            this.back(true);
            this.$store.dispatch("notification", "success");
            this.$store.dispatch("notificationMessage", "Вы успешно изменили сотрудника");
          }).finally(() => {
            this.loader = false;
          });
      } else {
        this.$store.dispatch("notification", "failure");
        this.$store.dispatch("notificationMessage", "Вы ничего не изменили");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-organization {
  padding: 20px;
  overflow: auto;
  min-height: 210px;
  max-height: 430px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.organization-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.box-form {
  .vs-input-parent {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-select-content {
    width: calc(25% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .box-form {
    .vs-input-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .edit-organization {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .vs-input-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
  .organization-btn-box {
    .vs-button {
      margin: 0;
      margin-bottom: 20px;
      margin-right: 0 !important;
      width: 100%;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
