import axios from "axios";
import Cookies from "js-cookie";

const state = {
  websites: null,
  websitesMeta: null,
  website: null,
  idWebsite: null,
  websiteFilterActive: "domain",
  websiteFilterValue: "",
  websiteCheck: null,
  websitesFilters: {
    domain: "Домен",
  },
};

const getters = {
  websiteCheck: (s) => s.websiteCheck,
  websites: (s) => s.websites,
  websitesMeta: (s) => s.websitesMeta,
  website: (s) => s.website,
  idWebsite: (s) => s.idWebsite,
  websitesFilters: (s) => s.websitesFilters,
  websiteFilterActive: (s) => s.websiteFilterActive,
};

const actions = {
  async websites({ commit, state }, parameters) {
    let page = 1;
    let concat = false;
    let per_page = Cookies.get("perPage") || "25";

    if (parameters?.page) {
      page = parameters.page;
    }
    if (parameters?.concat) {
      concat = parameters.concat;
    }

    const response = await axios.get(
      `/web-sites?page=${page}&per_page=${per_page}&filter[${state.websiteFilterActive}]=${state.websiteFilterValue}`
    );
    commit(concat ? "websitesNext" : "websites", response.data.data);
    commit("websitesMeta", response.data.meta);
    return response.data.data;
  },
  async websitesSearch({ commit, state }, { value }) {
    let per_page = Cookies.get("perPage") || "25";
    state.websiteFilterValue = value;
    const response = await axios.get(
      `/web-sites?filter[${state.websiteFilterActive}]=${value}&per_page=${per_page}`
    );
    commit("websites", response.data.data);
    commit("websitesMeta", response.data.meta);
    return response.data.data;
  },
  async websiteConnect({}, id) {
    const response = await axios.post(`/web-sites/${id}/connect`);
    return response.data.data;
  },
  async website({ commit }, id) {
    const response = await axios.get(`/web-sites/${id}`);
    commit("website", response.data.data);
    return response.data.data;
  },
  async addWebsite(_, data) {
    const response = await axios.post("/web-sites", data);
    return response;
  },
  async editWebsite(_, { id, data }) {
    const response = await axios.patch(`/web-sites/${id}`, data);
    return response;
  },
  async delWebsite(_, id) {
    const response = await axios.delete(`/web-sites/${id}`);
    return response;
  },
};

const mutations = {
  websiteCheck(state, id) {
    state.websiteCheck = id;
  },
  websiteFilterActive(state, filter) {
    state.websiteFilterActive = filter;
  },
  websites(state, data) {
    state.websites = data;
  },
  websitesNext(state, data) {
    state.websites = state.websites.concat(data);
  },
  websitesMeta(state, data) {
    state.websitesMeta = data;
  },
  website(state, data) {
    state.website = data;
  },
  idWebsite(state, id) {
    state.idWebsite = id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
