<template>
  <div class="added-station">
    <div class="station-header">Добавление веб-сайта</div>
    <div v-if="!websiteLoading && !loading">
      <help-field :help="'Выберите организацию'">
        <BaseSelect
          :state="isDanger('organization_id')"
          required
          :label="'Организация'"
          v-model="organization"
          :options="organizations"
          :field="'id'"
          :title="'name'"
          :filter="true"
          :method="'organizations'"
          :search="'organizationsSearch'"
          :loading="organizationsLoading"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите шаблон отображения'">
        <BaseSelect
          :state="isDanger('template')"
          required
          :label="'Шаблон отображения'"
          v-model="template"
          :options="templates"
          :field="'field'"
          :title="'label'"
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Выберите станции'">
        <BaseSelect
          :state="isDanger('station_ids')"
          required
          :label="'Выберите станции'"
          v-model="station_check"
          :options="stations"
          :field="'id'"
          :title="'name'"
          :loading="stationsLoading"
          multiselect
        />
      </help-field>
      <div class="h20 w20"></div>
      <help-field :help="'Введите Ваш домен'">
        <BaseInput
          class="width"
          :state="isDanger('domain')"
          label-placeholder="Домен"
          required
          v-model="domain"
        />
      </help-field>
      <a href="" @click.prevent="getHelp" style="font-size: 12px; margin-left: 12px">Нет домена?</a>
      <div class="h20 w20"></div>
      <div
        v-if="$access.websites.update.includes(accessLevel) || organization?.blocked === false"
        class="flex x-flex-end mt20"
      >
        <vs-button @click="back" class="mr20" flat="flat" :active="false"> Назад </vs-button>
        <vs-button @click="addWebsite" flat="flat" :active="true" icon="icon">
          <i class="bx bx-plus" style="margin-right: 5px"></i>
          <span>Добавить</span>
        </vs-button>
      </div>
      <div
        v-else-if="!$access.stations.update.includes(accessLevel) && organization?.blocked === true"
        class="organization--blocked"
      >
        Ваша организация заблокирована, поэтому это действие совершить нельзя. Проверьте срок
        действия услуги в
        <a href="https://bill.dline-media.com" target="__blank">биллинг-системе</a>
      </div>
    </div>
    <div v-else class="loader__wrapper">
      <Loader :width="80" :height="80" />
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/base/Select";
import HelpField from "@/components/base/HelpField";
import BaseInput from "@/components/base/Input";
import Loader from "@/components/base/Loader";

export default {
  name: "StationsAdd",
  components: {
    HelpField,
    BaseInput,
    BaseSelect,
    Loader,
  },
  data() {
    return {
      loading: false,
      domain: "",
      websiteLoading: true,
      organization: {},
      organizationsLoading: true,
      stationsLoading: true,
      station_check: [],
      count: 0,
      template: {
        field: "player",
        label: "Плеер (зелёный)",
      },
      templates: [
        {
          field: "player",
          label: "Плеер (зелёный)",
        },
      ],
    };
  },
  created() {
    this.organizationsLoading = true;
    this.stationsLoading = true;
    this.count += 1;
    this.$store.dispatch("organizations").finally(() => {
      this.organizationsLoading = false;
      if (this.organizations.length) {
        this.organization = this.organizations[0];
        this.$store
          .dispatch("stations", {
            organization_id: this.organization.id,
          })
          .then(() => {
            if (this.$store.getters.stations?.length) {
              this.station_check.push(this.$store.getters.stations[0]);
            }
          })
          .finally(() => {
            this.count = 0;
            this.stationsLoading = false;
            this.websiteLoading = false;
          });
      }
    });
  },
  watch: {
    organization(organization) {
      this.$store.dispatch("validations", null);
      if (!this.count) {
        this.stationsLoading = true;
        this.station_check = [];
        this.$store
          .dispatch("stations", {
            organization_id: organization.id,
          })
          .then(() => {
            if (this.$store.getters.stations?.length) {
              this.station_check.push(this.$store.getters.stations[0]);
            }
          })
          .finally(() => {
            this.stationsLoading = false;
          });
      }
    },
  },
  computed: {
    stations() {
      if (this.$store.getters.stations?.length) {
        this.station = this.$store.getters.stations[0];
        return this.$store.getters.stations;
      } else {
        return [];
      }
    },
    accessLevel() {
      if (this.$store.getters.userAccessLevel) {
        return this.$store.getters.userAccessLevel;
      } else {
        return 1;
      }
    },
    organizations() {
      if (this.$store.getters.userAccessLevel === 1) {
        return [this.$store.getters.userSelectOrganization];
      } else if (
        this.$store.getters.userAccessLevel > 1 &&
        this.$store.getters.organizations?.length
      ) {
        return this.$store.getters.organizations;
      } else {
        return [];
      }
    },
  },
  methods: {
    successCreateWebsite(id) {
      this.$store.dispatch("notification", "notification");
      this.$store.dispatch("notificationTitle", "Вы успешно добавили веб-сайт");
      this.$store.dispatch("notificationStyle", "max-width: 450px; min-height: 200px;");
      this.$store.dispatch(
        "notificationMessage",
        `<p>Отлично, домен добавлен в систему! Чтобы домен начал корректно работать, создайте в DNS редакторе следующую запись:</p>
         <br/>
         <p>Домен: <strong>${this.domain}</strong></p>
         <p>Тип записи: <strong>А</strong></p>
         <p>Значение: <strong>${process.env.VUE_APP_WEBSITE_WEB_SERVER_IP}</strong></p>
         <br/>
         <p>Если у Вас возникают сложности с настройкой или Вы переживаете, что ничего не получится - напишите в нашу службу поддержки через чат, поможем всё настроить.</p>
        `
      );
      this.$store.dispatch("notificationButtons", [
        {
          text: "Проверить запись в DNS",
          active: true,
          method: () => {
            this.$store.commit("websiteCheck", id);
            this.$store.dispatch("notification", null);
            this.$store.dispatch("notificationTitle", null);
            this.$store.dispatch("notificationMessage", null);
            this.$store.dispatch("notificationButtons", null);
          },
        },
      ]);
    },
    getHelp() {
      this.$store.dispatch("notification", "notification");
      this.$store.dispatch("notificationTitle", "Нет домена?");
      this.$store.dispatch("notificationStyle", "max-width: 450px; min-height: 200px;");
      this.$store.dispatch(
        "notificationMessage",
        `<p>Вы можете приобрести домен в нашей <a href="https://bill.dline-media.com" target="__blank">биллинг-системе</a>.</p>
           <br>
           <p>Домен в зоне .RU можно приобрести на 1 год за 299 рублей.</p>
           <br>
           <p>Если у Вас возникают сложности с настройкой или Вы переживаете, что ничего не получится - напишите в нашу службу поддержки через чат, поможем всё настроить.</p>`
      );
      this.$store.dispatch("notificationButtons", [
        {
          text: "Купить домен",
          active: true,
          method: () => {
            window.open("https://bill.dline-media.com", "_blank");
          },
        },
        {
          text: "Написать в поддержку",
          active: false,
          method: () => {
            const buttonChat = document.querySelector(
              ".b24-widget-button-inner-item.b24-widget-button-icon-animation"
            );
            buttonChat.click();
          },
        },
      ]);
    },
    isDanger(field) {
      if (
        this.$store.getters.validations &&
        Object.keys(this.$store.getters.validations).includes(field)
      ) {
        return "danger";
      } else {
        return "";
      }
    },
    back(update = false) {
      if (typeof update !== "boolean") {
        update = false;
      }
      this.$store.dispatch("validations", null);
      this.$emit("back", update);
    },
    async addWebsite() {
      this.loading = true;
      this.$store.commit("validations", null);
      let station_ids = [];
      if (this.station_check?.length) {
        station_ids = this.station_check.map((station) => {
          return station.id;
        });
      }
      this.$store
        .dispatch("addWebsite", {
          template: this.template.field,
          domain: this.domain,
          organization_id: this.organization.id,
          station_ids: station_ids,
        })
        .then((response) => {
          this.successCreateWebsite(response.data.data.id);
          this.back(true);
        })
        .catch((e) => {
          if (e.response.status === 500) {
            this.$store.dispatch("notification", "failure");
            this.$store.dispatch(
              "notificationMessage",
              e.response?.data?.message || "Не удалось добавить веб-сайт"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.added-station {
  padding: 35px;
  overflow: auto;
  max-height: 430px;
  max-width: 320px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.station-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.box-form {
  .width {
    width: calc(20% - 20px);
    margin-bottom: 20px;
  }
  .vs-switch {
    width: max-content;
  }
}
.box-form {
  display: flex;
  flex-wrap: wrap;
}
.organization--blocked {
  color: #666;
}
@media (max-width: 800px) {
  .box-form {
    .BaseInput-parent {
      width: calc(50% - 20px);
    }
    .vs-select-content {
      width: calc(50% - 20px);
    }
    .width {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .added-station {
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .box-form {
    .BaseInput-parent {
      width: 100%;
      margin-bottom: 0;
    }
    .width {
      width: 100%;
    }
    .vs-select-content {
      width: 100%;
      margin-bottom: 0;
    }
    .vs-select-content {
      margin-bottom: 20px;
    }
  }
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  margin-bottom: 50px;
}
</style>
