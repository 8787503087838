import Vue from "vue";
import Vuex from "vuex";

import clusters from "./modules/clusters";
import analytics from "./modules/analytics";
import servers from "./modules/servers";
import stations from "./modules/stations";
import streams from "./modules/streams";
import securities from "./modules/security";
import notification from "./modules/notification";
import auth from "./modules/auth";
import organizations from "./modules/organizations";
import users from "./modules/users";
import jobs from "./modules/jobs";
import search from "./modules/search";
import modal from "./modules/modal";
import tasks from "./modules/tasks";
import websites from "./modules/web-sites";
import countries from "./modules/countries";
import bitrateOverlimits from "./modules/bitrate-overlimits";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bitrateOverlimits,
    countries,
    securities,
    jobs,
    clusters,
    servers,
    stations,
    streams,
    notification,
    auth,
    organizations,
    users,
    search,
    modal,
    tasks,
    websites,
    analytics,
  },
});
