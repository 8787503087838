<template>
  <div>
    <div :class="classes">
      <BaseSearch v-model="search" :filterAcive="$store.getters.streamFilterActive" :filters="filters" @change="changeFilter" />
      <vs-button
        v-if="
          $access.streams.create.includes(access_level) ||
          $role.streams.create.includes(member_role)
        "
        style="min-width: max-content; margin-left: 20px"
        flat
        :active="true"
        icon
        @click="addStream"
      >
        <i class="bx bx-plus" style="margin-right: 5px"></i>
        <span>Добавить поток</span>
      </vs-button>
    </div>
    <StreamsView :loadingData="loadingData" @back="back" @block="block" @pagination="pagination"/>
    <vs-dialog prevent-close :not-close="isNotClose" not-padding v-model="action">
      <component :is="view" @back="back" @block="block" @showInfo="showInfo" @close="close"/>
    </vs-dialog>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import StreamsAdd from "@/components/Streams/Add.vue";
import StreamsEdit from "@/components/Streams/Edit.vue";
import StreamsView from "@/components/Streams/View.vue";
import StreamsInfo from "@/components/Streams/Info.vue";
import BaseSearch from "@/components/base/Search";

export default {
  name: "Streams",
  components: {
    StreamsAdd,
    StreamsEdit,
    StreamsView,
    StreamsInfo,
    BaseSearch,
  },
  data() {
    return {
      is_loading: false,
      loadingData: true,
      page: 1,
      view: StreamsView,
      search: this.$store.getters.streamFilterValue,
      timerId: 0,
      isNotClose: false,
      count: 0,
    };
  },
  created() {
    this.checkProgress();
    this.$store.commit("streams", []);
    this.$store.commit("streamsMeta", {});
    this.$store.dispatch("streams").finally(() => {
      this.loadingData = false;
    });
  },
  watch: {
    search() {
      this.loadingData = true;
      this.$store.commit("streams", []);
      this.$store.commit("streamsMeta", {});
      this.$store.dispatch("streamsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    action() {
      if (!this.action) {
        this.$store.dispatch("validations", null);
      }
    },
  },
  computed: {
    action: {
      get() {
        if (this.isNotClose) {
          return true;
        }
        return this.$store.getters.showModal;
      },
      set(value) {
        this.isNotClose = false;
        this.$store.commit('showModal', value);
      }
    },
    member_role() {
      if (this.$store.getters.userSelectOrganization) {
        return this.$store.getters.userSelectOrganization.member_role;
      }
      return 0;
    },
    filters() {
      return this.$store.getters.streamsFilters;
    },
    access_level() {
      return this.$store.getters.userAccessLevel;
    },
    isStreamsAdd() {
      return this.view.name === "StreamsAdd";
    },
    isStreamsEdit() {
      return this.view.name === "StreamsEdit";
    },
    classes() {
      return this.getClasses(this.view.name);
    },
  },
  methods: {
    setLoading(is_loading) {
      this.is_loading = is_loading;
    },
    pagination(page) {
      this.page = page;
    },
    close(value) {
      this.isNotClose = !value;
    },
    showInfo() {
      this.setAction("info");
      this.action = true;
    },
    changeFilter(value) {
      this.loadingData = true;
      this.$store.commit("streamFilterActive", value);
      this.$store.dispatch("streamsSearch", { value: this.search }).finally(() => {
        this.loadingData = false;
      });
    },
    getClasses(viewName) {
      if (viewName === "StreamsAdd" || viewName === "StreamsEdit") {
        return "x-flex-end flex header-table";
      }
      return "x-flex-end flex header-table";
    },
    setAction(action) {
      switch (action) {
        case "viewing":
          this.view = StreamsView;
          break;
        case "editing":
          this.view = StreamsEdit;
          break;
        case "info":
          this.view = StreamsInfo;
          break;
        case "addition":
          this.view = StreamsAdd;
          break;
        default:
          this.view = StreamsView;
      }
    },
    checkProgress() {
      const completedStatus = ["published", "deleted", "updated", "failed"];
      clearInterval(this.timerId);
      this.timerId = setInterval(() => {
        const list = this.$store.getters.checkStreamList;
        if (list.length) {
          this.count += 1;
          list.forEach((id) => {
            this.$store.dispatch("streamStatus", id).then((r) => {
              if (completedStatus.includes(r.status)) {
                this.$store.commit("streamRemoveCheckId", {id: id, status: r.status});
                if (!this.$store.getters.checkStreamList.length) {
                  clearInterval(this.timerId);
                  this.$store.dispatch("streams", {
                    page: this.page
                  });
                }
              }
            }).catch(() => {
              this.$store.commit("failedCheckId", id);
            });
          });
        } else {
            clearInterval(this.timerId);
            if (this.count) {
              this.$store.dispatch("streams");
              this.count = 0;
            }
        }
      }, 5000);

    },
    block(data) {
      this.setAction(data);
      this.action = true;
    },
    back(update = false) {
      if (update) {
        this.checkProgress();
      }
      this.action = false;
    },
    addStream() {
      this.view = StreamsAdd;
      this.action = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-table {
  background: #fff;
  padding: 22px 20px;
  border-radius: 16px 16px 0 0;
}
@media (max-width: 500px) {
  .header-table {
    display: flex;
    flex-direction: column-reverse;
  }
  body {
    .vs-button {
      margin-left: auto !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
