<template>
  <section>
    <security-list @setupSecurity="setupSecurity" />
    <vs-dialog prevent-close not-padding v-model="showStationList">
      <station-list @back="showStationList = false" />
    </vs-dialog>
  </section>
</template>

<script>
import { SecurityList, StationList } from "@/components/security-new";

export default {
  name: "page-security",
  components: { SecurityList, StationList },
  data() {
    return {
      showStationList: false,
    };
  },
  methods: {
    setupSecurity() {
      this.showStationList = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
