var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{ref:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(this.$route.meta.title))]),_c('div',{staticClass:"flex",staticStyle:{"align-items":"center"}},[(
          _vm.organizationsCount > 1 &&
          (_vm.$access.organizations.support.includes(_vm.access_level) ||
            (_vm.access_level === 1 && _vm.organizations?.length))
        )?_c('div',{staticClass:"header__group"},[_c('BaseSelect',{staticClass:"header__select",attrs:{"field":"id","title":_vm.title,"options":_vm.organizations,"isFirstItem":_vm.isFirstItem,"filter":_vm.isFilter,"method":'organizationsAdmin',"search":'organizationsSearchAdmin',"firstTitle":'name',"firstItem":{
            id: '',
            name: 'Все организации',
          }},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1):_vm._e(),_c('div',{staticClass:"profile-dialog__box"},[_c('vs-avatar',{attrs:{"circle":""},on:{"click":_vm.toogleMenu},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(_vm._s(_vm.$store.getters.userName ? _vm.$store.getters.userName[0] : ""))]},proxy:true}])}),_c('div',{staticClass:"profile-dialog",class:_vm.isMenu ? 'profile-dialog--active' : ''},[_c('div',{staticClass:"profile-dialog__wrapper"},[_c('div',{staticClass:"profile-dialog__name"},[_vm._v(_vm._s(_vm.$store.getters.userName))]),_c('div',{staticClass:"profile-dialog__email"},[_vm._v(_vm._s(_vm.$store.getters.userEmail))])]),_c('ul',{staticClass:"profile-dialog__menu unselectable"},[(_vm.access_level >= 50)?_c('li',{staticClass:"profile-dialog__item",on:{"click":_vm.viewSection}},[(_vm.isShowAdminSection)?_c('i',{staticClass:"bx bx-hide"}):_c('i',{staticClass:"bx bx-show-alt"}),_vm._v(" "+_vm._s(_vm.isShowAdminSection ? "Скрыть " : "Показать ")+"разделы ")]):_vm._e(),_c('li',{staticClass:"profile-dialog__item",on:{"click":_vm.logout}},[_c('i',{staticClass:"bx bx-log-out"}),_vm._v(" Выйти ")])])])],1),_c('vs-button',{staticClass:"mobile-menu-button ml20",attrs:{"flat":"","icon":""},on:{"click":_vm.activeSidebar}},[_c('i',{staticClass:"bx bx-menu"})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }