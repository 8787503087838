const OWNER = 4;
const ADMIN = 3;
const MANAGE = 2;
const USER = 1;

export default {
  clusters: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  servers: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  stations: {
    create: [OWNER, ADMIN, MANAGE],
    read: [OWNER, ADMIN, MANAGE, USER],
    update: [OWNER, ADMIN, MANAGE],
    delete: [OWNER, ADMIN, MANAGE],
  },
  streams: {
    create: [OWNER, ADMIN, MANAGE],
    read: [OWNER, ADMIN, MANAGE, USER],
    update: [OWNER, ADMIN, MANAGE],
    delete: [OWNER, ADMIN, MANAGE],
  },
  security: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  "security-update": {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  organizations: {
    create: [],
    read: [OWNER, ADMIN, MANAGE, USER],
    update: [OWNER, ADMIN],
    delete: [OWNER, ADMIN],
  },
  members: {
    create: [OWNER, ADMIN],
    read: [OWNER, ADMIN],
    update: [OWNER, ADMIN],
    delete: [OWNER, ADMIN],
  },
  users: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  jobs: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
  websites: {
    create: [OWNER, ADMIN],
    read: [OWNER, ADMIN],
    update: [OWNER, ADMIN],
    delete: [OWNER, ADMIN],
  },
  help: {
    read: [OWNER, ADMIN, MANAGE, USER],
  },
  analytics: {
    create: [OWNER, ADMIN, MANAGE],
    read: [OWNER, ADMIN, MANAGE],
    update: [OWNER, ADMIN, MANAGE],
    delete: [OWNER, ADMIN, MANAGE],
  },
  playlist_history: {
    create: [OWNER, ADMIN],
    read: [OWNER, ADMIN],
    update: [OWNER, ADMIN],
    delete: [OWNER, ADMIN],
  },
  instructions: {
    create: [],
    read: [OWNER, ADMIN, MANAGE, USER],
    update: [],
    delete: [],
  },
  violations: {
    create: [],
    read: [],
    update: [],
    delete: [],
  },
};
