<template>
  <vs-dialog
    auto-width
    not-padding
    prevent-close
    style="z-index: 999999"
    v-model="activeDialog"
    class="as-notification"
    @close="close"
  >
    <div class="p20 notification" :style="notificationStyle">
      <div class="flex x-center notification-title" v-html="notificationTitle || ''"></div>
      <div v-html="notificationMessage"></div>
      <div class="flex x-center mt20">
        <vs-button
          v-for="(button, index) in notificationButtons"
          :key="'button-' + index"
          :style="index + 1 !== notificationButtons.length ? 'margin-right: 10px;' : ''"
          transparent
          flat="flat"
          :active="button.active"
          @click="button.method"
          >{{ button.text }}</vs-button
        >
      </div>
    </div>
  </vs-dialog>
</template>

<script>
export default {
  data() {
    return {
      activeDialog: true,
    };
  },
  computed: {
    notificationTitle() {
      if (this.$store.getters.notificationTitle) {
        return this.$store.getters.notificationTitle;
      }
      return "";
    },
    notificationMessage() {
      if (this.$store.getters.notificationMessage) {
        return this.$store.getters.notificationMessage;
      }
      return "";
    },
    notificationStyle() {
      if (this.$store.getters.notificationStyle) {
        return this.$store.getters.notificationStyle;
      }
      return "";
    },
    notificationButtons() {
      if (this.$store.getters.notificationButtons) {
        return this.$store.getters.notificationButtons;
      }
      return [];
    },
  },
  methods: {
    close() {
      this.activeDialog = !this.activeDialog;
      this.$store.dispatch("notification", null);
      this.$store.dispatch("notificationTitle", null);
      this.$store.dispatch("notificationMessage", null);
      this.$store.dispatch("notificationButtons", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  min-width: 260px;
  max-width: 330px;
  min-height: 260px;
}
.notification-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: inherit;
  font-size: 18px;
}

@media (max-width: 600px) {
  body {
    .as-notification {
      align-items: center;
      .vs-dialog {
        border-radius: 16px;
        max-width: 300px;
        max-height: 300px;
        min-height: 300px;
      }
    }
  }
}
</style>
